import { Injectable } from '@angular/core';
import { Group } from '../models/group.model';
import { Person } from '../models/person.model';
import { CacheContext, HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class GroupsService {
    constructor(
        private httpService: HttpService
    ) {}

    private url: string = '/core/groups';
    private readonly cacheContext: CacheContext = { collection: 'Groups', maxAge: 1800000 };

    async getGroup(component, id: string) {
        return this.httpService.get<Group>(component, `${this.url}/${id}`);
    }

    async getGroupPeople(component, id: string) {
        return this.httpService.get<Person[]>(component, `${this.url}/${id}/people`);
    }

    async getGroups(component, facilityId?: string, tags?: string[]) {
        return this.httpService.get<Group[]>(component, this.url, { tags, facilityId });
    }

    async saveGroup(component, data: Group) {
        return data.id ? await this.httpService.put<Group>(component, `${this.url}/${data.id}`, data, this.cacheContext)
            : await this.httpService.post<Group>(component, this.url, data, this.cacheContext);
    }

    async deleteGroup(component, id: string) {
        return this.httpService.delete<Group>(component, `${this.url}/${id}`, null, this.cacheContext);
    }
}
