
<div class="content cPointer"
    *ngIf="processedText && !isLoading"
    [innerHtml]="processedText | safeHtml"
    [ngStyle]="style"
    (click)="handleClick($event)"
></div>

<div class="widget-no-data" *ngIf="!processedText && !isLoading">
    <p class="no-data">{{'dashboard.widget.noData' | translate}}</p>
</div>
