import { Injectable } from '@angular/core';
import { EmbedInfo } from '@weavix/models/src/powerbi/embed-info';
import { PowerBiReportConfig } from '@weavix/models/src/powerbi/report-config';
import { CacheContext, HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class PowerBiService {

    constructor(
        private httpService: HttpService,
    ) { }

    static baseUrl = '/core/power-bi';
    static cacheCollection = 'powerbi';
    private readonly cacheContext: CacheContext = { collection: PowerBiService.cacheCollection, maxAge: 1800000 };

    static url = (id?: string) => id ? `${PowerBiService.baseUrl}/${id}` : PowerBiService.baseUrl;

    getEmbedInfo(component: any, id: string): Promise<EmbedInfo> {
        return this.httpService.get<any>(component, PowerBiService.url(`embed/${id}`));
    }

    getAll(component: any, tags?: string[]) {
        return this.httpService.get<PowerBiReportConfig[]>(component, PowerBiService.url(), { tags }, this.cacheContext);
    }

    get(component: any, id: string) {
        return this.httpService.get<PowerBiReportConfig>(component, PowerBiService.url(id), null, this.cacheContext);
    }

    save(component: any, craft: Partial<PowerBiReportConfig>) {
        return craft.id
            ? this.update(component, craft.id, craft)
            : this.add(component, craft);
    }

    private add(component: any, craft: Partial<PowerBiReportConfig>) {
        return this.httpService.post<PowerBiReportConfig>(component, PowerBiService.url(), craft, this.cacheContext);
    }

    private update(component: any, id: string, craft: Partial<PowerBiReportConfig>) {
        return this.httpService.put<PowerBiReportConfig>(component, PowerBiService.url(id), craft, this.cacheContext);
    }

    delete(component: any, id: string) {
        return this.httpService.delete<void>(component, PowerBiService.url(id), null, this.cacheContext);
    }
}
