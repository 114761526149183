import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';

import { ModalActionType, ModalService } from 'app/core/services/modal/modal.service';
import { ComponentCanDeactivate } from './deactivate.guard';

@Injectable({
    providedIn: 'root',
})
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
    constructor(
        private modalService: ModalService,
    ) {}
    async canDeactivate(component: ComponentCanDeactivate) {
        if (!component.canDeactivate || component.canDeactivate()) {
            return true;
        } else {
            const modalResult = await this.modalService.confirmLeave();
            if (modalResult.action !== ModalActionType.submit) {
                return false;
            } else {
                return true;
            }
        }
    }
}
