export interface Identifiable<T = any> {
    id?: T;
}

export interface Taggable extends Identifiable {
    tags?: string[];
    folderId?: string;
    facilityId?: string;
}

export interface TagsFolder extends Identifiable {
    tags?: string[];
    folderId?: string;
}

export interface WithProperties {
    properties?: {
        key: string;
        value: string
    }[];
}

export enum OpenDirection {
    TopLeft = 'top-left',
    TopRight = 'top-right',
    BottomLeft = 'bottom-left',
    BottomRight = 'bottom-right'
}

export interface Nameable {
    name?: string;
}
