<app-avatar [avatarInput]="avatar" [readonly]="false" [backgroundColor]="backgroundColor">
    <div *ngIf="showAvailability"
        class="availability-indicator" 
        [ngStyle]="{
            'bottom.px': avatar?.height * 0.04,
            'right.px': avatar?.height * 0.04 - (avatar?.height / 6),
            'height.px': avatar?.height / 3,
            'width.px': avatar?.width / 3,
            'border-radius.px': avatar?.width / 6
        }"
        [ngClass]="available ? 'available' : '' "></div>
</app-avatar>

<ng-content></ng-content>