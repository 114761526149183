import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Dashboard } from 'weavix-shared/models/dashboard.model';
import { DashboardService } from 'weavix-shared/services/dashboard.service';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

@AutoUnsubscribe()
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

    dashboard: Dashboard;

    constructor(private dashboardService: DashboardService,
        private route: ActivatedRoute) { }

    async ngOnInit() {
        const dashboardId = this.route?.snapshot?.params?.dashboardId;
        if (dashboardId) this.dashboard = await this.dashboardService.getDashboard(this, dashboardId);
    }

}
