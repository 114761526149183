<div class="header">
    <div class="header-actions hide-empty">
        <app-report class="hide-empty"></app-report>
    </div>
    <div *ngIf="canaryAvailable" class="canary-menu" (click)="goToCanary()"
        [matTooltip]="'generics.beta-available' | translate">
        <app-icon [faIcon]="'fas fa-flask'" ></app-icon>
    </div>
    <div class="header-notifications">
        <app-notifications></app-notifications>
    </div>
    <div class="header-profile-menu">
        <div class="header-profile-menu-icon">
            <button id="settings-menu-icon" class="p0" (click)="toggleSettingsMenu($event, !settingsNavOpen)">
                <div class="header-profile-avatar" *ngIf="userProfile">
                    <app-avatar-person [personId]="userProfile.personId" [avatarInput]="avatarInput" [showAvailability]="true"></app-avatar-person>
                </div>
            </button>

            <app-settings-menu
                *ngIf="settingsNavOpen && userProfile"
                [profileInput]="userProfile"
                appClickOutside
                (clickOutside)="toggleSettingsMenu($event, false)"
            ></app-settings-menu>
        </div>

    </div>
    <div class="header-logo">
        <img  src="assets/img/logo-horizontal.png" alt="weavix Logo" />
    </div>
</div>
