<app-input *ngIf="[variableTypes.Text, variableTypes.Number].includes(variable.type)"
           [label]="showLabel ? (label || variable.name) : null" [translate]="false"
           type="{{variable.type === variableTypes.Text ? 'text' : 'number'}}"
           [formControl]="control">
</app-input>
<app-relative-date-picker class="col w100p" *ngIf="variableTypes.Date === variable.type && default"
                          [label]="showLabel ? (label || variable.name) : null" [formControl]="dateControl">
</app-relative-date-picker>
<app-date-picker class="col w100p" *ngIf="variableTypes.Date === variable.type && !default"
                 [label]="showLabel ? (label || variable.name) : null" [translate]="false" [formControl]="dateControl">
</app-date-picker>
<app-dropdown *ngIf="variable.options"
    class="col w100p"
    [formControl]="control"
    [dropdownInput]="variable.options"
    [floatedLabel]="showLabel ? (label || variable.name) : null"
    [translate]="false"
    [setDefault]="false"
></app-dropdown> 
