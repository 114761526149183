import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DateRangePickerHeaderComponent } from 'console/app/shared/date-range-picker/date-range-picker-header/date-range-picker-header.component';

@Component({
    selector: 'app-date-range-picker-header-no-arrow',
    templateUrl: './date-range-picker-header.component.html',
    styleUrls: ['./date-range-picker-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangePickerHeaderNoArrowComponent<D> extends DateRangePickerHeaderComponent<D> {
    showArrowUp = false;
}
