import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { CompanyService } from 'weavix-shared/services/company.service';
import { PersonService } from 'weavix-shared/services/person.service';

import { BadgeUpdate } from 'weavix-shared/models/event.model';
import { Avatar } from '../avatar.model';

import { css } from 'weavix-shared/utils/css';
import { AutoUnsubscribe, Utils } from 'weavix-shared/utils/utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-avatar-person',
    templateUrl: './avatar-person.component.html',
    styleUrls: ['./avatar-person.component.scss'],
})
export class AvatarPersonComponent implements OnInit, OnChanges {

    @Input() personId: string;
    @Input() avatarInput: Avatar;
    @Input() showAvailability: boolean = true;
    @Input() backgroundColor: string;

    private defaultAvatar: Avatar = {
        height: 50,
        width: 50,
        editable: false,
        hasBorders: true,
        placeholder: {
            icon: 'fas fa-user',
            iconColor: css.colors.WHITE,
            backgroundColor: css.colors.GRAY,
        },
    };

    private badgeSub: Subscription;
    available: boolean;
    avatar: Avatar;

    constructor(
        private cdr: ChangeDetectorRef,
        private personService: PersonService,
        private companyService: CompanyService,
    ) { }

    async ngOnInit() {
        if (this.avatarInput?.img) {
            this.avatar = { ...this.defaultAvatar, ...this.avatarInput };
        }
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.personId) {
            this.badgeSub?.unsubscribe();

            if (!this.personId && !this.avatarInput?.img) {
                this.avatar = null;
                return;
            }

            try {
                const person = await this.getPerson(this.personId);
                const company = await this.getCompany(person?.companyId);

                this.avatar = { ...this.defaultAvatar, ...this.avatarInput };

                if (person?.avatarFile) {
                    this.avatar.img = person.avatarFile;
                }

                this.avatar.outlineColor = company?.color ?? css.colors.WHITE_65P;

                this.updateAvailability(person?.badge);

                if (this.showAvailability) {
                    this.badgeSub = Utils.safeSubscribe(this, (await this.personService.subscribeBadgeUpdates(this, this.personId))).subscribe({
                        next: x => { if (x.topic[1] === this.personId) this.updateAvailability(x.payload); },
                    });
                }

                this.cdr.markForCheck();
            } catch (e) {
                console.error('Failed to load avatar person data', e);
            }
        }
    }

    private async getPerson(id: string) {
        try {
            return await this.personService.getPerson(this, id, true, true);
        } catch (e) {
            return null;
        }
    }

    private async getCompany(id: string) {
        if (id == null) return null;
        try {
            return await this.companyService.get(this, id, true, true);
        } catch (e) {
            return null;
        }
    }

    private updateAvailability(badge: BadgeUpdate) {
        this.available = PersonService.inAnyFacility(badge);
    }
}
