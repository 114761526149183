import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { CardAction, CardStatusIcon } from '../models/card.model';
import { ChannelType } from '../models/channels.model';
import { Company } from '../models/company.model';
import { DataFilterSelection } from '../models/data-filter-selection.model';
import { Facility } from '../models/facility.model';
import { Folder, FolderType } from '../models/folder.model';
import { Item } from '../models/item.model';
import { Person } from '../models/person.model';
import { BackendSearchResult, ResultGroup, ResultType, Searchable, SearchAction, SearchMapping, SearchResult, SearchTarget } from '../models/search.model';
import { Geofence } from '../models/weavix-map.model';
import { WorkForm } from '../models/work-forms.model';
import { PermissionAction } from '../permissions/permissions.model';
import { css } from '../utils/css';
import { FEATURE_ICONS } from '../utils/feature.icons';
import { Utils } from '../utils/utils';
import { AlertService, ServiceError } from './alert.service';
import { FolderService } from './folder.service';
import { HttpService } from './http.service';
import { ItemService } from './item.service';
import { MapService } from './map.service';
import { DeviceStatus, PersonService } from './person.service';
import { ProfileService } from './profile.service';
import { TranslationService } from './translation.service';

export interface SearchRequest {
    text?: string;
    targets?: SearchTarget[];
    facilityId?: string;
    map?: boolean;
}

export interface FilterRequest {
    folder: FolderType;
    selections: DataFilterSelection;
    facilityId?: string;
}

export interface FilteredData<T> {
    list: T[];
    folders: Folder[];
    facilities: Facility[];
}

@Injectable()
export class SearchService {
    searches: {[key: string]: SearchMapping};
    private lastLocation: string = null;
    private results: ResultGroup[] = [];

    constructor(
        private httpService: HttpService,
        // private ruleService: RuleService,
        private translationService: TranslationService,
        private itemService: ItemService,
        private folderService: FolderService,
        private alertsService: AlertService,
        private mapService: MapService,
        private profileService: ProfileService
    ) {}

    async search(component: any, query: string) {
        query = query.replace(/[!@#$%^&*()\-_=+\[\]{}\\|;:'"\/?,.<>`~]/g, ' ');

        this.results = [];

        const filters = Object.keys(Searchable).map(t => Searchable[t]);

        const targets = [];
        const handlers = {};

        filters.forEach(f => {
            const search = this.searches[f];
            if (!search || f === Searchable.Channels) return;

            const group = _.cloneDeep(search.group);
            search.preResults.forEach(pre => {
                targets.push(pre.target);
                handlers[pre.target] = (res) => {
                    const resultGroup = pre.group ? pre.group(res) : group;
                    let foundGroup = this.results.find(r => r.name === resultGroup.name && r.type === resultGroup.type);
                    if (!foundGroup) {
                        foundGroup = resultGroup;
                        foundGroup.results = [];
                        this.results.push(foundGroup);
                    }
                    res.displayText = pre.displayText(res);
                    if (pre.descriptions) res.descriptions = pre.descriptions(res);
                    res.icon = pre.icon(res);
                    res.actions = pre.actions(res);
                    foundGroup.results.push(res);
                };
            });
        });

        try {
            const results = await this.getSearchResults(component, { targets, text: query });
            results.forEach(r => {
                if (!handlers[r.type]) console.error(`Type ${r.type} not handled`, r);
                else handlers[r.type](r.data);
            });
            const categoryOrder = {
                [Searchable.People]: 1,
                [Searchable.Items]: 2,
                [Searchable.Facilities]: 3
            };
            return Utils.sortAlphabetical(this.results, (item) => item.name).sort((a, b) => {
                if (categoryOrder[a.type] && categoryOrder[b.type]) return categoryOrder[a.type] - categoryOrder[b.type];
                else if (categoryOrder[a.type]) return -1;
                else if (categoryOrder[b.type]) return 1;
                return 0;
            });
        } catch (e) {
            this.alertsService.sendServiceError(e, ServiceError.Get, 'search');
            throw e;
        }
    }

    async mapSearch(component: any, query: string, facilityId?: string, specificTarget?: SearchTarget) {
        const mapSearchResults = [];
        const mapSearchItems = [Searchable.People, Searchable.Geofences, Searchable.Items, Searchable.Companies];
        const searchPermissions: Partial<Record<Searchable, PermissionAction>> = {
            [Searchable.People]: PermissionAction.ViewPeople,
            [Searchable.Geofences]: PermissionAction.ViewSites,
            [Searchable.Items]: PermissionAction.ViewSites,
            [Searchable.Companies]: null,
        };
        const targets: SearchTarget[] = [];
        const handlers = {};

        mapSearchItems.forEach(f => {
            const permissionCheck = searchPermissions[f];
            if (permissionCheck && !this.profileService.hasPermission(permissionCheck, facilityId)) return;

            const search = this.searches[f];
            const preResults = specificTarget ? search.preResults.filter(pre => pre.target === specificTarget) : search.preResults;

            preResults.forEach(pre => {
                targets.push(pre.target);
                handlers[pre.target] = (res: SearchResult<any>) => {
                    res.displayText = pre.displayText(res);
                    if (pre.descriptions) res.descriptions = pre.descriptions(res);
                    res.icon = pre.icon(res);
                    res.actions = pre.actions(res);
                    res.type = pre.type;
                    if (pre.status) res.status = pre.status(res);

                    if (res.type !== ResultType.MapView && (!permissionCheck || this.profileService.hasPermission(permissionCheck, facilityId, res.folderId))) {
                        mapSearchResults.push(res);
                    }
                };
            });
        });

        try {
            const results = await this.getSearchResults(component, { targets, text: query, facilityId, map: true });
            results.forEach(r => {
                if (r.type === SearchTarget.People) {
                    if (this.mapService.dvrPlaybackMode) {
                        const person = this.mapService.dvrDataState.people[r.data.id];
                        if (person) r.data.badge = person.badge;
                    }
                }
                if (!handlers[r.type]) console.error(`Type ${r.type} not handled`, r);
                else handlers[r.type](r.data);
            });
            return Utils.sortAlphabetical(mapSearchResults, (item) => item.displayText);
        } catch (e) {
            this.alertsService.sendServiceError(e, ServiceError.Get, 'search');
            throw e;
        }
    }

    setLastLocation(url: string) {
        this.lastLocation = url;
    }

    getLastLocation() {
        return this.lastLocation;
    }

    async setupSearch(component: any) {
        await this.itemService.buildItemTypeMap(component);
        await this.folderService.buildItemFolderMap(component);
        this.buildSearchResultsMap();
    }

    getPersonDeviceStatusCardIcon(p: Person) {
        const badgeIcon = (color: string, tooltipKey: string, status: string): CardStatusIcon => ({
            faIcon: 'fas fa-id-badge',
            color,
            fontSize: '24px',
            inline: true,
            status,
            tooltip: this.translationService.getImmediate(tooltipKey)
        });

        const triangleIcon = (color: string, tooltipKey: string, status: string, transArg: {}): CardStatusIcon => ({
            faIcon: 'fas fa-exclamation-triangle',
            color,
            fontSize: '22px',
            inline: true,
            status,
            tooltip: this.translationService.getImmediate(tooltipKey, transArg)
        });

        const wispIcon = (color: string, status: string): CardStatusIcon => ({
            faIcon: 'fas fa-tag',
            color,
            fontSize: '24px',
            inline: true,
            status
        });

        if (PersonService.hasActiveDevice(p)) {
            if (p.wispId) {
                return wispIcon(css.colors.GREEN, DeviceStatus.Active);
            } else {
                return badgeIcon(css.colors.GREEN, 'badge.active', DeviceStatus.Active);
            }
        }

        if (PersonService.hasActivatedInactiveDevice(p, this.mapService.dvrTimestamp)) {
            return triangleIcon(css.colors.GRAY, 'badge.offlineDays', DeviceStatus.Offline, { days: PersonService.INACTIVE_DEVICE_TIME_DAYS });
        }

        if (p.badge) {
            return triangleIcon(css.colors.ORANGE, 'badge.offlineMinutes', DeviceStatus.Inactive, { minutes: PersonService.ACTIVE_DEVICE_TIME_MINUTES });
        }

        return badgeIcon(css.colors.RED, 'badge.notRegistered', DeviceStatus.Unregistered);
    }

    private buildSearchResultsMap() {
        this.searches = {
            [Searchable.People] : {
                preResults: [
                    {
                        target: SearchTarget.People,
                        displayText: (result) => `${result.firstName} ${result.lastName}`,
                        descriptions: (result) => [{ text: _.get(result, 'company.name') }],
                        actions: this.getPeopleActions.bind(this),
                        icon: (result) => result.avatarFile ? { img: result.avatarFile } : { faIcon: 'fas fa-user-circle' },
                        status: (result: Person) => this.getPersonDeviceStatusCardIcon(result),
                        type: ResultType.Person,
                    }
                ],
                group: {
                    name: this.translationService.getImmediate(`searchRes.searchable.${Searchable.People}`),
                    type: Searchable.People,
                    faIcon: 'fas fa-users'
                }
            },
            [Searchable.Items]: {
                preResults: [
                    {
                        target: SearchTarget.Items,
                        displayText: (result) => result.name,
                        descriptions: (result: Item) => {
                            if (!result.folderId) return [];
                            return [{
                                text: this.folderService.getItemFolderName(result.folderId, result.facilityId),
                                tooltip: `/${this.folderService.getItemFolderPath(result.folderId, result.facilityId)}`
                            }];
                        },
                        actions: (result) => {
                            return this.getItemActions(result);
                        },
                        icon: (result: Item) => {
                            const icon = this.itemService.itemTypeMap[result.typeId]?.icon ?? { value: 'fas fa-question', color: 'black' };
                            return { faIcon: icon.value, color: icon.color };
                        },
                        type: ResultType.Items
                    }
                ],
                group: {
                    name: this.translationService.getImmediate(`searchRes.searchable.${Searchable.Items}`),
                    type: Searchable.Items,
                    faIcon: 'fas fa-list'
                }
            },
            [Searchable.ItemTypes]: {
                preResults: [
                    {
                        target: SearchTarget.ItemTypes,
                        displayText: (result) => result.name,
                        actions: (result) => [
                            {
                                displayText: SearchAction.View,
                                action: SearchAction.View,
                                data: `/settings/item-types/${result.id}/editor`
                            }
                        ],
                        icon: () => ({ svgFile: 'assets/img/item-type.svg', width: '30', height: '30' }),
                        type: ResultType.ItemTypes
                    },
                ],
                group: {
                    name: this.translationService.getImmediate(`searchRes.searchable.${Searchable.ItemTypes}`),
                    type: Searchable.ItemTypes,
                    svgIcon: 'assets/img/item-type.svg'
                }
            },
            [Searchable.Forms]: {
                preResults: [
                    {
                        target: SearchTarget.WorkForms,
                        displayText: (result) => result.name,
                        actions: this.getFormActions.bind(this),
                        icon: () => ({ faIcon: 'fas fa-file-alt' }),
                        type: ResultType.Form,
                    }
                ],
                group: {
                    name: this.translationService.getImmediate(`searchRes.searchable.${Searchable.Forms}`),
                    type: Searchable.Forms,
                    faIcon: 'fas fa-file-alt'
                }
            },
            [Searchable.Companies]: {
                preResults: [
                    {
                        target: SearchTarget.Companies,
                        displayText: (result) => result.name,
                        actions: this.getCompanyActions.bind(this),
                        icon: (result) => ({ faIcon: 'fas fa-building', color: result.color }),
                        type: ResultType.Company,
                    }
                ],
                group: {
                    name: this.translationService.getImmediate(`searchRes.searchable.${Searchable.Companies}`),
                    type: Searchable.Companies,
                    faIcon: 'fas fa-building'
                }
            },
            [Searchable.Crafts]: {
                preResults: [
                    {
                        target: SearchTarget.Crafts,
                        displayText: (result) => result.name,
                        actions: (result) => [
                            {
                                displayText: SearchAction.View,
                                action: SearchAction.View,
                                data: `/settings/crafts/${result.id}/editor`
                            }
                        ],
                        icon: (result) => ({ faIcon: FEATURE_ICONS.craft.icon.faIcon, color: result.color }),
                        type: ResultType.Company,
                    }
                ],
                group: {
                    name: this.translationService.getImmediate(`searchRes.searchable.${Searchable.Crafts}`),
                    type: Searchable.Crafts,
                    faIcon: FEATURE_ICONS.craft.icon.faIcon
                }
            },
            [Searchable.Geofences]: {
                preResults: [
                    {
                        target: SearchTarget.Geofences,
                        displayText: (result) => result.name,
                        actions: (result: SearchResult<Geofence>) => [
                            {
                                displayText: SearchAction.View,
                                action: SearchAction.SecondaryView,
                                data: `${result.facilityId ? `/facilities/${result.facilityId}` : '' }/map?geofenceId=${result.id}`
                            }
                        ],
                        icon: (result) => ({ faIcon: 'fas fa-draw-polygon', color: result.color }),
                        type: ResultType.Geofence,
                    }
                ],
                group: {
                    name: this.translationService.getImmediate(`searchRes.searchable.${Searchable.Geofences}`),
                    type: Searchable.Geofences,
                    faIcon: 'fas fa-draw-polygon'
                }
            },
            [Searchable.Facilities]: {
                preResults: [
                    {
                        target: SearchTarget.Facilities,
                        displayText: (result) => result.name,
                        actions: this.getFacilityActions.bind(this),
                        icon: () => ({ faIcon: 'fas fa-industry' }),
                        type: ResultType.MapView,
                    }
                ],
                group: {
                    name: this.translationService.getImmediate(`searchRes.searchable.${Searchable.Facilities}`),
                    type: Searchable.Facilities,
                    faIcon: 'fas fa-industry'
                }
            },
            [Searchable.Channels]: {
                preResults: [
                    {
                        target: SearchTarget.Channels,
                        displayText: (result) => result.name,
                        actions: (result) => [
                            {
                                displayText: SearchAction.View,
                                action: SearchAction.View,
                                data: `/channels?id=${result.id}`
                            }
                        ],
                        icon: () => ({ faIcon: 'fas fa-hashtag' }),
                        type: ResultType.Channel,
                    },
                    {
                        target: SearchTarget.ChannelMessages,
                        group: (result) => ({
                            name: result.channelName,
                            type: result.channelName,
                            faIcon: result.channelType === ChannelType.People ? 'fas fa-circle' : 'fas fa-hashtag'
                        }),
                        displayText: (result) => `${result.sender ? `${result.sender.firstName} ${result.sender.lastName}` : ''}: ${result.message}`,
                        actions: (result) => [
                            {
                                displayText: SearchAction.View,
                                action: SearchAction.View,
                                data: `/channels?id=${result.channelId}`
                            }
                        ],
                        icon: (res) => ({ faIcon: res.channelType === ChannelType.People ? 'fas fa-circle' : 'fas fa-hashtag' }),
                        type: ResultType.Channel,
                    }
                ],
                group: {
                    name: this.translationService.getImmediate(`searchRes.searchable.${Searchable.Channels}`),
                    type: Searchable.Channels,
                    faIcon: 'fas fa-hashtag'
                }
            },
            [Searchable.PermissionGroups]: {
                preResults: [
                    {
                        target: SearchTarget.Groups,
                        displayText: (result) => result.name,
                        actions: (result) => [
                            {
                                displayText: SearchAction.View,
                                action: SearchAction.View,
                                data: `/settings/groups?search=${result.name}`
                            }
                        ],
                        icon: () => ({ faIcon: 'fas fa-lock' }),
                        type: ResultType.PermissionGroup,
                    }
                ],
                group: {
                    name: this.translationService.getImmediate(`searchRes.searchable.${Searchable.PermissionGroups}`),
                    type: Searchable.PermissionGroups,
                    faIcon: 'fas fa-lock'
                }
            }
        };
    }

    private getPeopleActions(result: SearchResult<Person>): CardAction[] {
        const actions: CardAction[] = [
            {
                displayText: SearchAction.View,
                action: SearchAction.View,
                data: `/people?search=${result.lastName}`
            }
        ];
        if (PersonService.hasActiveDevice(result)) {
            actions.push({
                displayText: this.translationService.getImmediate(`searchRes.${SearchAction.ViewOnMap}`),
                action: SearchAction.ViewOnMap,
                data: `/map?personId=${result.id}`
            });
        }

        return actions;
    }

    private getItemActions(result: SearchResult<Item>): CardAction[] {
        const actions: CardAction[] = [
            {
                displayText: SearchAction.View,
                action: SearchAction.View,
                data: `${result.facilityId ? `/facilities/${result.facilityId}` : ''}/items/${result.id}/editor`
            }
        ];
        if (result.location) {
            actions.push({
                displayText: this.translationService.getImmediate(`searchRes.${SearchAction.ViewOnMap}`),
                action: SearchAction.ViewOnMap,
                data: `/map?itemId=${result.id}`
            });
        }

        return actions;
    }

    private getFormActions(form: SearchResult<WorkForm>): CardAction[] {
        return [
            {
                displayText: SearchAction.View,
                action: SearchAction.View,
                data: `/forms/${form.id}/latest/submission`
            }
        ];
    }

    private getCompanyActions(company: SearchResult<Company>): CardAction[] {
        return [
            {
                displayText: SearchAction.View,
                action: SearchAction.View,
                data: `/people?search=${company.name}`
            },
            {
                displayText: this.translationService.getImmediate('searchRes.viewCompany'),
                action: SearchAction.SecondaryView,
                data: `/settings/companies?search=${company.name}`
            },
            {
                displayText: this.translationService.getImmediate(`searchRes.${SearchAction.ViewOnMap}`),
                action: SearchAction.ViewOnMap,
                data: `/map?companyId=${company.id}`
            }
        ];
    }

    private getFacilityActions(facility: SearchResult<Facility>): CardAction[] {
        const uri = `/facilities/${facility.id}`;
        return [
            {
                displayText: SearchAction.View,
                action: SearchAction.View,
                data: uri
            },
            {
                displayText: this.translationService.getImmediate('searchRes.viewMap'),
                action: SearchAction.View,
                data: `${uri}/map`
            }
        ];
    }

    private async getSearchResults(component: any, request: SearchRequest) {
        return this.httpService.post<BackendSearchResult[]>(component, '/core/search', request);
    }

    async getFiltered<T>(component, request: FilterRequest) {
        return this.httpService.post<FilteredData<T>>(component, '/core/search/selections', request);
    }
}
