<ng-container *ngIf="modalInput && modalInput.isOpen">
    <div class="modal-bg" [ngStyle]="{'z-index': modalInput.zIndex || 900}">
        <div #modal class="modal" [ngClass]="{'full-screen': modalInput.fullScreen}"
                [style.maxWidth]="modalInput.fullScreen ? '100%' : modalInput.width + 'px'"
                [style.maxHeight]="modalInput.fullScreen ? '100%' : modalInput.maxHeight + 'px'"
                [style.height.px]="modalInput.fullScreen ? '100%' : modalInput.height + 'px'"
                [style.overflow]='modalInput.overflow'>
            <i class="fa fa-times modal-close" [ngClass]="{'full-screen': modalInput.fullScreen}" (click)="handleModalClose(modalActionType.cancel)"></i>
            <div class="modal-content" [ngClass]="{'full-screen': modalInput.fullScreen}">

                <div class="modal-header" [ngClass]="[modalInput.header?.textAlignment ? modalInput.header?.textAlignment : 'center', modalInput.fullScreen ? 'full-screen' : '']" *ngIf="modalInput.header?.textKey">
                    {{ modalInput.header?.textKey | translate:modalInput.params }}
                </div>

                <div *ngIf="modalInput.header?.showSeparator" class="separator"></div>
                
                <div style="overflow:auto;">

                    <ng-container *ngIf="!modalInput.textKey || modalInput.content">
                        <ng-content></ng-content>
                    </ng-container>

                    <div class="modal-text" [ngClass]="[modalInput.header?.textAlignment ? modalInput.header?.textAlignment : 'center', modalInput.fullScreen ? 'full-screen' : '']" *ngIf="modalInput.textKey" [innerHTML]="modalInput.textKey | translate:modalInput.params" ></div>

                </div>
                
                <div *ngIf="modalInput.actions?.submit?.show || modalInput.actions?.cancel?.show">
                    <div class="modal-actions" [ngClass]="[modalInput.actionsAlignment ? modalInput.actionsAlignment : 'stretch', modalInput.fullScreen ? 'full-screen' : '' ]">
                        <button
                            *ngIf="modalInput.actions?.cancel?.show"
                            id="modal-button-cancel" 
                            (click)="handleModalClose(modalActionType.cancel)" 
                            class="modal-actions-button"
                            [style.width.px]="modalInput.actions?.cancel?.width ? modalInput.actions.cancel.width : ''"
                            [class.fg1]="!modalInput.actionsAlignment || modalInput.actionsAlignment === 'stretch'"
                            [ngClass]="[modalInput.actions?.cancel?.buttonClass, modalInput.fullScreen ? 'full-screen' : '' ]"
                            [disabled]="modalInput.actions.cancel.disabled ? modalInput.actions.cancel.disabled() : false">
                            {{ modalInput.actions.cancel.textKey | translate }}
                        </button>
                        <button
                            *ngIf="modalInput.actions?.delete?.show"
                            id="modal-button-delete" 
                            (click)="handleModalClose(modalActionType.delete)" 
                            class="modal-actions-button"
                            [style.width.px]="modalInput.actions?.delete?.width ? modalInput.actions.delete.width : ''"
                            [class.fg1]="!modalInput.actionsAlignment || modalInput.actionsAlignment === 'stretch'"
                            [ngClass]="[modalInput.actions?.delete?.buttonClass, modalInput.fullScreen ? 'full-screen' : '' ]"
                            [disabled]="modalInput.actions.delete.disabled ? modalInput.actions.delete.disabled() : false">
                            {{ modalInput.actions.delete.textKey | translate }}
                        </button>
                        <button
                            type="submit"
                            *ngIf="modalInput.actions?.submit?.show"
                            id="modal-button-submit"
                            (click)="handleModalClose(modalActionType.submit)"
                            class="modal-actions-button"
                            [style.width.px]="modalInput.actions?.submit?.width ? modalInput.actions.submit.width : ''"
                            [class.fg1]="!modalInput.actionsAlignment || modalInput.actionsAlignment === 'stretch'"
                            [ngClass]="[modalInput.actions?.submit?.buttonClass, modalInput.fullScreen ? 'full-screen' : '' ]"
                            [disabled]="modalInput.actions.submit.disabled ? modalInput.actions.submit.disabled() : false">
                            {{ modalInput.actions.submit.textKey | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>