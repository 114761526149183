import { Component, Input } from '@angular/core';
import { css } from 'weavix-shared/utils/css';
import { CalloutOptions } from '../../callout/callout.component';

export interface MapControl {
    id: string;
    onClick?: () => void;
    tooltip: string;
    icon: string;
    isActive?: () => boolean;
    hide?: () => boolean;
    color?: string;
    callout?: CalloutOptions;
    options?: MapControl[];
}

@Component({
    selector: 'app-map-control',
    templateUrl: './map-control.component.html',
    styleUrls: ['./map-control.component.scss'],
})
export class MapControlComponent {
    @Input() control: MapControl;
    displayOptions: boolean = false;
    colors = css.colors;

    toggleOptions() {
        this.displayOptions = !this.displayOptions;
    }

    closeOptions(): void {
        this.displayOptions = null;
    }

    handleControlOptionClick(option: MapControl): void {
        option?.onClick();
    }
}
