import * as moment from 'moment';
import { Utils } from './utils';

export class DateTimeUtil {
    static timeToLocaleString(value: number, locale: string, tz: string, includeSeconds: boolean = false): string {
        const options: Intl.DateTimeFormatOptions = {
            hour: 'numeric',
            minute: '2-digit'
        };
        if (includeSeconds) options.second = '2-digit';
        if (tz) {
            options.timeZone = tz;
            options.timeZoneName = 'short';
        }
        return new Date(value).toLocaleTimeString(locale, options);
    }

    static dateTimeToLocaleString(date: string | number | Date, locale: string, includeSeconds: boolean = false): string {
        const options: Intl.DateTimeFormatOptions = {
            month: 'numeric',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: '2-digit'
        };
        if (includeSeconds) options.second = '2-digit';
        return new Date(date).toLocaleDateString(locale, options);
    }

    static formatHrsToHrsMinString(hours: number): string {
        const ms = hours * 3600 * 1000;
        const formattedValue = Utils.msToFormattedDuration(ms);
        if (ms < 60000) {
            return formattedValue;
        } else {
            const trimIndex: number = formattedValue.includes('min') ? formattedValue.indexOf('min') + 3 : formattedValue.includes('hr(s)') ? formattedValue.indexOf('hr(s)') + 6 : null;
            return trimIndex ? formattedValue.substring(0, trimIndex) : formattedValue;
        }
    }

    static formatMsToRoundedHrs(ms: number): string {
        const hours = ms / (3600 * 1000);
        return `${Math.round(hours * 100) / 100}hr(s)`;
    }

    static getTimeZoneDiff(timezone: string) {
        return moment().startOf('day').valueOf() - moment().tz(timezone).startOf('day').valueOf();
    }
}
