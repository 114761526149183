import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-logo-spinner',
    templateUrl: './logo-spinner.component.html',
    styleUrls: ['./logo-spinner.component.scss'],
})
export class LogoSpinnerComponent implements OnInit {

    @Input() size: number;
    @Input() slow: boolean;

    constructor() { }

    ngOnInit(): void {
    }

}
