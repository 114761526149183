import { Identifiable, Nameable } from './core.model';

export interface GeofenceType extends Identifiable, Nameable {
    color: string;
    category: GeofenceTypeCategory;
}

export enum GeofenceTypeCategory {
    WorkingArea = 'working-area',
    BreakArea = 'break-area',
    TravelArea = 'travel-area'
}
