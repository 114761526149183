
<form *ngIf="group"
    [formGroup]="group"
    class="time-form"
>
    <app-day-time-picker
        *ngIf="group.controls.from.value"
        [value]="group.controls.from.value"
        [label]="('shared.filters.start-time' | translate)"
        (valueChange)="handleFromTimeChange($event)"
    ></app-day-time-picker>

    <app-day-time-picker
        *ngIf="group.controls.to.value"
        [value]="group.controls.to.value"
        [label]="('shared.filters.end-time' | translate)"
        (valueChange)="handleToTimeChange($event)"
    ></app-day-time-picker>
</form>
