
<div class="container">
    <div class="legend-container" *ngIf="widget.type === widgetType.PieChart">
        <app-chart-legend [legendData]="chartLegend"></app-chart-legend>
    </div>
    <div class="chart-container" [ngClass]="{ 'pie-chart-container': widget.type === widgetType.PieChart }">
        <app-chart
            #chartComponent
            class="chart"
            [type]="widget.type"
            [id]="widget.id"
            [size]="widget.size"
            [chartSettings]="widget.options?.settings"
            [sourceId]="widget.source"
            [variables]="widget.variables"
            [dataSources]="sources"
            [editable]="editable"
            (tableView)="tableView.emit($event)"
            (dashboardView)="dashboardView.emit($event)"
            (chartDataOutput)="setChartLegend($event)"
        ></app-chart>
    </div>
</div>