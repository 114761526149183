<div class="wrapper" [ngClass]="{'light': lightTheme }">
    <div *ngIf="!(tableService.loading$ | async)" class="content-wrapper">
        <ng-container>
            <app-filter-header
                [titleKey]="'generics.filters'"
                [includeBackClick]="false"
                (backClick)="mapSearchService.goBack()"
            ></app-filter-header>

            <div class="scroll-wrapper filter-results">
                <ng-container *ngFor="let res of filterResultsSorted">
                    <ng-container *ngTemplateOutlet="resItem; context: {parent: {children: filterResults}, res: res, resIndex: 0}"></ng-container>
                </ng-container>
            </div>
        </ng-container>

        <div class="actions">
            <button class="clear-filters" (click)="handleClearFilters()">
                {{ 'table.clear-filters' | translate }}
            </button>
        </div>
    </div>
</div>

<ng-template #resItem let-res="res" let-resIndex="resIndex" let-parent="parent">
    <app-table-filter-row [result]="res" [index]="resIndex" (selectEmit)="handleRowClick(parent, $event, false, resIndex)" (clickEmit)="handleRowClick(parent, $event, true, resIndex)"></app-table-filter-row>

    <ng-container *ngIf="res?.children && res.selected">
        <ng-container *ngFor="let child of getSortedChildren(res)">
            <div class="children">
                <ng-container *ngTemplateOutlet="resItem; context: {parent: res, res: child, resIndex: resIndex + (res.depth ? 2 : 1)}"></ng-container>
            </div>
        </ng-container>
    </ng-container>
</ng-template>
