import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmbedInfo } from '@weavix/models/src/powerbi/embed-info';
import * as pbi from 'powerbi-client';
import { PowerBiService } from 'weavix-shared/services/powerbi.service';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

type EmbedOptions = pbi.service.IComponentEmbedConfiguration | pbi.models.IEmbedConfigurationBase;

@AutoUnsubscribe()
@Component({
  selector: 'app-power-bi',
  templateUrl: './power-bi.component.html',
  styleUrls: ['./power-bi.component.scss'],
  providers: [],
})
export class PowerBiComponent implements AfterViewInit, OnDestroy {
  @ViewChild('reportContainer') reportContainer: ElementRef<HTMLDivElement>;

  state: 'loading' | 'loaded' | 'error' = 'loading';

  private refreshDataIntervalId: NodeJS.Timeout | number | string;
  private refreshTokenTimeoutId: NodeJS.Timeout | number | string;
  private reportId: string;
  private report: pbi.Embed;

  constructor(
    private route: ActivatedRoute,
    private powerBiService: PowerBiService,
  ) {}

  ngAfterViewInit(): void {
    this.reportId = this.route?.snapshot?.params?.powerBiReportId ?? null;
    this.initPowerBiWindow(this.reportContainer);
  }

  ngOnDestroy(): void {
    clearInterval(this.refreshDataIntervalId);
    clearTimeout(this.refreshTokenTimeoutId);
  }

  private initPowerBiWindow(container: ElementRef<HTMLDivElement>) {
    const embedResult = window.powerbi.bootstrap(container.nativeElement, { type: 'report' });
    embedResult.iframe.style.borderWidth = '0px';
    embedResult.iframe.addEventListener('load', () => {
      this.embedPowerBiReport(container);
    });
  }

  private async embedPowerBiReport(container: ElementRef<HTMLDivElement>) {
    try {
      const config = await this.loadEmbedOptions();
      this.report = window.powerbi.embed(container.nativeElement, config);

      this.report.on('rendered', () => {
        this.report.off('rendered');
        this.state = 'loaded';
        this.refreshReportPeriodically();
      });
      this.report.on('error', (e) => {
        console.error('Failed to load report', e);
        this.state = 'error';
      });
    } catch (e) {
      console.error('Failed to embed report', e);
      this.state = 'error';
    }
  }

  private scheduleAccessTokenRefresh(embedInfo: EmbedInfo): void {
    const expiry = new Date(embedInfo.expiry);
    const timeUntilExpires = Math.max(expiry.getTime() - new Date().getTime(), 0);
    this.refreshTokenTimeoutId = setTimeout(() => this.refreshReportAccessToken(), timeUntilExpires / 2);
  }

  private async refreshReportAccessToken(): Promise<void> {
    const config = await this.loadEmbedOptions();
    this.report.setAccessToken(config.accessToken);
  }

  private async loadEmbedOptions(): Promise<EmbedOptions> {
    const embedInfo = await this.powerBiService.getEmbedInfo(this, this.reportId);
    this.scheduleAccessTokenRefresh(embedInfo);
    return this.getEmbedOptions(embedInfo);
  }

  private getEmbedOptions(embedInfo: EmbedInfo): EmbedOptions {
    return {
      type: 'report',
      tokenType: pbi.models.TokenType.Embed,
      accessToken: embedInfo.accessToken,
      embedUrl: embedInfo.embedUrl,
      id: embedInfo.id,
      permissions: pbi.models.Permissions.All, // give All permissions to demonstrate switching between View and Edit mode and saving report.
      settings: {
          background: pbi.models.BackgroundType.Transparent,
          panes: {
              filters: {
                  visible: true,
              },
              pageNavigation: {
                  visible: false,
              },
          },
      },
    };
  }

  private refreshReportPeriodically() {
    this.refreshDataIntervalId = setInterval(async () => {
      try {
        await (this.report as any).refresh();
        this.state = 'loaded';
      } catch (e) {
        console.error('Failed to refresh report', e);
        this.state = 'error';
      }
    }, 60_000);
  }

}
