import { ImageUpload } from './upload.model';

export enum ChannelAlertsType {
    alert = 'alert',
    sms = 'sms',
    badge = 'badge',
    mobileAlert = 'mobileAlert',
    facilityOnly = 'facilityOnly',
    soundMuted = 'soundMuted',
    notificationsOff = 'notificationsOff',
    allowMuting = 'allowMuting',
    allowDisablingNotifications = 'allowDisablingNotifications',
    highPriorityChannel = 'highPriorityChannel',
}

export interface ChannelTyper {
    personId: string;
    status: ChannelTyperStatus;
    expires: Date;
}

export interface ChannelTypers {
    typers: ChannelTyper[];
}

export enum ChannelTyperStatus {
    Talking = 'talking',
    Typing = 'typing',
    None = 'none'
}

export type ChannelAlerts = Record<ChannelAlertsType, boolean>;

export interface ConditionalChannelAlerts extends ChannelAlerts {
    description: string;
    conditions: ConditionalProperty[];
}

export interface ConditionalProperty {
    key?: ConditionalKeys;
    value?: any;
    negate?: boolean;
}

export enum ConditionalKeys {
    People = 'people',
    PersonCompany = 'personCompany',
    PersonCraft = 'personCrafts',
    PersonSites = 'personSites',
    PersonTags = 'personTags',
}

export interface ChannelReadResponse {
    channelId?: string;
    readSequence?: number;
}

export enum ChannelType {
    Channel = 'channel',
    People = 'people'
}

export interface Channel {
    id?: string;
    folderId?: string;
    facilityId?: string;
    facilityName?: string;
    people?: string[];
    peopleFolders?: string[];
    peopleTags?: string[];
    peopleCrafts?: string[];
    companies?: string[];
    includeFacilityPeople?: boolean;
    type?: ChannelType;
    name?: string;
    readOnlySenders?: string[];
    isReadOnly?: boolean;
    facilityIds?: string[];
    roips?: { id: string; radio: number; }[];
    subscribed?: boolean;
    defaultNotification?: ChannelAlerts;
    conditionalNotificationOverrides?: ConditionalChannelAlerts[];
    preferencesDisabled?: boolean;
    notification?: ChannelAlerts;
    sequence?: number;
    readSequences?: { [userId: string]: number; };
    readSequence?: number;
    tags?: string[];
    lastMessages?: ChannelMessage[];
    updated?: string;
    radioChannel?: number;
    radioSquelch?: number;
    excludedPeople?: string[];
    excludedFacilityIds?: string[];
    excludedTags?: string[];
    excludedCrafts?: string[];
    excludedCompanies?: string[];
}

export enum MessageType {
    Text = 'text',
    Form = 'form',
    Video = 'video',
    Audio = 'audio'
}

export enum ChannelSource {
    Mobile = 'mobile',
    Walt = 'walt',
    Web = 'web',
}

export interface ChannelMessage {
    channelId: string;
    date: string;
    id: string;
    message: string;
    sender?: {
        id?: string;
        firstName?: string;
        lastName?: string;
        avatarFile?: string;
    };
    notification?: ChannelAlerts;
    type: MessageType;
    data?: ChannelMessageData;
}
export interface ChannelMessageData {
    ruleId?: string;
    event?: any;
    form?: {id?: string; version?: string; name?: string; title?: string };
    file?: {id?: string; name?: string; };
    buttons?: {acceptButtonText?: string, cancelButtonText?: string };
    attachments?: MessageAttachment[];
    radio?: string;
}

export interface DirectMessage {
    message: string;
    data?: ChannelMessageData;
    source?: ChannelSource;
}

export interface Communication {
    id: string;
    date: string;
    facilityId: string;
    personId: string;
    location?: number[];
    type: MessageType;
    duration: number;
    onsite?: boolean;
    source?: ChannelSource;
}

export interface ChannelMessageQuery {
    toId?: string;
    toDate?: string;
    limit?: number;
}

export const ALLOWED_IMAGE_FIlE_ATTACHMENT_TYPES: string = '.jpg, .jpeg, .png, .JPG, .JPEG, .PNG';
export const ALLOWED_FILE_ATTACHMENT_TYPES: string = '.csv, .pdf, text/plain, .zip, .CSV, .PDF, TEXT/PLAIN, .ZIP';
export const ALLOWED_VIDEO_FILE_ATTACHMENT_TYPES: string = '.mp4, .MP4';

export enum MessageAttachmentType {
    Image = 'image',
    Video = 'video',
    File = 'file',
    Audio = 'audio'
}
export interface MessageAttachment {
    type: MessageAttachmentType;
    file: ImageUpload;
    thumbnailUri?: string;
}
