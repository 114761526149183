import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { aggregate, CountFormatType, CountWidgetSettings, DashboardSource, DashboardWidgetConfig, formatWidget, Temperature, WidgetClickAction } from 'weavix-shared/models/dashboard.model';
import { AggregateType } from 'weavix-shared/models/data-source.model';
import { DashboardService } from 'weavix-shared/services/dashboard.service';
import { DataSourceResults, DataSourceService } from 'weavix-shared/services/data-source.service';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { AutoUnsubscribe, Utils } from 'weavix-shared/utils/utils';
import { RangeSection } from '../../range-slider/range-slider.model';

@AutoUnsubscribe()
@Component({
    selector: 'app-count',
    templateUrl: './count.component.html',
    styleUrls: ['./count.component.scss'],
})
export class CountComponent implements OnInit {
    static what: boolean = false;

    @Input() widgetData: DashboardWidgetConfig;
    @Input() sources: DashboardSource[];
    @Input() editable: boolean;
    @Output() editOutput: EventEmitter<string> = new EventEmitter();
    @Output() tableView: EventEmitter<any> = new EventEmitter();
    @Output() dashboardView: EventEmitter<any> = new EventEmitter();

    isLoading: boolean = true;
    widgetValue: string;
    rangeSection = RangeSection;
    activeRangeSection: RangeSection;
    color: string;

    results: DataSourceResults;

    get settings() { return _.get(this.widgetData, 'options.settings') as CountWidgetSettings; }
    get source() { return (this.sources || []).find(x => x.id === this.widgetData?.source)?.source; }
    get variables() { return this.widgetData?.variables ?? []; }
    get column() { return _.get(this.settings, 'column'); }
    get aggregationFn() { return _.get(this.settings, 'aggregation'); }
    get currency() { return _.get(this.settings, 'currency'); }
    get percentage() { return _.get(this.settings, 'percentage', false); }
    get format() { return _.get(this.settings, 'format'); }
    get temperature() { return _.get(this.settings, 'temperature'); }
    get thresholds() { return _.get(this.settings, 'range.thresholds', []); }

    constructor(
        private dataSourceService: DataSourceService,
        private translationService: TranslationService,
        private dashboardService: DashboardService,
    ) { }

    async ngOnInit() {

        if (this.source) {
            this.isLoading = true;
            this.dashboardService.setWidgetLoading(this, true);
            (await this.dataSourceService.subscribeData(this, this.source, this.widgetData.variables)).subscribe(results => {
                if (results.isLoading) {
                    this.isLoading = true;
                    this.dashboardService.setWidgetLoading(this, true);
                    return;
                }
                this.isLoading = false;
                this.dashboardService.setWidgetLoading(this, false);
                this.results = results;
                if (results.rows) this.setValue(Object.values(results.rows));
            });
        }
    }

    private setValue(rows: any[]): void {
        if (!this.column || !this.aggregationFn) return;

        const values: any[] = rows.map(r => _.get(r, this.column));
        const val = aggregate(values, this.aggregationFn);
        this.formatValue(val, rows);
    }

    private setActiveRangeSection(value, rows): void {
        if (value == null || isNaN(Number(value))) {
            this.color = 'black';
            this.activeRangeSection = null;
            return;
        }

        const result = formatWidget(_.get(this.widgetData, 'options.settings'), rows);
        if (result.length) {
            this.color = result[0].color;
            this.activeRangeSection = null;
            return;
        }

        this.color = null;
        if (this.thresholds.length) {
            if (value < this.thresholds[0]) this.activeRangeSection = RangeSection.low;
            else if (value >= this.thresholds[1]) this.activeRangeSection = RangeSection.high;
            else this.activeRangeSection = RangeSection.middle;
        }
    }

    private formatValue(num: number, rows) {
        this.setActiveRangeSection(num, rows);

        num = Math.round(num * 100) / 100;
        // const prevValue = this.widgetValue;
        switch (true) {
            case this.format === CountFormatType.Percent:
                this.widgetValue = `${num}%`;
                break;

            case this.format === CountFormatType.Temperature:
                if (this.temperature === Temperature.Fahrenheit) this.widgetValue = `${num}°F`;
                if (this.temperature === Temperature.Celsius) this.widgetValue = `${num}°C`;
                break;

            case this.format === CountFormatType.Currency || this.currency:
                this.widgetValue = this.translationService.numberToCurrency(num, this.currency);
                break;

            case this.format === CountFormatType.Duration:
                this.widgetValue = Utils.msToFormattedDuration(Math.max(num));
                break;

            default:
                this.widgetValue = num.toString();
        }

        // if (prevValue && this.widgetValue !== prevValue) {
        //     what(num);
        // }
    }

    async handleClick() {
        if (this.editable) return;
        switch (this.settings.clickAction) {
            case WidgetClickAction.Dashboard: {
                if (!this.settings.drillDown) return;

                const row = Object.values(this.results.rows)[0];
                this.dashboardView.emit({ row, drillDown: this.settings.drillDown });

                break;
            }

            default: {
                let results;
                if (this.source.sql && this.aggregationFn === AggregateType.Value) {
                    results = await this.dataSourceService.runDrillDownSql(
                        this, this.source.sql,
                        null,
                        null,
                        this.variables);
                } else {
                    results = {
                        meta: {
                            columns: await this.dataSourceService.getColumnsFrom(this, this.source),
                        },
                        rows: this.results.allRows || this.results.rows,
                    };
                }

                this.tableView.emit({ rows: results.rows, meta: results.meta });

                break;
            }
        }
    }
}
