import { Pipe, PipeTransform } from '@angular/core';

export function maybeDecimal(value: number) {
    value = Number(value);
    if (isNaN(value)) {
        return '';
    }
    if (Math.round(value * 10) % 10 === 0) return `${Math.round(value)}`;
    else return `${Math.round(value * 10) / 10}`;
}

@Pipe({ name: 'maybeDecimal', pure: false })
export class MaybeDecimalPipe implements PipeTransform {

    constructor() {
    }

    transform(value: any) {
        return maybeDecimal(value);
    }
}
