<div class="menu" id="settings-menu" *ngIf="profileInput">
    <div class="menu-section link" (click)="navigateToProfile()">
        <p>{{'generics.profile' | translate}}</p>
        <i class="fas fa-user"></i>
    </div>

    <div class="menu-section link" (click)="logout()">
        <p id="logout">{{'generics.logout' | translate}}</p>
        <i class="fa fa-sign-out-alt"></i>
    </div>
</div>