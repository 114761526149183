import { Location } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GridListItem, IGridsterDraggableOptions, IGridsterOptions } from 'angular2gridster';
import { AnalyticsService, StAction, StObject } from 'console/app/core/services/analytics.service';
import * as _ from 'lodash';
import { OpenDirection } from 'weavix-shared/models/core.model';
import { Dashboard, DashboardWidgetConfig, DashboardWidgetType, defaultWidgetSize, DrillDownConfig } from 'weavix-shared/models/dashboard.model';
import { AppRoute } from 'weavix-shared/models/navigation.model';
import { RowItem, TableOptions, TableRow } from 'weavix-shared/models/table.model';
import { PermissionAction } from 'weavix-shared/permissions/permissions.model';
import { ChannelService } from 'weavix-shared/services/channel.service';
import { DashboardService } from 'weavix-shared/services/dashboard.service';
import { DataSourceService, VariableField } from 'weavix-shared/services/data-source.service';
import { FacilityService } from 'weavix-shared/services/facility.service';
import { WorkFormService } from 'weavix-shared/services/form.service';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';
import { ChartComponent } from '../chart/chart.component';

export interface WidgetChangeEvent {
    breakpoint: any;
    changes: ('x' | 'y' | 'w' | 'h')[];
    isNew: boolean;
    item: GridListItem;
    oldValues: { x?: number; y?: number; w?: number; h?: number; };

}

@AutoUnsubscribe()
@Component({
  selector: 'app-widget-grid',
  templateUrl: './widget-grid.component.html',
  styleUrls: ['./widget-grid.component.scss'],
})
export class WidgetGridComponent implements OnInit {
    @Input() dashboard: Dashboard;
    @Input() editable: boolean;
    @Output() updateWidgetOutput: EventEmitter<DashboardWidgetConfig> = new EventEmitter();
    @Output() editWidgetOutput: EventEmitter<string> = new EventEmitter();

    readonly defaultWidgetSize = defaultWidgetSize;

    facilityId: string;

    gridsterOptions: { gridOptions: IGridsterOptions, dragOptions: IGridsterDraggableOptions };
    dashboardWidgetType = DashboardWidgetType;
    dashboards: Dashboard[];

    tableRows: any[];
    tableOptions: TableOptions;

    // modalType = ModalType;
    // activeModalType: ModalType;
    // modalInput: Modal = {
    //     isOpen: false,
    //     height: 200,
    //     width: 700,
    //     actions: {}
    // };

    // locked: boolean = true;

    openDirection = OpenDirection;
    @ViewChild(ChartComponent) chartComponent: ChartComponent;
    @ViewChild('container') container: ElementRef;

    canNavigateBack: boolean;

    constructor(
        private dashboardService: DashboardService,
        private formService: WorkFormService,
        private facilityService: FacilityService,
        private profileService: ProfileService,
        private router: Router,
        private route: ActivatedRoute,
        private channelsService: ChannelService,
        private location: Location,
    ) {}

    variables: VariableField[] = [];

    // private getStorageKey() {
    //     const segments = [].concat(...this.route.snapshot.pathFromRoot.map(x => x.url.map(y => y.path)));
    //     const facilityId = (segments.length > 2 ? ((segments.length % 2) === 1 ? segments[segments.length - 2] : segments[segments.length - 3]) : null) ?? '';
    //     return `${DEFAULT_DASHBOARD_KEY}${facilityId}`;
    // }

    async ngOnInit() {
        this.facilityId = this.facilityService.getCurrentFacility()?.id;
        this.canNavigateBack = this.route.snapshot.queryParams?.back;
        await this.initializeWidgets(this.dashboard);
        this.gridsterOptions = this.getGridOptions();
    }

    async handleWidgetChange(gridItem: WidgetChangeEvent, widget: DashboardWidgetConfig) {
        if (widget.position?.x === gridItem.item.x && widget.position?.y === gridItem.item.y
            && widget.size?.w === gridItem.item.w && widget.size?.h === gridItem.item.h) return;

        widget.position = { x: gridItem.item.x, y: gridItem.item.y };
        widget.size = { w: gridItem.item.w, h: gridItem.item.h };
        this.updateWidgetOutput.emit(widget);
    }

    hideTable() {
        this.tableOptions = null;
    }

    navigateBack() {
        if (this.tableOptions) this.hideTable();
        else this.location.back();
    }

    private async initializeWidgets(dashboard: Dashboard) {
        if (this.container) this.container.nativeElement.scrollTop = 0;
    }

    getGridOptions(): { gridOptions: IGridsterOptions, dragOptions: IGridsterDraggableOptions } {
        return {
            gridOptions: {
                direction: 'vertical',
                lanes: 12,
                responsiveView: true,
                responsiveSizes: false,
                dragAndDrop: this.hasPermission() && this.editable,
                resizable: this.hasPermission() && this.editable,
                shrink: false,
                minWidth: 1200,
                floating: false,
                cellHeight: 100,
                useCSSTransforms: true,
                responsiveOptions: [],
                lines: {
                    visible: true,
                },
            },
            dragOptions: {
                handlerClass: 'grip',
            },
        };
    }

    async dashboardView(event: { row: any; drillDown: DrillDownConfig; }) {
        const dashboardId = event.drillDown.dashboardId;
        if (!dashboardId) return;
        AnalyticsService.track(StObject.DashboardWidget, StAction.Clicked, this.constructor.name);
        const dashboard = await this.dashboardService.getDashboard(this, dashboardId);
        const route = [`../../../${dashboard.navParent}`, AppRoute.Dashboard, dashboardId];
        const queryParams: any = {
            back: 'true',
        };

        (event.drillDown.variables || []).forEach(variable => {
            if (variable.column) {
                const col = event.row[variable.column];
                queryParams[variable.name.toLowerCase()] = col instanceof Date ? col.toISOString() : String(col);
            } else if (variable.value) {
                queryParams[variable.name.toLowerCase()] = variable.value;
            }
        });

        this.router.navigate(route, {
            queryParams,
            relativeTo: this.route,
        });
    }

    tableView(event, name) {
        this.tableOptions = DataSourceService.buildTableOptions(event.meta, event.rows);
        this.tableOptions.title = name;
        this.tableOptions.translate = false;
        this.tableOptions.select.rowsClickable = true;
        this.tableOptions.columns.forEach(c => c.clickable = () => true);
        this.tableRows = event.rows;

        this.tableOptions.columns.forEach((col, i) => {
            if (i === this.tableOptions.columns.length - 1) {
                col.postfix = (row) => {
                    const action = this.getAction(row, col.colKey.toLowerCase());
                    if (!action.route) return null;

                    const type =
                        action.queryParams['personId'] ? 'person' :
                            action.queryParams['channelPersonId'] ? 'channelPerson' :
                                action.queryParams['geofenceId'] ? 'geofence' :
                                    action.queryParams['itemId'] ? 'item' :
                                        action.route[0] === 'formSubmissions' ? 'formSubmission' : null;

                                        // todo todo todo
                    const tooltip = `dashboard.widget.chart.goTo.${type}`;
                    return { value: `<i class="fas fa-chevron-right"></i>`, tooltip, clickable: false };
                };
            }
        });
    }

    async columnClick(row: TableRow, item: RowItem) {
        const action = this.getAction(row.original, item.colKey.toLowerCase());
        if (action.route) {
            if (action.queryParams.channelPersonId) {
                this.channelsService.openTabToRadio(this, action.queryParams.channelPersonId);
                return;
            }
            if (action.route[0] === 'formSubmissions') {
                const form = await this.formService.getSubmission(this, action.route[1]);
                action.route = ['facilities', form.facilityId ?? this.facilityId, 'forms', form.form.id, form.form.version, 'preview', action.route[1]];
            }
            this.router.navigate(action.route, { queryParams: action.queryParams, relativeTo: this.route });
        }
    }

    getAction(row: any, col: string) {
        const prefix = col.substring(0, col.indexOf(' '));
        let route;
        const queryParams: any = {};
        const keys = Object.keys(row);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (key === 'personId' && (!route || prefix === 'person')) {
                route = ['../../../people/live-look'];
                queryParams['personId'] = row.personId;
            }
            // todo open to radio tab
            // if (key === 'channelPersonId' && (!route || prefix === 'channelPerson')) {
            //     route = ['channels', row.channelPersonId];
            //     queryParams['channelPersonId'] = row.channelPersonId;
            // }
            if (key === 'geofenceId' && (!route || prefix === 'geofence')) {
                route = ['../../../people/live-look'];
                queryParams['geofenceId'] = row.geofenceId;
            }
            if (key === 'itemId' && (!route || prefix === 'item')) {
                route = ['../../../people/live-look'];
                queryParams['itemId'] = row.itemId;
            }
            // todo
            // if (key === 'formSubmissionId' && (!route || prefix === 'form')) {
            //     route = ['formSubmissions', row.formSubmissionId];
            // }
        }
        return { route, queryParams };
    }

    hasPermission() {
        return this.profileService.hasPermission(PermissionAction.EditDashboards, this.facilityId, this.dashboard?.folderId);
    }
}
