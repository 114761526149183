
import * as FileSaver from 'file-saver';
import { parse } from 'papaparse';

export const MAX_SIZE = 1048576 * 5;

export interface ImageInfo {
    uri: string;
    width: number;
    height: number;
}

export class FileUtils {


    static readCsv(text: string): string[][] {
        try {
            const parsed = parse(text, { skipEmptyLines: true });
            if (parsed.errors.length) {
                throw new Error(parsed.errors.toString());
            } else {
                return parsed.data as string[][];
            }
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    static writeCsv(lists: string[][]) {
        return lists.map(list => {
            return list.map(entry => `"${entry.toString().replace(/"/g, '""')}"`).join(',');
        }).join('\r\n');
    }

    static saveAsCsv(csvArray, fileName: string): void {
        const name = !fileName || fileName === '' ? 'export' : fileName;
        const blob = new Blob([csvArray], { type: 'text/csv' } );
        FileSaver.saveAs(blob, `${name}.csv`);
    }

    static async getImageInfo(fileBlob: Blob) {
        return new Promise<ImageInfo>((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(fileBlob);
            reader.onload = (event) => {
                const uri = event.target.result as string;
                const image = new Image();
                image.src = uri;
                image.onload = () => {
                    resolve({
                        uri,
                        width: image.width,
                        height: image.height
                    });
                };
            };
        });
    }

    static async getImageInfoFromUrl(fileUrl: string) {
        let response = await fetch(fileUrl);
        if (!response.ok) {
            console.error('Failed to get image from Url', fileUrl);
            return;
        }
        let data = await response.blob();
        let file = new File([data], 'file');
        return await this.getImageInfo(file);
    }

    static getFileName(fileUrl: string) {
        return ['http://', 'https://'].some(s => fileUrl?.includes(s)) ? fileUrl.split('/').pop() : fileUrl;
    }

    static dataURItoFile(dataURI: string, fileName: string): File {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new File([ab], fileName, { type: mimeString });
      }
}
