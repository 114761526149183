
<p class="function-value cPointer"
    *ngIf="!isLoading && widgetValue"
    [ngClass]="{
        'green': activeRangeSection === rangeSection.low,
        'orange': activeRangeSection === rangeSection.middle,
        'red': activeRangeSection === rangeSection.high
    }"
    [ngStyle]="{
        color: color
    }"
    (click)="handleClick()"
>
    {{widgetValue}}
</p>

<p class="widget-no-data no-data" *ngIf="!isLoading && !widgetValue">{{'dashboard.widget.count.noData' | translate}}</p>