import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import Highcharts3d from 'highcharts/highcharts-3d';
import markerClusters from 'highcharts/modules/marker-clusters';

import { v4 as uuid } from 'uuid';

import { AxisChartWidgetSettings, DashboardSource, DashboardVariable, DashboardWidgetType, getRow, groupWidget, PieChartWidgetSettings, WidgetClickAction } from 'weavix-shared/models/dashboard.model';
import { AggregateType, ColumnData, ColumnTypeMap, DataSourceTable } from 'weavix-shared/models/data-source.model';
import { ChartService } from 'weavix-shared/services/chart.service';
import { DashboardService } from 'weavix-shared/services/dashboard.service';
import { DataSourceService } from 'weavix-shared/services/data-source.service';

import { sleep } from 'weavix-shared/utils/sleep';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

Highcharts3d(Highcharts);
markerClusters(Highcharts);

@AutoUnsubscribe()
@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartComponent implements AfterViewInit, OnChanges, OnDestroy {
    id: string = uuid();
    @Input() type: DashboardWidgetType;
    @Input() chartSettings: AxisChartWidgetSettings | PieChartWidgetSettings;
    @Input() chartOptions: Highcharts.Options;
    @Input() size: any;
    @Input() title: string;
    @Input() chartData = [];
    @Input() containerStyle;
    @Input() selectionKey: string;
    @Input() editable: boolean;
    @Output() chartClick = new EventEmitter<Highcharts.Point>();
    @Output() tableView: EventEmitter<any> = new EventEmitter();
    @Output() dashboardView: EventEmitter<any> = new EventEmitter();
    @Output() chartDataOutput: EventEmitter<any[]> = new EventEmitter();

    // chart data and updates come from data source
    @Input() dataSources: DashboardSource[];
    @Input() sourceId: string;
    @Input() variables: DashboardVariable[];
    private chartSource: DataSourceTable;
    private columnTypeMap: ColumnTypeMap = {};
    private rows: any[] = [];

    isLoading: boolean;
    chart: Highcharts.Chart;

    left = 0;
    top = 0;
    width: any = '100%';
    height: any = '100%';

    constructor(
        private cdr: ChangeDetectorRef,
        private chartService: ChartService,
        private dataSourceService: DataSourceService,
        private dashboardService: DashboardService,
    ) { }

    async ngAfterViewInit() {
        if (this.chartData.length && this.chartOptions) {
            await this.setupChartOptions();
        } else if (this.sourceId && this.chartSettings) {
            await this.getDataFromSource();
        }
    }

    async ngOnChanges(e: SimpleChanges) {
        if (e.size && !e.size.firstChange && this.chart) await this.redrawChart();
        if (this.chartOptions && this.chartData.length) {
            if (e.chartOptions && !e.chartOptions.firstChange) {
                if (this.chart) this.chart.destroy();
                await this.setupChartOptions();
            } else if (e.chartData && !e.chartData.firstChange) {
                if (!this.chart) await this.setupChartOptions();
                else await this.dataUpdate();
            }
        }
    }

    ngOnDestroy() {}

    private async setupChartOptions() {
        if (this.chartSettings) {
            this.chartOptions = this.chartService.getChartOptions(this.type, this.chartData, this.chartSettings);

            if (Array.isArray(this.chartData[0])) {
                this.chartData.forEach((data, index) => {
                    this.chartOptions.series[index].events = {
                        click: async (event) => {
                            this.chartClick.emit(event.point);
                        },
                    };
                });
            } else {
                this.chartOptions.series[0].events = {
                    click: async (event) => {
                        this.chartClick.emit(event.point);
                    },
                };
            }
        }

        if (Array.isArray(this.chartData[0]) || Array.isArray(this.chartData[0]?.data)) {
            this.chartData.forEach((data, index) => {
                this.chartOptions.series[index] = {
                    ...this.chartOptions.series[index],
                    selected: index === 0,
                    ...data,
                };
            });
        } else {
            this.chartOptions.series[0]['data'] = this.chartData;
        }

        this.chart = Highcharts.chart(this.id, this.chartOptions);

        if (this.selectionKey) this.setInitialSelection();

        await this.calcPlot();
    }

    private async getDataFromSource() {
        this.chartSource = (this.dataSources || []).find(x => x.id === this.sourceId)?.source;

        if (this.chartSource) {
            this.isLoading = true;
            this.dashboardService.setWidgetLoading(this, this.isLoading);
            this.cdr.markForCheck();
            (await this.dataSourceService.subscribeData(this, this.chartSource, this.variables)).subscribe(results => {
                if (results.isLoading) {
                    this.isLoading = true;
                    this.dashboardService.setWidgetLoading(this, this.isLoading);
                    this.cdr.markForCheck();
                    return;
                }
                this.isLoading = false;
                this.dashboardService.setWidgetLoading(this, this.isLoading);

                if (results.updated && results.meta?.columns) {
                    this.createColumnTypeMap(results.meta.columns);
                }

                if (results.rows) {
                    this.rows = Object.values(results.rows);
                    if (this.chartOptions) {
                        this.chartData = this.chartService.getChartDataFromSource(this.rows, this.chartSettings, this.columnTypeMap, this.type);
                        this.dataUpdate();
                    } else {
                        this.setupChartOptionsWithDataSource();
                    }
                    this.cdr.markForCheck();
                }
            });
        }
    }

    private createColumnTypeMap(columns: ColumnData[]): ColumnTypeMap {
        columns.forEach(c => this.columnTypeMap[c.name] = c.type );
        return this.columnTypeMap;
    }

    private setupChartOptionsWithDataSource(): void {
        this.chartData = this.chartService.getChartDataFromSource(this.rows, this.chartSettings, this.columnTypeMap, this.type);
        this.chartDataOutput.emit(this.chartData);
        this.chartOptions = this.chartData ? this.chartService.getChartOptions(this.type, this.chartData, this.chartSettings) : null;

        if (this.chartOptions) {
            this.chartOptions.series[0].events = {
                click: async (event) => {
                    if (this.editable) return;
                    const selectedCategory = event.point.options.id;

                    switch (this.chartSettings.clickAction) {
                        case WidgetClickAction.Dashboard: {
                            if (!this.chartSettings.drillDown) return;

                            const row = this.rows.filter(r => {
                                const parsed = groupWidget(getRow(r, this.columnTypeMap), this.chartSettings, this.columnTypeMap);
                                return parsed.x === selectedCategory;
                            })[0];

                            this.dashboardView.emit({ row, drillDown: this.chartSettings.drillDown });

                            break;
                        }
                        default: {
                            this.isLoading = true;
                            this.dashboardService.setWidgetLoading(this, this.isLoading);
                            let results;
                            if (this.chartSettings.aggregate && this.chartSettings.aggregate !== AggregateType.Value) {
                                results = {
                                    meta: {
                                        columns: Object.keys(this.columnTypeMap).map(name => ({ name, type: this.columnTypeMap[name] }))
                                            .filter(c => !c.name.endsWith('Id') && !c.name.startsWith('id')),
                                    },
                                    rows: this.rows.filter(r => {
                                        const parsed = groupWidget(getRow(r, this.columnTypeMap), this.chartSettings, this.columnTypeMap);

                                        return parsed.x === selectedCategory;
                                    }),
                                };
                            } else {
                                if (this.chartSource.sql) {
                                    results = await this.dataSourceService.runDrillDownSql(
                                        this, this.chartSource.sql,
                                        this.chartSettings['x'] || this.chartSettings['labels'],
                                        selectedCategory,
                                        this.variables);
                                } else {
                                    results = {
                                        meta: {
                                            columns: await this.dataSourceService.getColumnsFrom(this, this.chartSource),
                                        },
                                        rows: this.rows.filter(r => {
                                            const parsed = groupWidget(getRow(r, this.columnTypeMap), this.chartSettings, this.columnTypeMap);
                                            return parsed.x === selectedCategory;
                                        }),
                                    };
                                }
                            }
                            this.isLoading = false;
                            this.dashboardService.setWidgetLoading(this, this.isLoading);

                            this.tableView.emit({ rows: results.rows, meta: results.meta });

                            break;
                        }
                    }
                },
            };
            this.chart = Highcharts.chart(this.id, this.chartOptions);
        }
    }

    private async dataUpdate() {
        if (!this.chartData) return;
        if (this.chartSettings) {
            this.chartService.updateData(this.type, this.chartData, this.chart, this.chartSettings);
        } else {
            if (Array.isArray(this.chartData[0]) || Array.isArray(this.chartData[0]?.data)) {
                this.chartData.forEach((data, index) => {
                    if (data?.data) this.chart.series[index].setData(data.data);
                    else this.chart.series[index].setData(data);
                });
            } else {
                this.chart.series[0].setData(this.chartData);
            }
        }
        this.chartDataOutput.emit(this.chartData);
        await this.calcPlot();
    }

    private async redrawChart() {
        if (!this.chart) return;
        this.chart.reflow();
        this.chart.redraw();
        await this.calcPlot();
    }

    private async calcPlot() {
        await sleep(100);
        if (this.type === DashboardWidgetType.DonutChart) {
            this.left = this.chart.plotLeft + (this.chart.plotWidth * 0.5) - 22.5;
            this.top = this.chart.plotTop + (this.chart.plotHeight * 0.3) + 12;
            this.width = 70;
            this.height = 70;
        } else {
            this.left = this.chart.plotLeft + 10;
            this.top = this.chart.plotTop + 8;
            this.width = this.chart.plotWidth;
            this.height = this.chart.plotHeight;
        }
        this.cdr.markForCheck();
    }

    private setInitialSelection(): void {
        // COMING SOON
        // this.chart.series.forEach(s => {
        //     (s.data ?? []).forEach(d => {
        //         if (d?.['key'] === this.selectionKey) d.select(true);
        //         else d.select(false, false);
        //     });
        // });
    }
}
