import { Injectable } from '@angular/core';

import { WorkForm, WorkFormSubmission } from '../models/work-forms.model';
import { AlertService, ServiceError } from './alert.service';
import { CacheContext, HttpService } from './http.service';

@Injectable({ providedIn: 'root' })
export class WorkFormService {

    constructor(
        private httpService: HttpService,
        private alertsService: AlertService,
    ) { }

    static baseUrl = '/core/forms';
    static cacheCollection = 'WorkForms';
    static idPath = 'id.id';
    private localForms: WorkForm[] = [];
    private readonly cacheContext: CacheContext = { collection: WorkFormService.cacheCollection, maxAge: 1800000 };
    static submissionsUrl = (id?: string) => id ? `${WorkFormService.baseUrl}/submissions/${id}` : `${WorkFormService.baseUrl}/submissions`;
    static url = (id?: string | {id: string, version: string}) => id ? `${WorkFormService.baseUrl}/${id}` : WorkFormService.baseUrl;

    async getAll(component, tags?: string[]) {
        return await this.getForms(component, WorkFormService.url(), tags);
    }

    async getAllByFacility(component, facilityId: string, tags?: string[]) {
        return await this.getForms(component, `${WorkFormService.url()}/facility/${facilityId}`, tags);
    }

    private async getForms(component, url: string, tags?: string[]) {
        try {
            const res = await this.httpService.get<WorkForm[]>(
                component,
                url,
                { tags }
            );
            this.localForms = res;
            return res;
        } catch (e) {
            this.alertsService.sendServiceError(e, ServiceError.Get, 'forms.form');
            this.alertsService.setAppLoading(false);
            throw e;
        }
    }

    add(component, wForm: WorkForm) {
        return this.httpService.post<WorkForm>(component, WorkFormService.url(), wForm, this.cacheContext);
    }

    update(component, form: WorkForm) {
        return this.httpService.put<WorkForm>(component, form.currentVersion ? `${WorkFormService.url(form.id)}/${form.currentVersion}`
            : WorkFormService.url(form.id),  form, this.cacheContext);
    }

    getLocalForms() {
        return this.localForms;
    }

    async getLocalFormById(component, id: string) {
        if (!this.localForms?.length) await this.getAll(component);
        return this.localForms.find(r => r.id === id);
    }

    getSubmission(component, id: string) {
        return this.httpService.get<WorkFormSubmission>(component, WorkFormService.submissionsUrl(id));
    }

}
