import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment-timezone';

import { DashboardSource, DashboardVariable } from 'weavix-shared/models/dashboard.model';
import { FieldType } from 'weavix-shared/models/item.model';
import { TIME_ZONE_KEY } from 'weavix-shared/models/report.model';
import { DataSourceService } from 'weavix-shared/services/data-source.service';
import { AutoUnsubscribe, Utils } from 'weavix-shared/utils/utils';
import { DropdownItem } from '../dropdown/dropdown.model';



@AutoUnsubscribe()
@Component({
    selector: 'app-dashboard-variable',
    templateUrl: './dashboard-variable.component.html',
    styleUrls: ['./dashboard-variable.component.scss'],
})
export class DashboardVariableComponent implements OnInit, OnDestroy, OnChanges {
    @Input() variable: DashboardVariable;
    @Input() sources: DashboardSource[] = [];
    @Input() control: FormControl;
    @Input() label: string;
    @Input() showLabel: boolean = true;
    @Input() default: boolean;

    variableTypes = FieldType;
    dateControl: FormControl;
    timezones: DropdownItem[] = [];

    constructor(
        private dataSourceService: DataSourceService,
    ) {
        this.timezones = moment.tz.names().map(t => ({ label: `${t.replace('_', ' ')} (GMT${moment.tz(t).format('Z')})`, key: t }));
    }

    async ngOnInit() {
        if (!this.control) {
            this.control = new FormControl(this.variable.value);
            this.control.valueChanges.subscribe(v => this.variable.value = v);
        }

        if (this.variable.type === FieldType.Dropdown && this.variable.name?.toLowerCase() === TIME_ZONE_KEY) {
            this.variable.options = this.timezones;

        } else if (this.variable.dataSourceId && this.variable.type === FieldType.Dropdown) {
            const source = this.sources.find(x => x.id === this.variable.dataSourceId);
            const subs = (await this.dataSourceService.subscribeSource(source.source)).subscribe(results => {
                this.variable.options = Utils.sortAlphabetical(Object.keys(results.rows).map(r => ({
                    key: results.rows[r][this.variable.dataSourceValues],
                    label: String(results.rows[r][this.variable.dataSourceLabels]),
                })), (x: any) => x.label);
                if (this.variable.options.length && !this.variable.options.some(x => x.key === this.control.value)) {
                    this.control.setValue(this.variable.options[0].key);
                }
                if (subs) subs.unsubscribe();
            });

        } else if (this.variable.type === FieldType.Fixed) {
            if (this.variable.options?.length && !this.variable.options.some(x => x.key === this.control.value)
                && this.variable.options.some(x => x.key)) {
                this.control.setValue(this.variable.options[0].key);
            }

        } else if (this.variable.type === FieldType.Date) {
            if (this.default) {
                this.dateControl = new FormControl(this.control.value);
                this.dateControl.valueChanges.subscribe(v => {
                    this.control.setValue(this.dateControl.value);
                });
                this.control.setValue(this.dateControl.value);
            } else {
                this.dateControl = new FormControl(Utils.fromUtcDate(Utils.toUtcDate(this.control.value || new Date())) || new Date());
                this.dateControl.valueChanges.subscribe(v => {
                    this.control.setValue((Utils.fromUtcDate(Utils.toUtcDate(this.dateControl.value)) || new Date()).toISOString());
                });
                this.control.setValue((Utils.fromUtcDate(Utils.toUtcDate(this.dateControl.value)) || new Date()).toISOString());
            }
        }
    }

    async ngOnChanges() {
        this.ngOnInit();
    }

    ngOnDestroy() {}

}
