import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { AccountService } from './account.service';
import { CacheContext, HttpService } from './http.service';
import { PubSubService } from './pub-sub.service';

import { Craft } from '../models/craft.model';
import { Topic } from '@weavix/models/src/topic/topic';
import { Utils } from '../utils/utils';


@Injectable({
    providedIn: 'root'
})
export class CraftService {

    constructor(
        private httpService: HttpService,
        private pubSubService: PubSubService,
        private accountService: AccountService
    ) { }

    static baseUrl = '/core/crafts';
    static cacheCollection = 'crafts';
    private readonly cacheContext: CacheContext = { collection: CraftService.cacheCollection, maxAge: 1800000 };
    private colors$: BehaviorSubject<string[]>;

    static url = (id?: string) => id ? `${CraftService.baseUrl}/${id}` : CraftService.baseUrl;

    getAll(component: any, tags?: string[]) {
        return this.httpService.get<Craft[]>(component, CraftService.url(), { tags }, this.cacheContext);
    }

    async getEnabled(component: any) {
        const crafts = await this.getAll(component);
        return crafts.filter(x => !x.disabled);
    }

    async getEnabledMap(component: any) {
        return Utils.toObjectMap(await this.getEnabled(component), x => x.id, x => x);
    }

    get(component: any, id: string) {
        return this.httpService.get<Craft>(component, CraftService.url(id), null, this.cacheContext);
    }

    save(component: any, craft: Partial<Craft>) {
        return craft.id
            ? this.update(component, craft.id, craft)
            : this.add(component, craft);
    }

    private add(component: any, craft: Partial<Craft>) {
        return this.httpService.post<Craft>(component, CraftService.url(), craft, this.cacheContext);
    }

    private update(component: any, id: string, craft: Partial<Craft>) {
        return this.httpService.put<Craft>(component, CraftService.url(id), craft, this.cacheContext);
    }

    delete(component: any, id: string) {
        return this.httpService.delete<void>(component, CraftService.url(id), null, this.cacheContext);
    }

    async colors(component: any) {
        if (!this.colors$) {
            this.colors$ = new BehaviorSubject(await this.httpService.get<string[]>(component, `${CraftService.url()}/colors`, null, this.cacheContext, null));
        }
        return await this.colors$.pipe(take(1)).toPromise();
    }

    async subscribeCraftUpdates(c: any, id: string = '+') {
        return await this.pubSubService.subscribe<Craft>(c, Topic.AccountCraftUpdated, [this.accountService.getAccountId(), id]);
    }
}
