import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { Dashboard, DashboardVariable } from 'weavix-shared/models/dashboard.model';
import { DataSourceService, VariableField } from 'weavix-shared/services/data-source.service';
import { FacilityService } from 'weavix-shared/services/facility.service';
import { ProfileService } from 'weavix-shared/services/profile.service';

@Component({
  selector: 'app-dashboard-filter',
  templateUrl: './dashboard-filter.component.html',
  styleUrls: ['./dashboard-filter.component.scss'],
})
export class DashboardFilterComponent implements OnInit {

    @Input() dashboard: Dashboard;

    variables: VariableField[];

    constructor(private facilityService: FacilityService,
        private profileService: ProfileService,
        private dataSourceService: DataSourceService,
        private route: ActivatedRoute) { }

    async ngOnInit() {
        if (this.dashboard) {
            await this.initializeVariableFields();
        }
    }

    private async initializeVariableFields() {
        const [facility, user] = await Promise.all([this.facilityService.getCurrentFacility(), await this.profileService.getUserProfile(this)]);
        const timezone = facility?.timezone || moment.tz.guess();
        const variablesMap = {
            FACILITYID: facility?.id || '',
            PERSONID: user.personId,
            TIMEZONE: timezone,
        };

        await this.dataSourceService.setVariables(variablesMap);
        this.dataSourceService.setAutoRefresh(this.dashboard.autoRefresh);

        const dashboardVariablesMap: { [name: string]: DashboardVariable } = (this.dashboard.variables || []).reduce((acc, v) => (acc[v.name.toUpperCase()] = v, acc), {});
        const variables = [];
        this.dataSourceService.subscribeVariables(this, 'dataSource', dashboardVariablesMap, variables, this.dashboard.sources, timezone);

        variables.forEach(variable => {
            if (this.route.snapshot.queryParamMap.has(variable.name.toLowerCase())) {
                const newVal = this.route.snapshot.queryParamMap.get(variable.name.toLowerCase());
                variable.control.setValue(newVal);
            }
        });

        variables.forEach(va => variablesMap[va.name] = va.control.value);
        await this.dataSourceService.setVariables(variablesMap);
        this.variables = variables;
    }

}
