<div id="dashboard-grid" class="grid page-wrapper">
            
    <button class="back-button" *ngIf="canNavigateBack || tableOptions" (click)="navigateBack()">
        <app-icon faIcon="fas fa-chevron-left"></app-icon>
    </button>

    <div class="grid-container" [class.conceal]="tableOptions">
        <div class="widgets-container" #container>
            
            <ng-container *ngIf="dashboard?.widgets.length > 0 && gridsterOptions">
                <ngx-gridster [options]="gridsterOptions.gridOptions" [draggableOptions]="gridsterOptions.dragOptions">
                    <ngx-gridster-item *ngFor="let widget of dashboard?.widgets"
                        (change)="handleWidgetChange($event, widget)"
                        [y]="widget?.position?.y || 0"
                        [x]="widget?.position?.x || 0"
                        [w]="widget?.size?.w || defaultWidgetSize.w"
                        [h]="widget?.size?.h || defaultWidgetSize.h"
                    >
                        <app-widget-container
                            *ngIf="widget"
                            [widgetData]="widget"
                            [sources]="dashboard.sources"
                            [editable]="hasPermission() && editable"
                            (editOutput)="editWidgetOutput.emit($event)"
                            class="widget-container"
                        >
    
                            <app-chart-widget
                                *ngIf="[dashboardWidgetType.BarChart, dashboardWidgetType.PieChart, dashboardWidgetType.LineChart].includes(widget.type)"
                                class="chart-widget w100p h100p"
                                [widget]="widget"
                                [sources]="dashboard.sources"
                                [editable]="hasPermission() && editable"
                                (tableView)="tableView($event, widget.name)"
                                (dashboardView)="dashboardView($event)"
                            ></app-chart-widget>
    
                            <app-count
                                *ngIf="widget.type === dashboardWidgetType.Count"
                                class="count-widget"
                                [widgetData]="widget"
                                [sources]="dashboard.sources"
                                [editable]="hasPermission() && editable"
                                (tableView)="tableView($event, widget.name)"
                                (dashboardView)="dashboardView($event)"
                            ></app-count>
    
                            <app-table-widget
                                *ngIf="widget.type === dashboardWidgetType.Table"
                                class="table-widget"
                                [widgetData]="widget"
                                [sources]="dashboard.sources"
                                [editable]="hasPermission() && editable"
                                (dashboardView)="dashboardView($event)"
                            ></app-table-widget>
    
                            <app-rich-text-widget
                                *ngIf="widget.type === dashboardWidgetType.RichText"
                                class="rich-text-widget"
                                [widgetData]="widget"
                                [editable]="hasPermission() && editable"
                                [sources]="dashboard.sources"
                                (tableView)="tableView($event, widget.name)"
                                (dashboardView)="dashboardView($event)"
                            ></app-rich-text-widget>
    
                        </app-widget-container>
                    </ngx-gridster-item>
                </ngx-gridster>
            </ng-container>

            <div class="no-widgets" *ngIf="!editable && dashboard?.widgets.length === 0">
                <div class="no-widgets-wrapper" >
                    <p class="no-data">{{'dashboards.no-widgets' | translate}}</p>
                </div>
            </div>

        </div>
    </div>

    <ng-container *ngIf="tableOptions">
        <app-table
            class="mT20"
            [rows]="tableRows"
            [options]="tableOptions"
            (columnClickOutput)="columnClick($event.row, $event.item)"
        ></app-table>
    </ng-container>
</div>
