<div
    class="relative-date"
>
    <app-dropdown
        class="input first"
        [translate]="true"
        [dropdownInput]="addTypes"
        emptyOption="generics.dates.type-none"
        floatedLabel="{{'generics.dates.type' | translate:{label:label} }}"
        [ngModel]="value.type"
        (ngModelChange)="setType($event)"
        [setDefault]="false"
        [skipSort]="true"
    ></app-dropdown>

    <app-input
    class="input"
        type="text"
        [ngModel]="value.amount"
        (ngModelChange)="setAmount($event)"
        label="generics.dates.amount"
        *ngIf="value.type === 'add' || value.type === 'subtract'"
    ></app-input>
    <app-dropdown
    class="input"
        [translate]="true"
        [dropdownInput]="unitTypes"
        floatedLabel="{{'generics.dates.unit' | translate}}"
        [ngModel]="value.unit"
        (ngModelChange)="setUnit($event)"
        *ngIf="value.type === 'add' || value.type === 'subtract'"
        [setDefault]="false"
        [skipSort]="true"
    ></app-dropdown>
</div>
<div class="relative-date mT5" [ngClass]="lessSpacing ? 'mT5' : 'mT20'">
    <app-dropdown
    class="input first"
        [translate]="true"
        [dropdownInput]="fixTypes"
        emptyOption="generics.dates.fix-none"
        floatedLabel="{{'generics.dates.fixType' | translate:{label:label} }}"
        [ngModel]="value.fixType"
        (ngModelChange)="setFixType($event)"
        [setDefault]="false"
        [skipSort]="true"
    ></app-dropdown>
    <app-dropdown
    class="input"
        [translate]="true"
        [dropdownInput]="fixUnits"
        floatedLabel="{{'generics.dates.unit' | translate}}"
        [ngModel]="value.fixUnit"
        (ngModelChange)="setFixUnit($event)"
        *ngIf="value.fixType === 'start' || value.fixType === 'end'"
        [setDefault]="false"
        [skipSort]="true"
    ></app-dropdown>
    <app-dropdown
    class="input"
        [translate]="true"
        [dropdownInput]="daysOfWeek"
        floatedLabel="{{'generics.dates.dow' | translate}}"
        [ngModel]="value.dayOfWeek"
        (ngModelChange)="setDayOfWeek($event)"
        *ngIf="(value.fixType === 'start' || value.fixType === 'end') && value.fixUnit === 'week'"
        [setDefault]="false"
        [skipSort]="true"
    ></app-dropdown>

</div>
