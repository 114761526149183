import { Injectable } from '@angular/core';
import { merge } from 'lodash';
import { ReplaySubject } from 'rxjs';
import { Point } from '../models/location.model';
import { Geofence, MapFilterCategory, MapFilterResult, MapGeofence } from '../models/weavix-map.model';
import { BoxTree } from '../utils/polygon';

@Injectable()
export class MapFilterService {
    defaultFilters: { [key in MapFilterCategory]?: MapFilterResult } = {};
    updateMapFilters: ReplaySubject<MapFilterResult> = new ReplaySubject();
    selectedFilters: {[key: string]: MapFilterResult} = {};

    /**
     * @param geofenceMap map of geofences to check
     * @param locationMap map of locations to check. Mapped by entity id ([personId], [waltId] ...)
     * @param defaultGeofences pre-determined geofences to include in return - (used for heatmap data)
     * @returns map by person to gefence they are inside. {[personId]: {[geofenceId]}}
     */
    static getPopulatedGeofences(
        geofenceMap: Map<string, MapGeofence>,
        locationMap: { [id: string]: any & { location: Point}[] },
        defaultGeofences = {},
        includeFloorPlanGeos: boolean
    ): {[key: string]: {[key: string]: any}} {
        let geofencesWithPeopleInside;
        const geofenceTree = new BoxTree<Geofence, any>((obj) => obj.vertices, 0);
        if (geofenceMap) {
            for (const geofence of geofenceMap.values()) {
                if (geofence.floorPlanId && !includeFloorPlanGeos) continue;
                geofenceTree.add(geofence);
            }
        }

        geofencesWithPeopleInside = defaultGeofences;
        Object.keys(locationMap).forEach(id => {
            const inside = {};
            geofenceTree.find(locationMap[id], inside);
            const cache = geofencesWithPeopleInside[id] ?? {};
            geofencesWithPeopleInside[id] = cache;
            Object.keys(inside).forEach((gId: string) => {
                inside[gId].forEach(x => {
                    if (!cache[gId]) cache[gId] = 0;
                    cache[gId] += x.weight || 0;
                });
            });
        });

        return geofencesWithPeopleInside;
    }

    public setDefaultFilters(filters: { [key in MapFilterCategory]?: MapFilterResult }) {
        this.defaultFilters = filters;
    }

    setActiveMapFilter(cat?: MapFilterResult) {
        this.selectedFilters[cat.key] = { ...cat, selected: true };
        this.updateMapFilters.next(this.selectedFilters[cat.key]);
    }

    removeActiveFilter(key: string): void {
        const selection = { ...this.selectedFilters[key], selected: false };
        delete this.selectedFilters[key];
        this.updateMapFilters.next(selection);
    }

    isFilterEmpty(): boolean {
        return !Object.keys(this.selectedFilters).length;
    }

    getFilter(geofenceMap: Map<string, Geofence>) {
        const ids = Object.values(this.selectedFilters).reduce((obj, curr) => ({
            people: merge(curr?.children ?? {}, obj.people),
            geofences: merge(curr?.filters?.geofences ?? {}, obj.geofences)
        }), { people: {}, geofences: {} });

        const hasFilter = Object.keys(ids.people).length;
        const hasGeofence = ids.geofences && Object.keys(ids.geofences).length;

        const tree = new BoxTree<Geofence, any>((obj) => obj.vertices, 0);
        if (hasGeofence) {
            Object.keys(ids.geofences).forEach(id => {
                const geofence = geofenceMap?.get(id);
                if (geofence) tree.add(geofence);
            });
        }
        return (personId, location) => {
            if (hasFilter && !ids.people[personId]) return false;
            if (!hasGeofence) return true;
            if (typeof location === 'string') return ids.geofences[location];
            return tree.findQuick(location);
        };
    }
}
