import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { css } from 'weavix-shared/utils/css';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';
import { Modal, ModalActionType } from '../modal/modal.model';
import { Avatar } from './avatar.model';

@AutoUnsubscribe()
@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent {
    imageSrcError: boolean = false;
    @Input() set avatarInput(avatarInput: Avatar) {
        this.avatarProperties = avatarInput;
        if (avatarInput?.hasBorders) {
            if (avatarInput?.borderWidth) {
                this.borderWidth = avatarInput?.borderWidth;
            } else if (avatarInput?.height < 30) {
                this.borderWidth = 1;
            } else if (avatarInput?.height < 60) {
                this.borderWidth = 2;
            } else {
                this.borderWidth = 3;
            }
        }
    }
    public avatarProperties: Avatar;
    public borderWidth: number;
    @Input() readonly: boolean = false;
    @Input() backgroundColor: string;
    @Output() avatarEditOutput: EventEmitter<File> = new EventEmitter();
    newAvatarFile: File;

    editModal: Modal = {
        isOpen: false,
        width: 600,
        header: {
            textKey: 'avatar.user-photo',
            showSeparator: true,
        },
        actions: {
            [ModalActionType.cancel]:  { show: true, textKey: 'generics.cancel', buttonClass: 'cancel' },
            [ModalActionType.submit]:  { show: true, textKey: 'generics.submit', buttonClass: 'blue-lt', disabled: () => !this.newAvatarFile },
        },
        fullScreen: false,
        content: true,
    };

    constructor() { }

    getBackgroundColor() {
        return this.avatarProperties?.placeholder?.backgroundColor ?? css.colors.GRAY;
    }

    getIconColor() {
        return this.avatarProperties?.placeholder?.iconColor ?? css.colors.WHITE;
    }

    getFontSize() {
        return (this.avatarProperties?.width ?? this.avatarProperties?.height ?? 30) * .5;
    }

    getCircular() {
        return this.avatarProperties?.circular ?? true;
    }

    getBorderRadius() {
        return this.getCircular() ? '50%' : '3px';
    }

    getOutlineColor() {
        return this.avatarProperties?.outlineColor ?? css.colors.WHITE;
    }

    showEditModal() {
        this.editModal.isOpen = true;
    }

    handleAvatarEditOutput(newAvatarFile: File) {
        this.imageSrcError = false;
        this.newAvatarFile = newAvatarFile;
    }

    handleEditModalClose(action: ModalActionType) {
        if (action === ModalActionType.submit) {
            this.avatarEditOutput.emit(this.newAvatarFile);
        }
        this.closeEditModal();
    }

    closeEditModal() {
        this.newAvatarFile = null;
        this.editModal.isOpen = false;
    }
}
