import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[appPreventInput]'
})
export class PreventInputDirective {
    @HostListener('keydown', ['$event'])
    onInputChange(event) {
        event.preventDefault();
    }
}
