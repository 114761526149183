import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { UserBadge } from 'weavix-shared/models/user.model';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { AutoUnsubscribe, getRadioWindowFeatures, RADIO_WINDOW_TARGET, Utils } from 'weavix-shared/utils/utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
    notificationCount: number = 0;

    constructor(
        private profileService: ProfileService,
    ) {}

    ngOnInit() {
        this.getData();
    }

    async getData(): Promise<void> {
        const badges = await this.profileService.refreshMyBadge(this);

        Utils.safeSubscribe(this, await this.profileService.subscribeNotifications(this))
            .subscribe(notif => this.updateCount(notif.payload.badges, true));
        Utils.safeSubscribe(this, await this.profileService.subscribeBadgeUpdates(this))
            .subscribe(notif => this.updateCount(notif.payload, true));

        this.updateCount(badges);
    }

    private updateCount(badge: UserBadge, updateUserProfile?: boolean) {
        if (updateUserProfile) this.profileService.updateUserProfileBadges(badge);

        this.notificationCount = Math.min(99, (badge?.messages ?? 0) + (badge?.alerts ?? 0));
    }

    onClick(event: MouseEvent) {
        if (event.button === 1) window.open(environment.radioUrl, RADIO_WINDOW_TARGET);
        else window.open(environment.radioUrl, RADIO_WINDOW_TARGET, getRadioWindowFeatures());
    }
}
