import { Subject } from 'rxjs';
import { Facility } from '../models/facility.model';
import { MapTrackingCircle } from '../models/weavix-map.model';
import { PermissionAction } from '../permissions/permissions.model';
import { Utils } from '../utils/utils';
import { FacilityService } from './facility.service';
import { ProfileService } from './profile.service';
import { StateServiceBase, StateUpdate } from './state-base.service';

export class TrackingCircleStateService extends StateServiceBase {
    trackingCircles: Map<string, MapTrackingCircle> = new Map();
    trackingCircles$: Subject<StateUpdate<MapTrackingCircle>> = new Subject();

    constructor(
        private facilityService: FacilityService,
        private profileService: ProfileService
    ) {
        super();
    }

    async loadData(c: any, accountId: string, facilityId?: string, tags?: string[]) {
        if (!this.profileService.hasPermission(PermissionAction.ViewWorkerLiveLook, facilityId)) return;
        const facility = await this.facilityService.get(c, facilityId, false);
        this.trackingCircles = Utils.toMap(facility.trackingCircles, t => `${t.latitude}-${t.longitude}`);
    }

    async stop() {
        super.stop();
        this.trackingCircles.clear();
    }

    protected async setupSubscriptions(c: any,  accountId: string, facilityId?: string) {
        if (!this.profileService.hasPermission(PermissionAction.ViewWorkerLiveLook, facilityId)) return;

        return (await this.facilityService.subscribeFacilityUpdates(c, accountId, facilityId)).subscribe(async result => {
            const facility = Object.values(result.payload)[0] as Facility;
            const trackingCirclesMap = Utils.toObjectMap(facility.trackingCircles, t => `${t.latitude}-${t.longitude}`, t => t);
            const updatedIds = Object.keys(trackingCirclesMap);
            const deletedTrackingCirles = Array.from(this.trackingCircles.values()).filter(t => !updatedIds.some(id => id === `${t.latitude}-${t.longitude}`));
            const updateMap = Object.assign({} , trackingCirclesMap, Utils.toObjectMap(deletedTrackingCirles, t => `${t.latitude}-${t.longitude}`, t => null));

            this.updateFromMap(this.trackingCircles, updateMap, this.trackingCircles$);
        });
    }
}
