 
<div class="widget-no-data" *ngIf="!isLoading && !tableOptions">
    <p class="no-data">{{'dashboard.widget.table.noData' | translate}}</p>
</div>

<app-table
    class="h100p w100p"
    *ngIf="!isLoading && tableOptions"
    [rows]="filteredRows"
    [options]="tableOptions"
    (rowClickOutput)="handleRowClick($event)"
></app-table>

