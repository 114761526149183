import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateRangePickerHeaderComponent } from 'console/app/shared/date-range-picker/date-range-picker-header/date-range-picker-header.component';
import { debounce, isEqual } from 'lodash';
import * as moment from 'moment';
import { DateRange, TimeRange } from 'weavix-shared/models/dvr.model';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

export interface DateRangeWithShift extends DateRange {
    shift: TimeRange;
}

@AutoUnsubscribe()
@Component({
    selector: 'app-date-range-picker',
    templateUrl: './date-range-picker.component.html',
    styleUrls: ['./date-range-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateRangePickerComponent),
            multi: true,
        },
    ],
})
export class DateRangePickerComponent implements OnInit, ControlValueAccessor {
    @Input() dateRange: DateRange;
    @Input() timeRange: TimeRange;
    @Input() customStyle: string;
    @Input() includeTimeRange: boolean = true;
    @Output() dateRangeChanged: EventEmitter<DateRange> = new EventEmitter();
    @ViewChild('picker') private rangePicker: MatDatepicker<Date>;

    customHeader = DateRangePickerHeaderComponent;
    maxDate = new Date();
    currRange: DateRangeWithShift;
    dateRangeForm = new FormGroup({
        from: new FormControl(),
        to: new FormControl(),
        shift: new FormGroup({
            from: new FormControl(),
            to: new FormControl(),
        }),
    });
    doneLabel: string;
    endDateLabel: string;
    startDateLabel: string;
    translatedLabel: string;
    separator = '';
    debouncedFormSubmission = debounce((range: DateRangeWithShift) => this.emitValueChanges(range), 500);
    tzDiff: number;

    propagateChange = (dateRange: DateRangeWithShift) => null;

    constructor(
        private translationService: TranslationService,
    ) { }

    async ngOnInit() {
        const result = await this.translationService.getTranslations([
            'shared.generics.done',
            'shared.filters.end-date',
            'shared.filters.date-range',
            'shared.filters.start-date',
        ]).toPromise();

        this.doneLabel = result['shared.generics.done'];
        this.endDateLabel = result['shared.filters.end-date'];
        this.translatedLabel = result['shared.filters.date-range'];
        this.startDateLabel = result['shared.filters.start-date'];
        this.initializeForm(this.dateRange, this.timeRange);
    }

    private initializeForm(dateRange: DateRange, timeRange: TimeRange) {
        if (!dateRange) return;
        this.dateRangeForm.setValue({
            from: new Date(dateRange?.from),
            to: new Date(dateRange?.to),
            shift: {
                from: timeRange?.from ?? new Date(),
                to: timeRange?.to ?? new Date(),
            },
        });

        this.separator = dateRange.to ? '-' : '';

        this.setCurrentRangeValue({ ...dateRange, shift: timeRange });
    }

    openPicker() {
        this.dateRangeForm.markAsDirty();
        if (this.rangePicker.opened) return;
        this.rangePicker.open();
        this.dateRangeForm.markAsPristine();
    }

    private setCurrentRangeValue(range: DateRangeWithShift): void {
        this.currRange = range;
    }

    handleSubmission() {
        const newRangeValue = { ...this.dateRangeForm.value } as any;
        if (!isEqual(newRangeValue, this.currRange)) this.debouncedFormSubmission(newRangeValue);
        this.rangePicker.close();
    }

    emitValueChanges = (dateRange: DateRangeWithShift) => {
        this.setCurrentRangeValue(dateRange);
        if (!dateRange.to) {
            dateRange.to = moment(dateRange.from).endOf('day').toDate();
            this.dateRangeForm.controls['to'].setValue(dateRange.to, { emitEvent: false });
        }
        this.separator = dateRange.to ? '-' : '';
        this.dateRangeChanged.emit(dateRange);
        this.propagateChange(dateRange);
    };

    registerOnChange(fn: (dateRange: DateRange) => void): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {}

    writeValue(obj: any): void {
        if (!obj) return;
        this.initializeForm(obj, this.timeRange);
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) this.dateRangeForm.disable();
        else this.dateRangeForm.enable();
    }
}
