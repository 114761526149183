<div *ngIf="chartOptions"
    class="donut-chart" 
    [ngClass]="{'vertical': styleOptions?.verticalLayout}"
    [style.background]="styleOptions?.backgroundColor"
>
    <ng-container *ngIf="loading">
        <app-loader></app-loader>
    </ng-container>

    <ng-container *ngIf="!loading">
        <p class="donut-chart-title" *ngIf="chartTitleKey[chartType]">
            <span>{{chartTitleKey[chartType] | translate}}</span>
            <span *ngIf="timeRangeDisplay">{{timeRangeDisplay}}</span>
        </p>

        <div class="flex1 aiC jcC" *ngIf="isError">
            <p class="no-data">{{'ERRORS.GENERIC.LOAD-DATA'| translate}}</p>
        </div>

        <div class="donut-chart-area" [ngClass]="{'no-data': chartData.length == 0}"  *ngIf="!isError">
            <!-- Swap legend and data if vertical -->
            <ng-container *ngIf="!styleOptions?.verticalLayout">
                <ng-template [ngTemplateOutlet]="legend"></ng-template>
                <ng-template [ngTemplateOutlet]="data"></ng-template>
            </ng-container>

            <ng-container *ngIf="styleOptions?.verticalLayout">
                <ng-template [ngTemplateOutlet]="data"></ng-template>
                <ng-template [ngTemplateOutlet]="legend"></ng-template>
            </ng-container>
        </div>
    </ng-container>
</div>

<ng-template #legend>
    <div class="donut-chart-legend" [style.background]="styleOptions?.foregroundColor">
        <app-chart-legend [legendData]="chartLegend"></app-chart-legend>
    </div>
</ng-template>
<ng-template #data>
    <div class="donut-chart-data" [style.background]="styleOptions?.foregroundColor">
        <app-chart
            [chartOptions]="chartOptions"
            [chartData]="chartData"
            [selectionKey]="selectionKey"
        ></app-chart>
    </div>
</ng-template>