import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

import {
    Channel,
    ChannelAlerts,
    ChannelMessage,
    ChannelMessageQuery,
    ChannelReadResponse,
    ChannelTyper,
    ChannelTypers,
    ChannelTyperStatus,
    Communication,
    DirectMessage,
    MessageAttachment,
    MessageAttachmentType,
} from '../models/channels.model';
import { Person } from '../models/person.model';
import { Topic } from '@weavix/models/src/topic/topic';
import { RADIO_WINDOW_TARGET } from '../utils/utils';

import { AccountService } from './account.service';
import { AlertService } from './alert.service';
import { HttpService } from './http.service';
import { ProfileService } from './profile.service';
import { PubSubService } from './pub-sub.service';

@Injectable({
    providedIn: 'root',
})
export class ChannelService {
    typers: {[id: string]: ChannelTyper[]};
    handleChannel: Function;

    constructor(
        private httpService: HttpService,
        private accountService: AccountService,
        private profileService: ProfileService,
        private pubSubService: PubSubService,
        private alertsService: AlertService,
    ) {}

    channelIdNameMap: {[key: string]: string} = {};
    get visibility() { return this.isVisible; }
    set visibility(isVisible: boolean) { this.isVisible = isVisible; }

    private url: string = '/core/channels';
    private isVisible: boolean = false;

    static url = (id?: string) => id ? `/core/channels/${id}` : '/core/channels';

    private channelMessagesUrl = (channelId: string): string => `/core/channels/${channelId}/messages`;

    async buildChannelMap(component) {
        const lists = await this.getChannels(component);
        this.channelIdNameMap = {};
        lists.forEach(l => this.channelIdNameMap[l.id] = l.name);
    }

    handleChannelSelect(channelId: string) {
        if (this.handleChannel) {
            this.handleChannel(channelId);
            return true;
        }
        return false;
    }

    toggleVisibility(): void {
        this.visibility = !this.isVisible;
    }

    getChannels(component, facilityId?: string, tags?) {
        return this.httpService.get<Channel[]>(component, this.url, { facilityId, tags }, null, null);
    }

    getSubscribedChannels(component) {
        return this.httpService.get<Channel[]>(component, `${this.url}/subscribed`, undefined, null, null);
    }

    getSubscribedGroups(component) {
        return this.httpService.get<Channel[]>(component, `${this.url}/groups/subscribed`, undefined, null, null);
    }

    getSubscribedPeople(component, channelId: string) {
        return this.httpService.get<Person[]>(component, `${this.url}/${channelId}/subscribed`, undefined, null, (person: Person) => person.fullName);
    }

    getUnsubscribedChannels(component) {
        return this.httpService.get<Channel[]>(component, `${this.url}/unsubscribed`, undefined, null, null);
    }

    getUnsubscribedGroups(component) {
        return this.httpService.get<Channel[]>(component, `${this.url}/groups/unsubscribed`, undefined, null, null);
    }

    getChannel(component, id: string) {
        return this.httpService.get<Channel>(component, `${this.url}/${id}`, null);
    }

    addChannel(component, channel: Channel) {
        return this.httpService.post<Channel>(component, `${this.url}`, channel);
    }

    addGroup(component, channel: Channel) {
        return this.httpService.post<Channel>(component, `${this.url}/groups`, channel);
    }

    updateChannel(component, channel: Channel) {
        return this.httpService.put<Channel>(component, `${this.url}/${channel.id}`, channel);
    }

    updateChannelName(component, channelId: string, name: string) {
        return this.httpService.put<Channel>(component, `${this.url}/${channelId}`, { name: name || '' });
    }

    saveChannel(component, channel: Channel) {
        return channel.id ? this.updateChannel(component, channel) : this.addChannel(component, channel);
    }

    deleteChannel(component, id: string) {
        return this.httpService.delete<Channel>(component, `${this.url}/${id}`, null);
    }

    markChannelAsRead(component, channelId: string) {
        return this.httpService.put<ChannelReadResponse>(component, `${this.url}/${channelId}/preferences`, { read: true });
    }

    setTyping(component, channelId: string, status: ChannelTyperStatus) {
        return this.httpService.put<ChannelReadResponse>(component, `${this.url}/${channelId}/typing`, { status });
    }

    unsubscribe(component, channelId: string) {
        return this.httpService.put<Channel[]>(component, `${this.url}/${channelId}/preferences`, { subscribed: false });
    }

    subscribe(component, channelId: string) {
        return this.httpService.put(component, `${this.url}/${channelId}/preferences`, { subscribed: true });
    }

    updateNotificationType(component, channelId: string, notification: ChannelAlerts) {
        return this.httpService.put(component, `${this.url}/${channelId}/preferences`, { notification });
    }

    pageChannel(component, channelId: string) {
        return this.httpService.put(component, `${this.url}/${channelId}/page`, {});
    }

    async getChannelMessages(component, channelId: string, query?: ChannelMessageQuery) {
        return await this.httpService.get<ChannelMessage[]>(component, this.channelMessagesUrl(channelId), query, null, null);
    }

    addChannelMessage(component, channelMessage: DirectMessage, channelId: string) {
        return this.httpService.post<ChannelMessage>(component, `${this.url}/${channelId}/message`, channelMessage);
    }

    async getCommunications(component, facilityId: string, from: Date, to?: Date) {
        return this.httpService.get<Communication[]>(component, '/core/channels/communications', { facilityId, from, to });
    }

    async subscribeCommunications(component, facilityId: string) {
        const account = await this.accountService.getAccount();
        return this.pubSubService.subscribe<Communication>(component, Topic.AccountFacilityCommunication, [account.id, facilityId]);
    }

    async newAlertSubscription(component): Promise<Observable<ChannelMessage>> {
        const account = await this.accountService.getAccount();
        const userProfile = await this.profileService.getUserProfile(component);
        if (account && userProfile) {
            return (await this.pubSubService.subscribe<ChannelMessage>(component, Topic.AccountPersonChannelMessageCreated, [account.id, userProfile.personId]))
                    .pipe(map(x => x.payload));
        }
        return of(null);
    }

    async updateChannelSubscription(component): Promise<Observable<Channel>> {
        const account = await this.accountService.getAccount();
        const userProfile = await this.profileService.getUserProfile(component);
        if (account && userProfile) {
            return (await this.pubSubService.subscribe<Channel>(component, Topic.AccountPersonChannelUpdated, [account.id, userProfile.personId, '+']))
                .pipe(map(x => {
                    return { ...x.payload, id: x.topic[1] };
                }));
        }
        return of(null);
    }

    async subscribeTypers(component, channelId: string): Promise<Observable<ChannelTyper[]>> {
        const account = await this.accountService.getAccount();
        if (account) {
            return (await this.pubSubService.subscribe<ChannelTypers>(component, Topic.AccountChannelTypers, [account.id, channelId], true))
                .pipe(map(x => {
                    return x.payload.typers;
                }));
        }
        return of([]);
    }

    async uploadMessageAttachments(component, channelId: string, type: MessageAttachmentType, formData) {
        try {
            return await this.httpService.upload<MessageAttachment>(component, `${this.url}/${channelId}/message/attachment/${type}`, formData);
        } catch (e) {
            if (e.error?.details?.reason) {
                const reason: string = e.error?.details?.reason;
                switch (reason) {
                    case ('file_too_large') :
                        this.alertsService.sendError(e, 'ERRORS.FILE.TOO-LARGE');
                        break;
                    case ('invalid_attachment_type') :
                        this.alertsService.sendError(e, 'ERRORS.CHANNEL.INVALID_ATTACHMENT_TYPE');
                        break;
                    case ('invalid_file_type') :
                        this.alertsService.sendError(e, 'ERRORS.CHANNEL.INVALID_FILE_TYPE');
                        break;
                    default :
                        this.alertsService.sendError(e, 'ERRORS.UPLOAD');
                }
            }
            
            throw (e);
        }
    }

    async openTabToRadio(component?: any, ...peopleIds: string[]) {
        try {
            let url = `${environment.radioUrl}/radio`;
            if (peopleIds?.length > 0) {
                if (component == null) throw new Error('component is required');
                const channel = await this.addGroup(component, { people: peopleIds });
                url += `?id=${channel.id}`;
            }
            window.open(url, RADIO_WINDOW_TARGET);
        } catch (e) {
            this.alertsService.sendError(e, 'ERRORS.CHANNEL.OPEN_TAB');
        }
    }
}
