<div *ngIf="!readonly"
    appDragAndDrop
    class="upload upload-edit"
    [ngClass]="{'has-image': imageSrc}"
    (drop)="handleImageEdit($event)"
>
    <img *ngIf="imageSrc"
        class="preview"
        [src]="imageSrc"
        alt="preview"
    />

    <div class="remove-icon" (click)="removeImage($event)">
        <app-icon [faIcon]="'fas fa-trash-alt'"></app-icon>
    </div>

    <app-icon *ngIf="iconPlaceHolder && !imageSrc" [icon]="iconPlaceHolder"></app-icon>

    <i class="fas fa-upload upload-icon"></i>
    <input type="file" name="file" id="file" (change)="handleImageEdit($event?.target?.files)">
    <label class="upload-label" for="file"></label>
</div>

<div *ngIf="readonly"
    class="upload"
    [ngClass]="{'has-image': imageSrc}"
>
    <img *ngIf="imageSrc"
        class="preview"
        [src]="imageSrc"
        alt="preview"
    >

    <app-icon *ngIf="iconPlaceHolder && !imageSrc" [icon]="iconPlaceHolder"></app-icon>
</div>