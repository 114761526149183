import { Injectable } from '@angular/core';

import { SwPush } from '@angular/service-worker';
import { HttpService } from './http.service';

const VAPID_SUBSCRIPTION = 'VAPID_SUBSCRIPTION';

// Don't provide in root. Only want one instance
@Injectable()
export class PushService {
    enabled: boolean;
    accountId: string;

    constructor(private swPush: SwPush,
            private httpService: HttpService
    ) {
    }

    readonly VAPID_PUBLIC_KEY = 'BPzVzuyTaSw4UxPnHLgMpURMAboiJrO5vOzpgBXsO69Wvg5ulfiYkYVp-XglbsDDSa967P-mySRGezad6KlOcNA';

    async register(userId: string, accountId: string) {
        try {
            const data = await this.swPush.requestSubscription({ serverPublicKey: this.VAPID_PUBLIC_KEY });
            await this.httpService.put(null, `/core/users/${userId}/add-push`, { id: data, type: 'vapid' });
            localStorage.setItem(VAPID_SUBSCRIPTION, JSON.stringify(data));
            this.enabled = true;
            this.accountId = accountId;
        } catch (e) {
            const subId = localStorage.getItem(VAPID_SUBSCRIPTION);
            if (subId) {
                try {
                    await this.deregister(userId);
                } catch (e) {
                    console.error(e);
                }
            }
            if (e instanceof DOMException && e.name === 'NotAllowedError') {
                console.warn('Denied permission for web push notifications.', e);
            } else {
                console.error('Failed to register push notifications.', e);
            }
        }
    }

    async deregister(userId: string) {
        const vapid = localStorage.getItem(VAPID_SUBSCRIPTION);
        if (vapid) {
            localStorage.removeItem(VAPID_SUBSCRIPTION);

            const data = JSON.parse(vapid);
            try {
                await this.httpService.put(null, `/core/users/${userId}/remove-push`, { id: data, type: 'vapid' });
            } catch (e) {
                console.error('Failed to deregister push notifications.', e);
            }
        }
    }
}
