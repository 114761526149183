import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThemeService } from 'console/app/core/services/themeService';
import { Avatar } from 'console/app/shared/avatar/avatar.model';
import { Person } from 'weavix-shared/models/person.model';
import { UserProfile } from 'weavix-shared/models/user.model';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { css } from 'weavix-shared/utils/css';

@Component({
    selector: 'app-person-row-display',
    templateUrl: './person-row-display.component.html',
    styleUrls: ['./person-row-display.component.scss'],
})

export class PersonRowDisplayComponent implements OnInit {
    @Input() person: Person;
    @Input() size: 'small' | 'large' = 'small';

    @Output() clickEmit: EventEmitter<Person> = new EventEmitter();

    avatarInput: Avatar;
    userProfile: UserProfile;

    lightTheme: boolean;

    constructor(private profileService: ProfileService) {}

    async ngOnInit() {
        this.lightTheme = ThemeService.getLightTheme();
        this.userProfile = await this.profileService.getUserProfile(this);

        this.avatarInput = {
            height: this.size === 'small' ? 30 : 60,
            width: this.size === 'small' ? 30 : 60,
            img: this.person.avatarFile,
            circular: true,
            placeholder: {
                icon: 'fas fa-user',
                iconColor: css.colors.WHITE,
                backgroundColor: css.colors.GRAY,
            },
        };
    }

    handleChatPress(event: Event): void {
        event.stopPropagation();
    }

    click() {
        this.clickEmit.emit(this.person);
    }
}
