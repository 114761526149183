// NOTE: Abstract as it is provided by the apps
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

export enum Language {
    en = 'en',
    es = 'es',
    pt = 'pt',
    de = 'de',
    it = 'it',
    fr = 'fr',
    my = 'my',
    sw = 'sw',
    sq = 'sq',
    ko = 'ko',
    th = 'th',
    fil = 'fil',
}

export interface Currency {
    code: string;
    name: string;
    fractionSize: number;
    symbol: string;
}

@Injectable()
export abstract class TranslationService {
    abstract activeLanguage: Language;
    abstract language$: ReplaySubject<Language>;

    constructor() { }

    abstract setInitialLanguage(): void;

    abstract setLanguage(lang: string): void;

    abstract getLanguage(): string;

    abstract getLanguageString(): string;

    abstract getImmediate(key: string, params?, recursive?: boolean): string;

    abstract getTranslation(key: string, params?): Promise<string>;

    abstract getTranslations(keys: string[], params?): Observable<{ [key: string]: string }>;

    abstract getCurrencies(): Currency[];

    abstract getCurrency(code: string);

    abstract numberToCurrency(num: number, code: string): string;

    abstract getLocale();
}
