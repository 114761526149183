import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { DashboardSource, DashboardWidgetConfig } from 'weavix-shared/models/dashboard.model';
import { ColumnData } from 'weavix-shared/models/data-source.model';
import { DataSourceService } from 'weavix-shared/services/data-source.service';
import { AutoUnsubscribe, Utils } from 'weavix-shared/utils/utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-widget-container',
    templateUrl: './widget-container.component.html',
    styleUrls: ['./widget-container.component.scss'],
})
export class WidgetContainerComponent implements OnInit {
    @Input() widgetData: DashboardWidgetConfig;
    @Input() sources: DashboardSource[];
    @Input() editable: boolean;
    @Output() editOutput: EventEmitter<string> = new EventEmitter();

    public hasDataSource: boolean = false;
    private allRows: any[];
    private columns: ColumnData[];

    get dataSourceSetting() { const val = (this.sources || []).find(x => x.id === this.widgetData.source); return typeof val === 'string' ? val : null; }

    get dataSource() {
        const source = (this.sources || []).find(x => x.id === this.widgetData.source);
        this.hasDataSource = !!source;
        return source;
    }

    constructor(private dataSourceService: DataSourceService) { }

    async ngOnInit() {
        const ds = this.dataSource;
        const meta = await this.dataSourceService.validateQuery(this, { sql: ds.source.sql });
        this.columns = meta.columns;

        (await this.dataSourceService.subscribeData(this, ds.source, this.widgetData.variables))
            .subscribe(results => this.allRows = Object.values(results.allRows ?? {}));
    }

    async exportToCsv() {
        const values: string[][] = [this.columns.map(c => c.name)];
        this.allRows.forEach(r => { values.push(this.columns.map(c => r[c.name] == null ? '' : r[c.name])); });
        const csv = Utils.writeCsv(values);
        Utils.saveAsCsv(csv, _.get(this.widgetData, 'options.name'));
    }

    edit(event) {
        if (!this.editable) return;
        this.editOutput.emit(this.widgetData.id);
        event.stopPropagation();
    }
}
