import { Injectable } from '@angular/core';

import { AlertService } from '../services/alert.service';
import { HttpService } from '../services/http.service';

import { AnyBadgeEvent, BadgeUpdate, EventUpdateResponse, GeofenceEvent } from '../models/event.model';
import { Person } from '../models/person.model';

export interface RegistrationResponse {
    qr: string;
    token: string;
    pin: string;
}

@Injectable({
    providedIn: 'root'
})
export class BadgeService {
    constructor(
        private httpService: HttpService,
        private alertsService: AlertService
    ) { }

    static updatePersonBadgeDate<T extends Person>(person: T, badgeEvent: BadgeUpdate): void {
        person.badge = {
            ...person.badge,
            ...badgeEvent,
        };
    }

    async getPeopleEvents(component: any, facilityId: string, from?: Date, to?: Date) {
        return this.httpService.get<EventUpdateResponse[]>(component, '/track/events/people', { facilityId, from, to }, null, null);
    }

    async getAllItemEvents(component: any, facilityId: string, from?: Date, to?: Date) {
        return this.httpService.get<EventUpdateResponse[]>(component, '/track/events/items', { facilityId, from, to }, null, null);
    }

    async getItemEvents(component: any, itemId: string, facilityId: string, from?: Date, to?: Date) {
        return this.httpService.get<AnyBadgeEvent[]>(component, `/track/events/item/${itemId}`, { facilityId, from, to }, null, null);
    }

    async getEvents(component: any, personId: string, from?: Date, to?: Date): Promise<AnyBadgeEvent[]> {
        try {
            return await this.httpService.get(component, `/track/events/${personId}`, { from, to }, null, null);
        } catch (e) {
            this.alertsService.sendError(e, 'ERRORS.BADGE.GET-EVENTS');
        }
    }

    async getEventsByGeofence(component: any, geofenceID: string, from?: Date, to?: Date): Promise<GeofenceEvent[]> {
        try {
            return await this.httpService.get(component, `/track/events/geofence/${geofenceID}`, { from, to }, null, null);
        } catch (e) {
            this.alertsService.sendError(e, 'ERRORS.BADGE.GET-EVENTS');
        }
    }
}
