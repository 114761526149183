import { ACCOUNT_ONLY_PERMISSIONS, AppRoute, MULTI_SITE_PERMISSIONS, ROUTE_PERMISSIONS } from '../models/navigation.model';
import { consoleViewPermissions, PermissionAction } from '../permissions/permissions.model';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PowerBiReportConfig } from '@weavix/models/src/powerbi/report-config';
import { ReplaySubject, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { Dashboard } from '../models/dashboard.model';
import { FontelloIcon } from '../models/icon.model';
import { TreeItem } from '../models/tree-item.model';
import { Utils } from '../utils/utils';
import { AccountService } from './account.service';
import { FacilityService } from './facility.service';
import { ProfileService } from './profile.service';
import { TranslationService } from './translation.service';

export interface NavigationItem extends TreeItem {
    route?: AppRoute | string;
    activeRoutes?: (AppRoute | string)[];
    children?: NavigationItem[];
}

@Injectable({
    providedIn: 'root',
})
export class NavigationService {

    public navigationItems: NavigationItem[];
    public navigationItems$ = new ReplaySubject<NavigationItem[]>(1);
    public expanded: boolean;
    private currentFacility: string;
    public navigationExpanded: Subject<void> = new Subject();

    public localDashboards: Dashboard[];
    public powerBiReports: PowerBiReportConfig[];

    constructor(
        private router: Router,
        private profileService: ProfileService,
        private facilityService: FacilityService,
        private accountService: AccountService,
        private translationService: TranslationService,
    ) {
        this.expanded = localStorage.getItem('navigation-expanded') === 'false' ? false : true;
        this.facilityService.getCurrentFacilityId$().subscribe(id => {
            const fixChildren = item => {
                if (item?.url) item.url = item.url.replace(this.currentFacility, id);
                if (item?.children) item.children.forEach(fixChildren);
            };
            fixChildren({ children: this.navigationItems });
            this.currentFacility = id;
            this.setupNavigationItems();
        });
    }

    async setupNavigationItems() {
        this.currentFacility = localStorage.getItem('navigation-facility');
        const facilities = await this.facilityService.getMyFacilities(null);
        if (!facilities.some(x => x.id === this.currentFacility)) this.currentFacility = facilities[0]?.id;
        const siteVisionUrl: string = `f/${this.currentFacility}`;

        this.navigationItems = [
            {
                route: AppRoute.People,
                activeRoutes: [AppRoute.People, AppRoute.LiveLook, AppRoute.WorkerEfficiency, ...this.getDynamicRoutes(AppRoute.People)],
                hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.People]) || !facilities?.length,
                main: true,
                icon: { faIcon: 'fas fa-user-hard-hat' },
                titleKey: 'console-navigation.people',
                children: [
                    {
                        route: AppRoute.LiveLook,
                        activeRoutes: [AppRoute.LiveLook],
                        hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.LiveLook]),
                        titleKey: 'console-navigation.live-look',
                        url: `/${siteVisionUrl}/${AppRoute.People}/${AppRoute.LiveLook}`,
                    },
                    {
                        route: AppRoute.WorkerEfficiency,
                        activeRoutes: [AppRoute.WorkerEfficiency],
                        hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.WorkerEfficiency]),
                        titleKey: 'console-navigation.worker-efficiency',
                        url: `/${siteVisionUrl}/${AppRoute.People}/${AppRoute.WorkerEfficiency}`,
                    },
                    ...this.getDynamicRouteItems(AppRoute.People),
                ],

            },
            {
                route: AppRoute.Equipment,
                activeRoutes: [AppRoute.Equipment, ...this.getDynamicRoutes(AppRoute.Equipment)],
                hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.Equipment]) || !facilities?.length,
                main: true,
                icon: { faIcon: 'fas fa-forklift' },
                titleKey: 'console-navigation.equipment',
                children: [
                    {
                        route: AppRoute.Equipment,
                        activeRoutes: [AppRoute.Equipment],
                        titleKey: 'console-navigation.map',
                        url: `/${siteVisionUrl}/${AppRoute.Equipment}/${AppRoute.Map}`,
                    },
                    ...this.getDynamicRouteItems(AppRoute.Equipment),
                ],
            },
            {
                route: AppRoute.SmartRadio,
                activeRoutes: [AppRoute.SmartRadio, ...this.getDynamicRoutes(AppRoute.SmartRadio)],
                hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.SmartRadio]) || !facilities?.length || !this.getDynamicRoutes(AppRoute.SmartRadio).length,
                main: true,
                icon: { fntlIcon: FontelloIcon.Walt },
                titleKey: 'console-navigation.smart-radio',
                children: [
                    ...this.getDynamicRouteItems(AppRoute.SmartRadio),
                ],
            },
            {
                route: AppRoute.Safety,
                activeRoutes: [AppRoute.Alerts, AppRoute.HoleWatch, ...this.getDynamicRoutes(AppRoute.Safety)],
                hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.Safety]) || !facilities?.length,
                main: true,
                icon: { faIcon: 'fas fa-shield-alt' },
                titleKey: 'console-navigation.safety',
                children: [
                    {
                        route: AppRoute.Alerts,
                        activeRoutes: [AppRoute.Alerts],
                        hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.Alerts]),
                        titleKey: 'console-navigation.alerts',
                        url: `/${siteVisionUrl}/${AppRoute.Safety}/${AppRoute.Alerts}`,

                    },
                    {
                        route: AppRoute.HoleWatch,
                        activeRoutes: [AppRoute.HoleWatch],
                        hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.HoleWatch]),
                        titleKey: 'console-navigation.digital-confined-space-management',
                        url: `/${siteVisionUrl}/${AppRoute.Safety}/${AppRoute.HoleWatch}`,

                    },
                    ...this.getDynamicRouteItems(AppRoute.Safety),
                ],
            },
            {
                route: AppRoute.Configuration,
                activeRoutes: this.getConfigurationActiveRoutes(),
                hidden: !this.hasConfigAccess(),
                main: true,
                icon: { faIcon: 'fas fa-cog' },
                titleKey: 'shared.icons.configuration',
                children: [
                    {
                        route: AppRoute.AlertTypes,
                        hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.AlertTypes]),
                        activeRoutes: [AppRoute.AlertTypes],
                        titleKey: 'console-navigation.alert-types',
                        url: `/${AppRoute.Configuration}/${AppRoute.AlertTypes}`,
                    },
                    {
                        route: AppRoute.Configuration,
                        activeRoutes: [AppRoute.Configuration],
                        hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.General]),
                        titleKey: 'console-navigation.account',
                        url: `/${AppRoute.Configuration}`,
                    },
                    {
                        route: AppRoute.Channels,
                        hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.Channels]),
                        activeRoutes: [AppRoute.Channels],
                        titleKey: 'console-navigation.channels',
                        url: `/${AppRoute.Configuration}/${AppRoute.Channels}`,
                    },
                    {
                        route: AppRoute.DeviceManagement,
                        hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.DeviceManagement]),
                        activeRoutes: [AppRoute.DeviceManagement],
                        titleKey: 'console-navigation.device-management',
                        url: `/${AppRoute.Configuration}/${AppRoute.DeviceManagement}`,
                    },
                    {
                        route: AppRoute.ApiKeys,
                        hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.ApiKeys]),
                        activeRoutes: [AppRoute.ApiKeys],
                        titleKey: 'console-navigation.api-keys',
                        url: `/${AppRoute.Configuration}/${AppRoute.ApiKeys}`,
                    },
                    {
                        route: AppRoute.Compliance,
                        hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.Compliance]),
                        activeRoutes: [AppRoute.Compliance],
                        titleKey: 'console-navigation.compliance',
                        url: `/${AppRoute.Configuration}/${AppRoute.Compliance}`,
                    },
                    {
                        route: AppRoute.Crafts,
                        hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.Crafts]),
                        activeRoutes: [AppRoute.Crafts],
                        titleKey: 'console-navigation.crafts',
                        url: `/${AppRoute.Configuration}/${AppRoute.Crafts}`,
                    },
                    {
                        route: AppRoute.Dashboards,
                        hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.Dashboards]),
                        activeRoutes: [AppRoute.Dashboards],
                        titleKey: 'console-navigation.dashboards',
                        url: `/${AppRoute.Configuration}/${AppRoute.Dashboards}`,
                    },
                    {
                        route: AppRoute.Forms,
                        hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.Forms]),
                        activeRoutes: [AppRoute.Forms],
                        titleKey: 'console-navigation.forms',
                        url: `/${AppRoute.Configuration}/${AppRoute.Forms}`,
                    },
                    {
                        route: AppRoute.GeofenceTypes,
                        hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.GeofenceTypes]),
                        activeRoutes: [AppRoute.GeofenceTypes],
                        titleKey: 'console-navigation.geofence-types',
                        url: `/${AppRoute.Configuration}/${AppRoute.GeofenceTypes}`,
                    },
                    {
                        route: AppRoute.ItemTypes,
                        hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.ItemTypes]),
                        activeRoutes: [AppRoute.ItemTypes],
                        titleKey: 'console-navigation.item-types',
                        url: `/${AppRoute.Configuration}/${AppRoute.ItemTypes}`,
                    },
                    {
                        route: AppRoute.PermissionGroups,
                        hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.PermissionGroups]),
                        activeRoutes: [AppRoute.PermissionGroups],
                        titleKey: 'console-navigation.permission-groups',
                        url: `/${AppRoute.Configuration}/${AppRoute.PermissionGroups}`,
                    },
                    {
                        route: AppRoute.PowerBiConfig,
                        hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.PowerBiConfig]) || !environment.enableReports,
                        activeRoutes: [AppRoute.PowerBiConfig],
                        titleKey: 'generics.reports',
                        url: `/${AppRoute.Configuration}/${AppRoute.PowerBiConfig}`,
                    },
                    {
                        route: AppRoute.Sites,
                        hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.Sites]),
                        activeRoutes: [AppRoute.Sites],
                        titleKey: 'console-navigation.sites',
                        url: `/${AppRoute.Configuration}/${AppRoute.Sites}`,
                    },
                    {
                        route: AppRoute.Users,
                        hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.Users]),
                        activeRoutes: [AppRoute.Users],
                        titleKey: 'console-navigation.users',
                        url: `/${AppRoute.Configuration}/${AppRoute.Users}`,
                    },
                    {
                        route: AppRoute.WaltSettings,
                        hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.WaltSettings]),
                        activeRoutes: [AppRoute.WaltSettings],
                        titleKey: 'console-navigation.walt-settings',
                        url: `/${AppRoute.Configuration}/${AppRoute.WaltSettings}`,
                    },
                    {
                        route: AppRoute.Workflows,
                        hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.Workflows]),
                        activeRoutes: [AppRoute.Workflows],
                        titleKey: 'console-navigation.workflows',
                        url: `/${AppRoute.Configuration}/${AppRoute.Workflows}`,
                    },
                    ...this.getDynamicRouteItems(AppRoute.Configuration),
                ],
            },
        ];

        this.navigationItems.forEach(n => {
            Utils.sortAlphabetical(n.children, (c) => this.translationService.getImmediate(c.titleKey));
        });
        this.navigationItems$.next(this.navigationItems);
    }

    public setupLocalDashboards(dashboards: Dashboard[]) {
        this.localDashboards = dashboards.filter(d => d.enabled);
    }

    private getDynamicRoutes(route: AppRoute): string[] {
        return this.getDashboardRoutes(route)
            .concat(this.getPowerBiRoutes(route));
    }

    private getDashboardRoutes(route: AppRoute): string[] {
        return this.localDashboards?.filter(d => d.navParent === route).map(d => d.id) ?? [];
    }

    private getPowerBiRoutes(route: AppRoute): string[] {
        return (this.powerBiReports || [])
            .filter(report => report.navParent === route)
            .map(report => report.id);
    }

    private getDynamicRouteItems(route: AppRoute): NavigationItem[] {
        return this.getDashboardRouteItems(route)
            .concat(this.getPowerBiReportRouteItems(route));
    }

    private getDashboardRouteItems(route: AppRoute): NavigationItem[] {
        return this.localDashboards?.filter(d => d.navParent === route).map(d => {
            return {
                route: d.id,
                activeRoutes: [AppRoute.Dashboard, d.id],
                hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.Dashboards]),
                titleKey: d.name,
                url: `${this.getDynamicRouteUrlBase(route)}/${AppRoute.Dashboard}/${d.id}`,
            };
        }) ?? [];
    }

    private getPowerBiReportRouteItems(route: AppRoute): NavigationItem[] {
        return (this.powerBiReports ?? [])
            .filter(report => report.navParent === route)
            .map(report => ({
                route: report.id,
                activeRoutes: [AppRoute.PowerBiReport, report.id],
                hidden: this.hideRoute(ROUTE_PERMISSIONS[AppRoute.Dashboards]) || !environment.enableReports,
                titleKey: report.name,
                url: `${this.getDynamicRouteUrlBase(route)}/${AppRoute.PowerBiReport}/${report.id}`,
            }));
    }

    private getDynamicRouteUrlBase(route: AppRoute): string {
        if (route === AppRoute.Configuration) {
            return `/${route}`;
        } else {
          return `f/${this.currentFacility}/${route}`;
        }
    }

    private getConfigurationActiveRoutes() {
        return [
            AppRoute.Configuration, AppRoute.Channels, AppRoute.PermissionGroups, AppRoute.ApiKeys, AppRoute.Crafts, AppRoute.Dashboards, AppRoute.Forms,
            AppRoute.GeofenceTypes, AppRoute.ItemTypes, AppRoute.SelfRegistration, AppRoute.Sites, AppRoute.Users, AppRoute.Workflows, AppRoute.AlertTypes, AppRoute.WaltSettings,
            ...this.getDynamicRoutes(AppRoute.Configuration),
        ];
    }

    public toggleExpansion(): void {
        this.navigationItems.forEach(navItem => {
            navItem.dropdown = false;
        });
        this.expanded = !this.expanded;
        localStorage.setItem('navigation-expanded', `${this.expanded}`);
        this.navigationExpanded.next();
    }

    public toggleNavItem(item): void {
        item.closed = !item.closed;
    }

    public toggleNavItemDropdown(item: NavigationItem): void {
        this.navigationItems.forEach(navItem => {
            if (navItem.titleKey === item.titleKey) {
                item.dropdown = !item.dropdown;
            } else {
                navItem.dropdown = false;
            }
        });
    }

    public navigateAndToggleNavItemDropdown(item: NavigationItem, parent: NavigationItem): void {
        parent.dropdown = false;
        this.router.navigateByUrl(item.url);
    }

    public getFirstAvailableItemUrl(): string {
        const firstAvailable = this.getAllVisibleItems()[0];
        return `${this.accountService.getAccountRoute()}/${firstAvailable?.url}`;
    }

    public isUrlAvailable(url: string): boolean {
        return this.getAllVisibleItems()
            .some(itemOrChildItem => itemOrChildItem.url === url);
    }

    private getAllVisibleItems(): NavigationItem[] {
        return this.navigationItems
            .filter(item => !item.hidden)
            .flatMap(item => item.children ?? [item])
            .filter(itemOrChildItem => !itemOrChildItem.hidden);
    }

    private hasConfigAccess(): boolean {
        return consoleViewPermissions.some(action => this.profileService.hasAnyPermissionInAnyFacility(action));
    }

    public hasFacilityRoutePermissions(navItemPerms: PermissionAction[], anyRoute = false): boolean {
        return !navItemPerms || !navItemPerms.length ? true : navItemPerms?.some(p => {
            if (ACCOUNT_ONLY_PERMISSIONS.includes(p)) return this.profileService.hasAccountOrAnyFolderPermission(p);
            if (MULTI_SITE_PERMISSIONS.includes(p) || anyRoute) return this.profileService.hasAnyPermissionInAnyFacility(p);
            return this.profileService.hasAnyPermission(p);
        });
    }

    private hideRoute(perms: PermissionAction[]) {
        return !this.hasFacilityRoutePermissions(perms, true);
    }
}
