import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingWithDelayService {
    private timeoutRef: number = 0;
    private delay: number = 500;
    private isLoadingSub = new Subject<boolean>();

    public isLoading$ = this.isLoadingSub.asObservable();

    public set isLoading(value: boolean) {
        window.clearTimeout(this.timeoutRef);
        if (!value) {
            this.timeoutRef = window.setTimeout(() => { this.isLoadingSub.next(false); }, this.delay);
            return;
        }
        this.isLoadingSub.next(true);
    }
}
