import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PersonSelect } from '../../console/app/shared/person-select/person-select.model';
import { AlertType, FacilityAlertLevel, FacilityAlertLocation } from '../models/facility-alerts.model';
import { Person } from '../models/person.model';
import { RichMarker } from '../models/weavix-map.model';

export interface FacilityAlertItem {
    id: string;
    alertType: AlertType;
    alertTypeId: string;
    alertLevel: FacilityAlertLevel;
    alertLocation?: {
        location?: number[];
        geofenceId?: string;
    };
    name: string;
    message: string;
    lastUpdated: Date;
    icon: string;
    total: number;
    totalRead: number;
    totalRemoved: number;
    totalAcknowledged: number;
    progress: number;
    pinnedByUser?: boolean;
    location?: FacilityAlertLocation;
    marker?: RichMarker;
    sender?: Person;
    recipients?: PersonSelect[];
    acknowledged?: PersonSelect[];
    received?: PersonSelect[];
}

@Injectable({
    providedIn: 'root'
})
export class AlertMaydayService {
    private alerts$: BehaviorSubject<FacilityAlertItem[]>;

    constructor() {
        this.alerts$ = new BehaviorSubject([]);
    }

    public get alerts(): Observable<FacilityAlertItem[]> {
        return this.alerts$.asObservable();
    }

    public setAlerts(values: FacilityAlertItem[]) {
        this.alerts$.next(values);
    }

    public addTempAlert(alerts: FacilityAlertItem[], newAlert: FacilityAlertItem): void {
        const updatedAlerts: FacilityAlertItem[] = [...alerts, newAlert];
        this.setAlerts(updatedAlerts);
    }

    public removeTempAlert(): void {
        this.setAlerts(this.alerts$.value.filter(alert => alert.id !== 'temporary'));
    }
}
