
<div *ngIf="control"
    (clickOutside)="control.options && displayOptions ? closeOptions() : null"
    appClickOutside
    class="control-container"
>
    <app-callout *ngIf="control?.callout" [options]="control.callout"></app-callout>

    <button
        [id]="control.id"
        class="control gray-dk circle p0"
        [ngClass]="{'active-control': (control.isActive && control.isActive() && !control.color), 'inactive-control': !(control.isActive && control.isActive()) && !control.color}"
        [ngStyle]="{'background-color': (control.isActive && control.isActive() && control.color) ? colors.WHITE : control.color}"
        [matTooltip]="(control.tooltip | translate)"
        matTooltipPosition="before"
        (click)="control.options ? toggleOptions() : control.onClick()"
    >
        <app-icon [faIcon]="control.icon" [color]="control.isActive && control.isActive() ? colors.GRAY_DK : colors.WHITE"></app-icon>
    </button>

    <div *ngIf="displayOptions" class="control-options">
        <ng-container *ngFor="let option of control.options">
            <div 
                (click)="handleControlOptionClick(option)"
                [ngClass]="{
                    'active-control': (option.isActive && option.isActive() && !option.color),
                    'inactive-control': !(option.isActive && option.isActive()) && !option.color,
                    'hidden': option?.hide && option.hide()
                }"
                class="option"
            >
                <app-icon [faIcon]="option.icon" class="mR5"></app-icon>
                <p>{{option.tooltip | translate}}</p>
            </div>
        </ng-container>
    </div>
</div>