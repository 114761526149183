export const TIME_ZONE_KEY = 'timezone';
export const FACILITY_KEY = 'facility';

export interface ReportRequest {
    facilityId?: string;
    parameters: ReportDataSourceParameter[];
    reportTemplateId: string;
    isCustom?: boolean;
}

export interface ReportDataSourceParameter {
    key: string;
    value: any;
    display?: string;
    chipListMeta?: { display: string; value: string; }[];
}

export interface ReportDataSourceParameterQuery {
    key: string;
    value: string;
}

interface ReportBase {
    id?: string;
    name: string;
    isCustom?: boolean;
    reportTemplateId: string;
    parameters: ReportDataSourceParameter[];
    generatedById: string;
    status: ReportStatus;
    startTime?: Date;
    endTime?: Date;
    cancelledTime?: Date;
    error?: string;
    facilityId?: string;
    emailWhenDone: boolean;
}

export interface Report extends ReportBase {
    uri?: string;
}

export interface ReportResponse extends ReportBase {
    uri: string;
}

export enum ReportStatus {
    Running = 'running',
    Cancelled = 'cancelled',
    Error = 'error',
    RunSuccessful = 'run-successful'
}

export enum CannedReportIds {
    ConfinedSpace = '1',
    ContactSummary = 'contact-summary', // :)
    UserActivity = 'user-activity'
}

export const REPORT_IDS = {
    ...CannedReportIds,
    CustomReport: 'custom-report'
};

export enum ReportIds {
    ConfinedSpaceReport = '1',
    ContactSummaryReport = 'contact-summary', // :)
    UserActivityReport = 'user-activity'
}
