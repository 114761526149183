import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';
import { DateRange, TimeRange } from 'weavix-shared/models/dvr.model';
import { Facility } from 'weavix-shared/models/facility.model';
import { ItemBaseType } from 'weavix-shared/models/item.model';
import { AppRoute } from 'weavix-shared/models/navigation.model';
import { ReportDataSourceParameter, ReportIds, ReportRequest, ReportResponse, ReportStatus } from 'weavix-shared/models/report.model';
import { Topic } from '@weavix/models/src/topic/topic';
import { AccountService } from 'weavix-shared/services/account.service';
import { AlertService } from 'weavix-shared/services/alert.service';
import { FacilityService } from 'weavix-shared/services/facility.service';
import { ItemService } from 'weavix-shared/services/item.service';
import { LoadingWithDelayService } from 'weavix-shared/services/loading-with-delay.service';
import { PubSubService } from 'weavix-shared/services/pub-sub.service';
import { ReportService } from 'weavix-shared/services/report.service';
import { DateTimeUtil } from 'weavix-shared/utils/datetime';

import { AutoUnsubscribe, Utils } from 'weavix-shared/utils/utils';
import { DropdownItem } from '../dropdown/dropdown.model';
import { Modal } from '../modal/modal.model';

@AutoUnsubscribe()
@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
    isShown = false;

    reportForm: FormGroup = this.buildForm();

    facility: Facility;
    startTimeRange: TimeRange;
    tzDiff: number = 0;

    items: DropdownItem[] = [];

    modalInput: Modal = {
        isOpen: false,
        width: 600,
        overflow: 'visible',
        header: {
            textAlignment: 'left',
            showSeparator: true,
            textKey: 'generics.report',
        },
    };

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private loadingService: LoadingWithDelayService,
        private alertService: AlertService,
        private facilityService: FacilityService,
        private itemService: ItemService,
        private reportService: ReportService,
        private pubSubService: PubSubService,
        private accountService: AccountService,
    ) {
        const holeWatchUrl = [AppRoute.Safety, AppRoute.HoleWatch].join('/');
        this.isShown = this.router.url.includes(holeWatchUrl);
        this.router.events
            .pipe(filter(x => x instanceof NavigationEnd))
            .subscribe(async (x: NavigationEnd) => this.isShown = x.url.includes(holeWatchUrl));
    }

    get dateRange(): DateRange { return this.reportForm.get('dateRange')?.value; }
    get permitNumber(): string { return this.reportForm.get('permitNumber')?.value; }
    get itemId(): string { return this.reportForm.get('item')?.value; }

    async ngOnInit() {
        Utils.safeSubscribe(this, this.loadingService.isLoading$).subscribe(isLoading => this.alertService.setAppLoading(isLoading));
        Utils.safeSubscribe(this, this.facilityService.currentFacility$).subscribe(this.facilitySelected);
    }

    openReportForm(): void {
        this.modalInput.isOpen = true;
    }

    handleModalClose(): void {
        this.reportForm = this.buildForm();
        this.setInitialTimes();
        this.modalInput.isOpen = false;
    }

    private buildForm(): FormGroup {
        return this.fb.group({
            dateRange: this.fb.control(null, [Validators.required]),
            permitNumber: this.fb.control(null, [Validators.required]),
            item: this.fb.control(null, [Validators.required]),
        });
    }

    private facilitySelected = async (facility: Facility) => {
        if (facility?.id === this.facility?.id) return;
        this.facility = facility;
        try {
        this.tzDiff = DateTimeUtil.getTimeZoneDiff(this.facility.timezone);
        this.setInitialTimes();
        await this.loadItems();
        } catch (e) {
            console.error(e);
        }
    };

    private setInitialTimes(): void {
        const startOfDay = moment.tz(this.facility.timezone).startOf('day').valueOf() + this.tzDiff;
        const endOfDay = moment.tz(this.facility.timezone).endOf('day').valueOf() + this.tzDiff;

        this.reportForm.patchValue({ dateRange: { from: new Date(startOfDay), to: new Date(endOfDay) } as DateRange });
        this.startTimeRange = { from: startOfDay.valueOf(), to: endOfDay.valueOf() };
    }

    private async loadItems() {
        try {
            const csItems = await this.itemService.getItems(this, this.facility.id, undefined, ItemBaseType.ConfinedSpace);
            this.items = csItems.map(item => ({ key: item.id, label: item.name }));
        } catch (err) {
            console.error(err);
        }
    }

    private generateReportParameters(): ReportDataSourceParameter[] {
        const convertDateFromShifts = (dateRange: any) => {
            if (dateRange.shift) {
                const shiftFrom = new Date(dateRange.shift.from);
                const shiftTo = new Date(dateRange.shift.to);

                dateRange.from.setHours(shiftFrom.getHours());
                dateRange.from.setMinutes(shiftFrom.getMinutes());
                dateRange.from.setSeconds(shiftFrom.getSeconds());

                dateRange.to.setHours(shiftTo.getHours());
                dateRange.to.setMinutes(shiftTo.getMinutes());
                dateRange.to.setSeconds(shiftTo.getSeconds());
            }
            return { start: dateRange.from.valueOf() - this.tzDiff, end: dateRange.to.valueOf() - this.tzDiff };
        };

        return [
            { key: 'facility', value: this.facility.id },
            { key: 'timezone', value: this.facility.timezone },
            { key: 'itemId', value: this.itemId },
            { key: 'permitNumber', value: this.permitNumber },
            { key: 'dateRange', value: convertDateFromShifts(this.dateRange) },
        ] as ReportDataSourceParameter[];
    }

    private async subscribeToReportUpdates(repordId: string) {
        return this.pubSubService.subscribe<ReportResponse>(this, Topic.AccountReportUpdated, [this.accountService.getAccountId(), repordId]);
    }

    async submit() {
        this.alertService.setAppLoading(true);
        const reportRequest: ReportRequest = {
            facilityId: this.facility.id,
            reportTemplateId: ReportIds.ConfinedSpaceReport,
            parameters: this.generateReportParameters(),
        };
        const response = await this.reportService.createReport(this, reportRequest);
        if (response) {
            (await this.subscribeToReportUpdates(response.id))
            .subscribe(async x => {
                const reportUpdates = x.payload;
                switch (reportUpdates.status) {
                    case ReportStatus.RunSuccessful:
                        window.location.href = reportUpdates.uri;
                        this.handleModalClose();
                        break;
                    case ReportStatus.Error:
                        console.error(reportUpdates.error);
                        this.alertService.sendError(null, reportUpdates.error);
                        break;
                }
                this.alertService.setAppLoading(false);
            });
        } else {
            this.alertService.setAppLoading(false);
        }
    }

    cancel(): void {
        this.handleModalClose();
    }
}
