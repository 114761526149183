import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';

import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { AppComponent } from './app.component';
import { AppCoreModule } from './core/core.module';

import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';

export function errorHandlerFactory() {
    return new BugsnagErrorHandler();
}

export const matTooltipOptions: MatTooltipDefaultOptions = {
    showDelay: 0,
    hideDelay: 0,
    touchGestures: 'auto',
    position: 'below',
    touchendHideDelay: 0,
    disableTooltipInteractivity: true,
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppCoreModule,
        ServiceWorkerModule.register('custom-service-worker.js', { enabled: true }),
    ],
    providers: [
        { provide: ErrorHandler, useFactory: errorHandlerFactory },
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: matTooltipOptions },
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
