<div class="date-picker-input w100p">
    <div *ngIf="label" class="label-container">
        <span class="input-label" [class.required]="required">{{translate ? (label | translate) : label }}</span>
        <app-tooltip *ngIf="tooltip" [options]="tooltip" [translate]="translate"></app-tooltip>
    </div>
    <div class="input-container">
        <input matInput
            [required]="required"
            [readonly]="readonly"
            placeholder="mm/dd/yyyy"
            [ngClass]="[inputClass, readonly ? 'readonly' : '']"
            (keydown)="typing.emit()"
            (blur)="blur.emit()"
            [matDatepicker]="startPicker"
            [ngModel]="value"
            (ngModelChange)="setValue($event)"
            [max]="max"
            #input
        >
        
        <mat-datepicker-toggle *ngIf="!readonly" class="datepicker-toggle" [for]="startPicker"></mat-datepicker-toggle>
        <mat-datepicker #startPicker class="date-picker"></mat-datepicker>
    </div>
    
    <div *ngIf="showErrors">
        <mat-error *ngIf="translatedLabel && formControl?.hasError('required') && formControl?.touched">{{ 'formValidation.required' | translate:{ label: translatedLabel } }}</mat-error>
        <mat-error *ngIf="translatedLabel && formControl?.hasError('dateAfterOrEqualTo') && formControl?.touched">{{ 'formValidation.dateAfterOrEqualTo' | translate:{ label: translatedLabel, val: formControl.errors.dateAfterOrEqualTo.min } }}</mat-error>
    </div>

</div>
