/*
    The Microblink licenses below are somewhat secret, but secrets in the frontend cannot be kept secret.
    Someday we could move all Microblink logic to our backend which would allow us to hide these keys completely.

    Microblink's SDK does lock these down so that you can't use these unless they are used within our exact
    website (the prod key must be used on console.weavix.com, for example) so if someone scrapes these they
    can't use them anyway.
*/
export function getMicroblinkLicense(domain: string): string {
    // keys expire 02/20/2024
    if (domain === 'weavix.com') {
        return 'sRwAAAYMKi53ZWF2aXguY29tUk4053iNUWWl8uCQqNQN75OwjmY6A5RuC6bHj7yLqhTj73fhP1RAzKki5qVt3R4tNaGYuSvtutgmHEk4xfc5coya7KdnSDgdEAYgcfXc5xwGzQ0KOe7H9rKJDBCF39cFTHb6/yoq8IMOtjvaZYi3PJodKlsrdfs3EoXVIRAvWl8iPhVU30iEGegJBnKm6qbiZEN+1LRkG7WZH/dgErlsDwqof7wdB8U=';
    } else if (domain === 'weavixdemo.com') {
        return 'sRwAAAYQKi53ZWF2aXhkZW1vLmNvbbRpCaXcBrHwcnSpQiMYbvoVduYh++LBw5t+g7kpg6oBFNRmBNan5Krkt5fDPvSQiNHkee7Oadh88U0AtVEN6fYbhrCgH+dFIvYIErtUVrGLut7vaknkqk04GpHJMFD/77J4ifx5mQbzNVGCft/1xTux9YKU1nUFb6zMdSx/aVyDDBq7jp4hAt7deisO5iVUDg8IqHN8ilRhEjAISBs3KcX4sgDm9qJCd3vy';
    } else if (domain === 'weavixstage.com') {
        return 'sRwAAAYRKi53ZWF2aXhzdGFnZS5jb22ZvW3HOODcvvHjVyy6E3ifOoFoO9WDBWYLPW/XQmfAWDARQkITZpsNCna07U3p9vZPqggVJe+fz7CiSx+WPs7p13ZIIsJWLl24mKGJ5+N1ATUgJnvUDpbG0051m2PBc4YH/pEE5PP/z0sV0+UsbZiKqUZSYYg6fy+DZZAqROaPi2s+HuQBLsPWP++5w6sF4S8w+G5RK8Drboyc9ubSebGMhvlYoHhcA8I11g==';
    } else if (domain === 'weavixdev.com') {
        return 'sRwAAAYPKi53ZWF2aXhkZXYuY29tkZ6T/oNCd9iKNfaeIhp57QoEaXmMnE+xiZ7kn8jAG5EOxKk695DyKG+GD0Wb68aGUYcJrmmPDI2Mscd37gxFJ/9oimjE4ixI8JwoWaFYs7lna8iVvUjA3Iu0EbIdNtuMjXNtHPXpsybMDnQFAAbL5klSWP6M3czcXLdXDz1fAEu+LDzCJ6LONNf8iybdyeOq8BfsXgK6GxF3cBHZHJDX/hHkQfGdGFl7V30=';
    } else if (domain === 'localhost') {
        return 'sRwAAAYJbG9jYWxob3N0r/lOPgo/w35CpJmmK0UYwQTtayApm5tVKQYdinq10+yzrxeuwbaDzg9fs8ADFET033f927eF8KbAU1ywuaw46w1m+MQa8zi56K20AANTMjLhDbexEiW1b8+yqBi2kDMee02SpEdJJkiHLx7NHX5CSMnSdGysAP7eb1aHdgOPBgpgGFK529ek3cMrwGkPSBSE2yFAZ5310+cxZgQX4vC5b79QkRL2C3q9';
    } else {
        throw new Error(`Unknown domain: ${domain}`);
    }
}
