import { Component, Input, OnInit } from '@angular/core';
import { ThemeService } from 'console/app/core/services/themeService';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

export interface CalloutOptions {
    id: string;
    icon?: string;
    title?: string;
    text?: string;
    position?: 'top' | 'bottom';
    translate?: boolean;
    hasClose?: boolean;
    actions?: CalloutAction[];
}

export interface CalloutAction {
    label?: string;
    translate?: boolean;
    class?: string;
    action?: () => void;
}

@AutoUnsubscribe()
@Component({
    selector: 'app-callout',
    templateUrl: './callout.component.html',
    styleUrls: ['./callout.component.scss'],
})
export class CalloutComponent implements OnInit {
    @Input() options: CalloutOptions;

    lightTheme: boolean;
    show: boolean = true;

    constructor() {
        this.lightTheme = ThemeService.getLightTheme();
    }

    ngOnInit(): void {
        this.show = JSON.parse(localStorage.getItem(this.options.id) || 'true');
        this.listenToStorageChange();
    }

    private listenToStorageChange() {
        window.addEventListener('storage', (event: StorageEvent) => {
            if (event.key === this.options.id)
                this.show = JSON.parse(localStorage.getItem(this.options.id) || 'true');
        });
    }

    get hasCloseBtn(): boolean {
        return !this.options.hasClose || this.options.hasClose;
    }

    get calloutTranslate(): boolean {
        return !this.options.translate || this.options.translate;
    }

    actionTranslate = (action: CalloutAction): boolean => {
        return !action.translate || action.translate;
    };

    close(): void {
        localStorage.setItem(this.options.id, 'false');
        window.dispatchEvent(new StorageEvent('storage', { key: this.options.id }));
    }
}
