import { AppIcon } from 'weavix-shared/models/icon.model';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { DateTimeUtil } from 'weavix-shared/utils/datetime';

export interface NetworkDevice {
    id: string;
    type: NetworkDeviceType;
    dbm: number;
    rfBand: string;
    serviceType: string;
    carrier: string;
    ssid: string;
}

export interface NetworkGps {
    location: number[];
    time: string;
}

export enum NetworkDeviceType {
    Cellular = 'cellular',
    Ethernet = 'ethernet',
    Wifi = 'wifi',
    Unknown = 'unknown'
}

enum NetworkStrength {
    None = 'none',
    VeryLow = 'very-low',
    Low = 'low',
    Medium = 'medium',
    High = 'high',
}

const networkIconMap = {
    [NetworkDeviceType.Cellular]: {
        [NetworkStrength.None]: 'signal-alt-slash',
        [NetworkStrength.VeryLow]: 'signal-alt-1',
        [NetworkStrength.Low]: 'signal-alt-2',
        [NetworkStrength.Medium]: 'signal-alt-3',
        [NetworkStrength.High]: 'signal-alt',
    },
    [NetworkDeviceType.Wifi]: {
        [NetworkStrength.None]: 'wifi-slash',
        [NetworkStrength.VeryLow]: 'wifi-1',
        [NetworkStrength.Low]: 'wifi-1',
        [NetworkStrength.Medium]: 'wifi-2',
        [NetworkStrength.High]: 'wifi',
    },
};

export class NetworkUtility {
    static getNetworkIcon(type: NetworkDeviceType, dbm?: number): AppIcon {
        if (type == null || type === NetworkDeviceType.Unknown) return { faIcon: 'fas fa-question', fontSize: '18px' };
        if (type === NetworkDeviceType.Ethernet) return { faIcon: 'fas fa-ethernet', fontSize: '20px' };

        const strength = NetworkUtility.getNetworkStrength(dbm);
        const icon = networkIconMap[type][strength];
        return { faIcon: `fad fa-${icon}`, fontSize: '14px' };
    }

    static getNetworkName(type: NetworkDeviceType, carrier?: string, rfBand?: string) {
        if (type == null || type === NetworkDeviceType.Unknown) return 'network-device-type.unknown';
        if (type === NetworkDeviceType.Ethernet) return 'network-device-type.ethernet';
        if (type === NetworkDeviceType.Wifi) return 'network-device-type.wifi';
        if (type === NetworkDeviceType.Cellular && rfBand?.includes('48')) return 'network-indicator.private-cellular';
        return carrier ?? 'network-device-type.cellular';
    }

    static getNetworkStrength(dbm: number) {
        if (dbm >= -50) return NetworkStrength.High;
        if (dbm >= -70) return NetworkStrength.Medium;
        if (dbm >= -80) return NetworkStrength.Low;
        if (dbm >= -100) return NetworkStrength.VeryLow;
        return NetworkStrength.None;
    }

    static getDetailsText(translationService: TranslationService, device: NetworkDevice, gps?: NetworkGps) {
        const t = translationService;
        const networkName = NetworkUtility.getNetworkName(device?.type, device?.carrier, device?.rfBand);
        const networkType = device?.type ?? NetworkDeviceType.Unknown;
        const text =
`
${t.getImmediate(networkName)}

${t.getImmediate('network-indicator.network-type')}: ${t.getImmediate(`network-device-type.${networkType}`)}
${t.getImmediate('network-indicator.dbm')}: ${device?.dbm || '-'}
${t.getImmediate('network-indicator.rf-band')}: ${device?.rfBand || '-'}
${t.getImmediate('network-indicator.service-type')}: ${device?.serviceType ?? '-'}
${t.getImmediate('network-indicator.carrier')}: ${device?.carrier || '-'}
${t.getImmediate('network-indicator.wan-ssid')}: ${device?.ssid || '-'}

${t.getImmediate('network-indicator.gps')}: ${gps?.location != null ? `${gps.location?.[1]?.toFixed(8)}, ${gps.location?.[0]?.toFixed(8)}` : '-'}
${t.getImmediate('network-indicator.gps-time')}: ${gps?.time != null ? `${DateTimeUtil.dateTimeToLocaleString(gps.time, t.getLocale(), true)}` : '-'}
`;
        return text;
    }
}
