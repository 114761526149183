<div class="main" [class.mouseover]="!options?.showOnClick" (click)="options?.showOnClick ? show=!show : ''">
    <app-icon
        [faIcon]="options.icon ? options.icon : 'fas fa-info'"
        class="icon tooltip-icon">
    </app-icon>

    <div class="wrapper right" [ngClass]="[ options?.vPosition ?? 'bottom' ]" [class.show]="show" >
        <div class="content" [style.width.px]="options.width ? options.width : 220">
            <div class="title"><span>{{ translate ? (options.title | translate) : options.title }}</span></div>
            <div class="separator"></div>
            <div class="body">{{ translate ? (options.body | translate) : options.body }}</div>
        </div>
    </div>

</div>
