<div *ngIf="isShown">
    <div class="icon" (click)="openReportForm()" (auxclick)="openReportForm()">
        <app-icon [faIcon]="'fas fa-file-contract'" ></app-icon>
    </div>
</div>

<app-modal *ngIf="modalInput.isOpen" [modalInput]="modalInput" (modalCloseOutput)="handleModalClose()">
    <form [formGroup]="reportForm" id="report-modal" class="flexColumn overHidden">
        <div class="form-row gap10p">
            <div class="date-range-input flexGrow1">
                <div class="label-container">
                    <span class="input-label required">{{ 'shared.filters.date-range' | translate }}</span>
                </div>

                <app-date-range-picker
                    inputId="dateRange"
                    formControlName="dateRange"
                    [timeRange]="startTimeRange"
                ></app-date-range-picker>
            </div>
        </div>

        <div class="form-row gap10p">
            <app-input
                class="flexGrow1"
                label="hole-watch.permit-number"
                inputId="permitNumber"
                formControlName="permitNumber"
                [required]="true"
                errorClass="block"
            ></app-input>
        </div>

        <div class="form-row gap10p">
            <app-dropdown
                class="flexGrow1"
                errorClass="block"
                formControlName="item"
                [dropdownInput]="items"
                [floatedLabel]="'generics.item'"
                [setDefault]="false"
                [required]="true"
                errorClass="block"
            ></app-dropdown>
        </div>

        <div class="form-buttons">
            <button
                id="cancel-button"
                type="button" 
                class="btn standard-width gray-lt config-form-button spaced-right"
                (click)="cancel()">
                {{ 'generics.cancel' | translate }}
            </button>

            <button
                id="submit-button"
                type="submit"
                class="btn standard-width blue-lt config-form-button spaced-right"
                [disabled]="reportForm.invalid || reportForm.pristine"
                (click)="submit()">
                {{ 'generics.generate' | translate }}
            </button>
        </div>
        
    </form>
</app-modal>