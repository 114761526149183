import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RowEdit, RowEditAction, RowItem, RowItemType, TableColumn, TableRow } from 'weavix-shared/models/table.model';

import { ThemeService } from 'console/app/core/services/themeService';
import { Icon } from 'weavix-shared/models/icon.model';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-table-row',
    templateUrl: './table-row.component.html',
    styleUrls: ['./table-row.component.scss'],
})
export class TableRowComponent implements OnInit {
    @Input() row: TableRow;
    @Input() colKey: {[key: string]: TableColumn};
    @Input() inTableThatIsMoveable: boolean;
    @Input() canMove: boolean;
    @Input() moveTooltip: string;
    @Input() dummyEdits = [];
    @Input() icon: Icon;
    @Input() iconFixedWidth: boolean;
    @Input() backgroundColor: string;
    @Input() totalRowConfig: { show: boolean; count: number };

    @Output() checkAction: EventEmitter<void> = new EventEmitter();
    @Output() columnClick: EventEmitter<RowItem> = new EventEmitter();
    @Output() colPrefixClick: EventEmitter<RowItem> = new EventEmitter();
    @Output() colPostfixClick: EventEmitter<RowItem> = new EventEmitter();
    @Output() editClick: EventEmitter<RowEdit> = new EventEmitter();

    rowItemType = RowItemType;
    subTableLeftOffset: string;
    lightTheme: boolean;

    rowItemExpanded: { [colKey: string]: boolean } = {};
    rowItemExpanding: { [colKey: string]: boolean } = {};

    get canExpand(): boolean { return this.row?.items?.some(item => !!item.maxLines); }
    get hasExpanded(): boolean { return Object.values(this.rowItemExpanded)?.some(v => v); }

    constructor() { }

    ngOnInit() {
        this.lightTheme = ThemeService.getLightTheme();
        if (this.row.subTable) this.setSubTableRightAlignOffset();
    }

    // offset to the right by the difference in columns. Currently assuming columns are equal widths
    setSubTableRightAlignOffset(): void {
        const parentColCount: number = this.row.items.length;
        const subColCount: number = this.row.subTable?.options?.columns?.length;
        const colDiff: number = parentColCount - subColCount;
        if (colDiff > 1) this.subTableLeftOffset = `${(colDiff / parentColCount) * 100}%`;
    }

    select(event: Event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.checkAction.emit();
    }

    column(event: Event, item: RowItem) {
        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
        this.columnClick.emit(item);
    }

    prefix(event: Event, item: RowItem) {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.colPrefixClick.emit(item);
    }

    postfix(event: Event, item: RowItem) {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.colPostfixClick.emit(item);
    }

    edit(event: Event, edit: RowEdit) {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.editClick.emit(edit);
    }

    chooseFolder(event: Event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.editClick.emit({ title: null, icon: null, action: RowEditAction.move });
    }

    handleShowSubRowItems(event: Event): void {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.row.subTable.show = !this.row.subTable.show;
    }

    async handleExpandRowItem(item: RowItem, expanded: boolean) {
        this.rowItemExpanding[item.colKey] = true;
        if (expanded && item.lineExpand) await item.lineExpand(this.row.original);
        this.rowItemExpanded[item.colKey] = expanded;
        this.rowItemExpanding[item.colKey] = false;
    }
}
