
import { Subject, Subscription } from 'rxjs';

export enum StateUpdateType {
    Add = 'add',
    Update = 'update',
    Delete = 'delete'
}

export interface StateUpdate<T> {
    data: T;
    type: StateUpdateType;
}

export abstract class StateServiceBase {
    private subscriptions: Subscription[] = [];

    abstract loadData(component: any, accountId: string, facilityId?: string, tags?: string[]): Promise<void>;
    protected abstract setupSubscriptions(component: any, accountId: string, facilityId?: string, tags?: string[]): Promise<Subscription | Subscription[]>;

    async start(component: any, accountId: string, facilityId?: string, tags?: string[]): Promise<void> {
        await this.startSubscriptions(component, accountId, facilityId, tags);
        await this.loadData(component, accountId, facilityId, tags);
    }

    async stop(): Promise<void> {
        await this.stopSubscriptions();
    }

    async startSubscriptions(component: any, accountId: string, facilityId?: string, tags?: string[]): Promise<void> {
        await this.stopSubscriptions();
        this.subscriptions = [].concat(await this.setupSubscriptions(component, accountId, facilityId, tags)).filter(s => s);
    }

    async stopSubscriptions(): Promise<void> {
        this.subscriptions?.forEach(s => s.unsubscribe());
        this.subscriptions = [];
    }

    protected sendUpdateEvent<T>(subject: Subject<StateUpdate<T>>, data: T, type: StateUpdateType) {
        subject.next({ data, type });
    }

    protected updateFromMap<T extends U, U>(existingMap: Map<string, T>, updateMap: { [key: string]: U }, updateSub$: Subject<StateUpdate<T>>) {
        const updateKeys = Object.keys(updateMap);
        updateKeys.forEach(k => {
            if (updateMap[k]) {
                const type = existingMap.has(k) ? StateUpdateType.Update : StateUpdateType.Add;
                existingMap.set(k, { ...existingMap.get(k), ...updateMap[k] });
                this.sendUpdateEvent(updateSub$, existingMap.get(k), type);
            } else {
                const item = existingMap.get(k);
                existingMap.delete(k);
                this.sendUpdateEvent(updateSub$, item, StateUpdateType.Delete);
            }
        });
    }
}
