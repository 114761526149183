import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppRoute } from 'weavix-shared/models/navigation.model';
import { AccountService } from 'weavix-shared/services/account.service';
import { FacilityService } from 'weavix-shared/services/facility.service';
import { NavigationService } from 'weavix-shared/services/navigation.service';

@Injectable({ providedIn: 'root' })
export class PermissionTargetActionGuard implements CanActivate {
    constructor(
        private accountService: AccountService,
        private facilityService: FacilityService,
        private navigationService: NavigationService,
        private router: Router,
    ) {}


    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const noAccessRoute: string = `${this.accountService.getAccountRoute()}/f/${this.facilityService.getCurrentFacilityId()}/${AppRoute.NoAccess}`;
        const hasPermission = this.navigationService.hasFacilityRoutePermissions(route?.data?.permissions);

        if (hasPermission) {
            return true;
        } else {
            this.router.navigateByUrl(noAccessRoute);
            return false;
        }
    }
}
