<form *ngIf="form"
    [formGroup]="form"
    class="time-form"
>
    <app-dropdown
        formControlName="time"
        [customWidth]="100"
        [dropdownLabel]="label"
        [dropdownInput]="dropdownItems"
        [skipSort]="true"
    ></app-dropdown>
</form>
