import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DashboardSource, DashboardWidgetConfig, DashboardWidgetType } from 'weavix-shared/models/dashboard.model';
import { ChartLegendItem } from '../../chart-legend/chart-legend.component';

@Component({
    selector: 'app-chart-widget',
    templateUrl: './chart-widget.component.html',
    styleUrls: ['./chart-widget.component.scss'],
})
export class ChartWidgetComponent implements OnInit {

    @Input() widget: DashboardWidgetConfig;
    @Input() sources: DashboardSource;
    @Input() editable: boolean;
    @Output() tableView: EventEmitter<any> = new EventEmitter();
    @Output() dashboardView: EventEmitter<any> = new EventEmitter();

    chartLegend: ChartLegendItem[] = [];
    widgetType = DashboardWidgetType;

    constructor() { }

    ngOnInit(): void {
    }

    setChartLegend(chartData: any[]) {
        this.chartLegend = chartData.map(c => {
            return {
                name: c.name as string,
                value: {
                    actual: Number(c.y),
                    display: c.x,
                },
                color: c.color as string,
                seriesDataKey: c.key,
            };
        });
    }
}
