<div class="parentContainer">
    <div *ngIf="state === 'loading'" class="centeredContainer">
        <app-logo-spinner [size]="100"></app-logo-spinner>
    </div>

    <div *ngIf="state === 'error'" class="centeredContainer">
        {{ 'ERRORS.GENERIC.SOMETHING' | translate }}
    </div>

    <div *ngIf="state !== 'error'" #reportContainer class="reportContainer"></div>
</div>
