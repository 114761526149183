import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpService } from 'weavix-shared/services/http.service';
import { Utils } from 'weavix-shared/utils/utils';

export enum SubAppName {
    Console = 'console'
}

@Injectable({ providedIn: 'root' })
export class AppService {
    constructor(
        @Inject(DOCUMENT) private document: HTMLDocument,
        private title: Title,
        private rendererFactory: RendererFactory2,
        private router: Router,
        private deviceService: DeviceDetectorService,
    ) {
        this.renderer = this.rendererFactory.createRenderer(null, null);
    }
    static appName = SubAppName.Console;

    private renderer: Renderer2;
    currentRouteComponent: any;

    setAppDetails(unauthenticated: Function) {
        const host = window.location.hostname;
        const port = window.location.port;
        let app = 'weavix';
        if (host.includes(SubAppName.Console) || port === '4202') {
            app = SubAppName.Console;
        }

        const link = this.getFavicon() ?? this.renderer.createElement('link');
        this.appendLinkTag(link, `/assets/favicons/${app}-favicon.ico`);
        this.title.setTitle(`weavix™ ${Utils.toProperCase(app)}`);
        AppService.appName = SubAppName.Console;
        HttpService.unauthenticated = () => unauthenticated(async () => {
            await this.router.navigateByUrl('/login/authorize');
        });
    }

    getFavicon() {
        return this.document.querySelector(`link[rel*='icon']`);
    }

    private appendLinkTag(link: any, iconURL: string) {
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = iconURL;
        const head = this.document.getElementsByTagName('head')[0];
        this.renderer.appendChild(head, link);
    }

    isMobile() {
        const data = this.deviceService.getDeviceInfo().os;
        switch (data.toLowerCase()) {
            case 'mac':
                return this.deviceService.isTablet();
            case 'ios':
            case 'android':
                return true;
        }
        return false;
    }
}
