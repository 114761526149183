
<div class="container"> 

    <div class="widget-grid-container">
        <app-widget-grid *ngIf="dashboard"
            [dashboard]="dashboard"
            [editable]="false"
        ></app-widget-grid>
    </div>

    <div class="filters-container">
        <div class="filter-container">
            <app-dashboard-filter *ngIf="dashboard"
                [dashboard]="dashboard"
            ></app-dashboard-filter>
        </div>
    </div>
</div>