<div *ngIf="avatarProperties"
    class="avatar-container"
>
    <div class="avatar" [ngStyle]="avatarProperties.outlineColor ? {
        'height.px': avatarProperties.height + borderWidth * 2,
        'width.px': (avatarProperties.width || avatarProperties.height) + borderWidth * 2,
        'border': 'solid ' + borderWidth + 'px',
        'border-color': getOutlineColor(),
        'border-radius': getBorderRadius(),
        'background-color': backgroundColor ? backgroundColor : 'transparent',
        'box-shadow': backgroundColor ? backgroundColor + ' 0 0 0 ' + borderWidth * 1.5 + 'px' : 'none'
    } : {}">

        <ng-content></ng-content>

        <img
        *ngIf="avatarProperties.img && !imageSrcError"
        class="avatar-img"
        [ngStyle]="{
            'height.px': avatarProperties.height,
            'width.px': avatarProperties.width || avatarProperties.height,
            'border-radius': getBorderRadius()
        }"
        [src]="avatarProperties.img"
        [alt]="avatarProperties.altText || ('userImage' | translate)"
        (error)="imageSrcError = true"
        >

        <div
            *ngIf="avatarProperties.placeholder && (!avatarProperties.img || imageSrcError)"
            [ngStyle]="{
                'height.px': avatarProperties.height,
                'width.px': avatarProperties.width || avatarProperties.height,
                'backgroundColor': getBackgroundColor(),
                'text-align': 'center',
                'display': 'flex',
                'border-radius': getBorderRadius()
            }"
        >
            <i
                [ngClass]="avatarProperties.placeholder.icon"
                [ngStyle]="{
                    'fontSize.px': getFontSize(),
                    'color': getIconColor(),
                    'margin': 'auto'
                }"
            ></i>
        </div>

        <i
            class="fas fa-user-circle avatar-icon"
            *ngIf="!avatarProperties.placeholder && (!avatarProperties.img || imageSrcError)"
            [ngStyle]="{
                'fontSize.px': avatarProperties.width,
                'height.px': avatarProperties.height,
                'width.px': avatarProperties.width || avatarProperties.height
            }"
        ></i>

        <button class="primary circle-icon"
            [ngClass]="getCircular() ? 'circular-button-icon' : 'square-button-icon'"
            *ngIf="avatarProperties.editable && !readonly"
            type="button"
            (click)="showEditModal()"
        >
            <i class="fas fa-pen"></i>
        </button>
    </div>
</div>


<app-modal *ngIf="editModal.isOpen" [modalInput]="editModal" (modalCloseOutput)="handleEditModalClose($event)">
    <app-avatar-editor (editOutput)="handleAvatarEditOutput($event)"></app-avatar-editor> 
</app-modal>