import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { aggregate, ColumnDefinition, DashboardSource, DashboardWidgetConfig, evaluate, formatWidget, getRow, groupValue, makeColumnDefinitionMapper, TableWidgetColumn, TableWidgetSettings } from 'weavix-shared/models/dashboard.model';
import { AggregateType, ColumnData, ColumnType, GroupType } from 'weavix-shared/models/data-source.model';
import { RowItemType, TableColumn, TableOptions, TableRow } from 'weavix-shared/models/table.model';
import { DashboardService } from 'weavix-shared/services/dashboard.service';
import { DataSourceService } from 'weavix-shared/services/data-source.service';
import { TranslationService } from 'weavix-shared/services/translation.service';
import { AutoUnsubscribe, Utils } from 'weavix-shared/utils/utils';
import { TableService } from '../../table/table.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-table-widget',
  templateUrl: './table-widget.component.html',
  styleUrls: ['./table-widget.component.scss'],
})
export class TableWidgetComponent implements OnInit, OnDestroy {
    @Input() widgetData: DashboardWidgetConfig;
    @Input() sources: DashboardSource[];
    @Input() editable: boolean;

    @Output() dashboardView: EventEmitter<any> = new EventEmitter<any>();

    isLoading: boolean = true;
    tableOptions: TableOptions;
    rows: {[key: string]: any}[];
    filteredRows: {[key: string]: any}[];

    get settings() { return _.get(this.widgetData, 'options.settings') as TableWidgetSettings; }
    get source() { return (this.sources || []).find(x => x.id === this.widgetData?.source)?.source; }

    constructor(
        private dataSourceService: DataSourceService,
        private tableService: TableService,
        private translationService: TranslationService,
        private dashboardService: DashboardService,
    ) { }

    async ngOnInit() {
        if (this.source) {
            this.isLoading = true;
            this.dashboardService.setWidgetLoading(this, this.isLoading);
            (await this.dataSourceService.subscribeData(this, this.source, this.widgetData.variables)).subscribe(results => {
                if (results.isLoading) {
                    this.isLoading = true;
                    this.dashboardService.setWidgetLoading(this, this.isLoading);
                    return;
                }
                this.isLoading = false;
                this.dashboardService.setWidgetLoading(this, this.isLoading);

                const sourceCols: ColumnData[] = _.get(results, 'meta.columns', []);
                const typeMap = sourceCols.reduce((acc, c) => (acc[c.name] = c.type, acc), {});
                const widgetColumns: ColumnDefinition[] = (_.get(this.settings, 'columns', []) as TableWidgetColumn[])
                    .map(c => (typeof c === 'string' ? { name: c, type: typeMap[c] } : c));
                const fnMap = widgetColumns.reduce((acc, c) => (acc[c.name] = c.group === GroupType.Function ? evaluate(c.groupFn) : null, acc), {});
                const groupColumns = widgetColumns.filter(c => !Object.values(AggregateType).includes(c.group as any) || c.group === AggregateType.Value);
                const aggregateColumns = widgetColumns.filter(c => Object.values(AggregateType).includes(c.group as any) && c.group !== AggregateType.Value);
                const groups = {};
                if (results?.rows)
                    Object.values(results.rows).forEach(r => {
                        const testRow = getRow(r, typeMap);
                        const grouped = { allRows: [] };
                        const grouping = JSON.stringify(groupColumns.map(c => {
                            const val = groupValue(testRow[c.name], c.group, testRow, fnMap[c.name]);
                            grouped[c.name] = val.x instanceof Date ? val.x : val.label;
                            return val.x;
                        }));
                        const groupRow = groups[grouping] || grouped;
                        groups[grouping] = groupRow;
                        aggregateColumns.forEach(c => {
                            groupRow[c.name] ? groupRow[c.name].push(testRow[c.name]) : groupRow[c.name] = [testRow[c.name]];
                        });
                        groupRow.allRows.push(testRow);
                    });
                this.rows = Object.values(groups);
                this.rows.forEach(r => {
                    aggregateColumns.forEach(c => {
                        r[c.name] = aggregate(r[c.name], c.group as AggregateType);
                    });
                });
                this.filteredRows = this.rows;

                const tableColumns: TableColumn[] = this.getTableColumns(sourceCols);

                if (tableColumns && this.rows) {
                    this.tableOptions = this.getTableOptions(tableColumns);
                }
            });
            Utils.safeSubscribe(this, this.tableService.filterUpdate$).subscribe(({ resetFilters }) => {
                if (resetFilters) this.tableService.clearFilters();
                this.filteredRows = this.rows.filter(f =>
                    this.tableService.checkAndApplyFiltersToRow(f),
                );
            });
        }
    }

    ngOnDestroy() {}

    async handleRowClick(row: TableRow) {
        if (this.editable || !this.settings.drillDown) return;
        this.dashboardView.emit({ row: row.original.allRows[0], drillDown: this.settings.drillDown });
    }

    private getTableColumns(sourceColumns: ColumnData[]): TableColumn[] {
        const widgetColumns: TableWidgetColumn[] = _.get(this.settings, 'columns', []);
        if (sourceColumns.length < 1 || widgetColumns.length < 1) return [];

        const sourceColumnsMap = sourceColumns.reduce((acc, sourceCol) => (acc[sourceCol.name] = sourceCol, acc), {});

        const isNumber = (group) => [AggregateType.Avg, AggregateType.Count, AggregateType.Sum].includes(group);
        const isSame = (group) => !group || [AggregateType.Value, AggregateType.Max, AggregateType.Min].includes(group);
        return _.chain(widgetColumns)
            .map(makeColumnDefinitionMapper(sourceColumns))
            .filter(widgetCol => sourceColumnsMap[widgetCol.name])
            .map(col => ({ title: col.name, colKey: col.name, type:
                sourceColumnsMap[col.name]?.type === ColumnType.Number && isSame(col.group) || isNumber(col.group) ? RowItemType.number :
                sourceColumnsMap[col.name]?.type === ColumnType.Datetime && isSame(col.group) ? col.timeAgo ? RowItemType.timeAgo : RowItemType.date :
                RowItemType.text,
                           sort: this.getSort(col),
            })).value();
    }

    private getSort(col) {
        return {
            selected: ['asc', 'desc'].includes(col.sort),
            sortable: true,
            sortAsc: col.sort === 'asc',
        };
    }

    private getTableOptions(cols: TableColumn[]): TableOptions {
        const settings = _.get(this.widgetData, 'options.settings');
        return {
            title: null,
            keyCol: 'id',
            rowEdits: [],
            columns: cols,
            canAdd: false,
            select: {
                selectable: false,
            },
            settingsKey: this.widgetData.id,
            headerOptions: {
                hide: false,
                isPaginationInfoVisible: true,
                search: {
                    show: this.settings?.showSearch,
                    icon: 'fas fa-search',
                    fields: cols.map(c => c.colKey),
                    placeholder: this.translationService.getImmediate('table.header.search', { type: '' }),
                    searchAction: (columnNames: (string | ((row) => any))[], value: string) => {
                        this.tableService.searchFilter = { columnNames, value };
                        this.tableService.filterUpdate$.next({ resetFilters: false });
                    },
                },
            },
            hideTableIntro: true,
            format: row => {
                const result = formatWidget(settings, row.allRows);
                if (result.length) return { color: result[0].color };
                return null;
            },
            pagination: {
                initPageSize: 15,
                showMoreSize: 10,
                pageUpdated: (shown: number, total: number) => this.tableService.setPaginationInfo({ shown, total }),
            },
        };
    }
}
