
<div #dropdownContainer class="dropdown-container" [ngStyle]="{'width.px': customWidth}">
    <div *ngIf="showLabel && floatedLabel" class="label-container">
        <span class="input-label" [class.required]="required">{{floatedLabel | translate}}</span>
        <app-tooltip *ngIf="tooltip" [options]="tooltip" [translate]="translate"></app-tooltip>
    </div>

    <mat-form-field
        class="dropdown"
        *ngIf="dropdownItems"
        [ngStyle]="{'width.px': customWidth}"
        [ngClass]="{
            'disabled': readonly,
            'clickable': isClickable,
            'white': white
        }"
    >
        <mat-label *ngIf="dropdownLabel">{{dropdownLabel | translate}}</mat-label> 

        <mat-select
            disableOptionCentering 
            #matSelect
            id="dropdown" 
            [(value)]="selectedItem"
            (selectionChange)="onSelectionChange()"
            (openedChange)="focusFilter($event)"
            (blur)="onTouched($event)"
            [required]="required"
            [ngClass]="{
                'disabled': readonly,
                'clickable': isClickable
            }"
        >
            <mat-select-trigger>
                <div class="selected-item" [class.placeholder]="selectedItem?.placeholder">
                    <div matPrefix
                        class="avatar selected-image" 
                        *ngIf="selectedItem?.imgUrl || selectedItem?.faIcon || selectedItem?.matIcon"
                        >
                        <app-icon
                            [img]="selectedItem.imgUrl"
                            [faIcon]="selectedItem.faIcon"
                            [matIcon]="selectedItem.matIcon"
                            [altText]="selectedItem.imgAltText"
                        ></app-icon>
                    </div>

                    <span class="selected-label" *ngIf="translate">
                        {{ selectedItem?.label | translate }}
                    </span>
                    <span class="selected-label" *ngIf="!translate">
                        {{ selectedItem?.label || '&nbsp;' }}
                    </span>
                </div>
            </mat-select-trigger>

            <div class="dropdown-item text-input" [ngStyle]="{'width.px': customWidth}">
                <div class="inline-loading" *ngIf="isLoading">
                    <app-loading [loading]="isLoading" [diameter]="25" [inline]="true" [theme]="'transparent'"></app-loading>
                </div>

                <input
                    matInput
                    #filterInput
                    type="text"
                    id="dropdown-filter"
                    [ngClass]="{'white': white}"
                    [(ngModel)]="filterValue"
                    (ngModelChange)="filterList($event)"
                    (keydown)="preventSpaceBar($event)"
                    (blur)="onFilterBlur($event)"
                    [placeholder]="placeHolderText"
                    autocomplete="off"
                >
            </div>
    
            <mat-option
                *ngIf="showAdd && hasAdd && isInputFilterValueValid && (filterValue || dropdownItems.length === 0)"
                class="dropdown-mat-option"
                [ngClass]="{ 'white': white }"
                [value]="hasAdd"
                [ngStyle]="{
                    'width.px': customWidth
                }"
            >
                <div id="add-dropdown-item" class="dropdown-item">
                    <div class="avatar dropdown-item-img" *ngIf="hasAdd.imgUrl || hasAdd.faIcon || hasAdd.matIcon">
                        <app-icon
                            [img]="hasAdd.imgUrl"
                            [faIcon]="hasAdd.faIcon"
                            [matIcon]="hasAdd.matIcon"
                            [altText]="hasAdd.imgAltText"
                        ></app-icon>
                    </div>

                    <p class="dropdown-item-label add">{{filterValue}} ({{hasAdd.label | translate}})</p>
                </div>
            </mat-option>
            
            <div
                *ngFor="let group of filteredInput"
                class="group-container"
                [ngClass]="{ 
                    'padding': group.label,
                    'hidden': isLoading || !isInputFilterValueValid
                }"
            >
                <div class="group-label" *ngIf="group.label">
                    {{group.label | translate}}
                </div>
                
                <mat-option
                    *ngFor="let item of group.items"
                    class="dropdown-mat-option"
                    [ngClass]="{
                        'placeholder': item.placeholder,
                        'white': white,
                        'bottom-spacing': item.subLabel
                    }"
                    [value]="item"
                    [disabled]="!!item.disabled"
                    [ngStyle]="{
                        'width.px': customWidth
                    }"
                >
                    <div class="dropdown-item">
                        <div class="main">
                            <div class="avatar dropdown-item-img" *ngIf="item.imgUrl || item.faIcon || item.matIcon">
                                <app-icon
                                    [img]="item.imgUrl"
                                    [faIcon]="item.faIcon"
                                    [matIcon]="item.matIcon"
                                    [altText]="item.imgAltText"
                                ></app-icon>
                            </div>

                            <div class="dropdown-label-container">
                                <div class="dropdown-item-label" *ngIf="translate">{{ item.label | translate}}</div>
                                <div class="dropdown-item-label" *ngIf="!translate">{{ item.label }}</div>
                                <div class="dropdown-sub-label" *ngIf="item.subLabel && !translate">{{ item.subLabel }}</div>
                                <div class="dropdown-sub-label" *ngIf="item.subLabel && translate">{{ item.subLabel | translate }}</div>
                            </div>

                            <div class="rightLabels" *ngIf="item.rightKey || item.rightImgUrl || item.rightFaIcon || item.rightMatIcon">
                                <div class="dropdown-sub-label pR10" *ngIf="item.rightKey">{{ item.rightKey | translate }}</div>
                                <app-icon
                                    [img]="item.rightImgUrl"
                                    [faIcon]="item.rightFaIcon"
                                    [matIcon]="item.rightMatIcon"
                                    [altText]="item.rightImgAltText"
                                ></app-icon>
                            </div>
                        </div>
                    </div>
                </mat-option>
            </div>

            <mat-option
                *ngIf="showHasMoreIndicator"
                class="dropdown-mat-option"
                [disabled]="true"
                [ngClass]="{
                    'hidden': !isInputFilterValueValid
                }"
            >
                <div class="dropdown-item">
                    <div class="main">
                        <p class="dropdown-item-label">{{ 'shared.generics.has-more' | translate}}...</p>
                    </div>
                </div>
            </mat-option>

        </mat-select>
        <ng-content></ng-content>
    </mat-form-field>
    <div *ngIf="showErrors" class="error-container" [ngClass]="[errorClass ? errorClass : '']">
        <mat-error *ngIf="formControl?.hasError('required') && formControl?.touched">{{ 'formValidation.required' | translate:{ label: ((floatedLabel ? floatedLabel : dropdownLabel) | translate) } }}</mat-error>
        <mat-error *ngIf="formControl?.hasError('custom') && formControl?.touched">{{ formControl.errors['custom'] | translate }}</mat-error>
    </div>
</div>
