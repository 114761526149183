import { Injectable } from '@angular/core';
import { Structure } from '../models/structure.model';
import { Topic } from '@weavix/models/src/topic/topic';
import { AccountService } from './account.service';
import { CacheContext, HttpService } from './http.service';
import { PubSubService } from './pub-sub.service';

@Injectable({
    providedIn: 'root'
})
export class StructureService {
    constructor(
        private httpService: HttpService,
        private pubSubService: PubSubService,
        private accountService: AccountService
    ) { }

    private static readonly cacheContext: CacheContext = { collection: 'Structures', maxAge: 1800000 };

    static url = (id?: string) => id ? `/track/structures/${id}` : `/track/structures`;

    public static clearCache = () => HttpService.clearCache(StructureService.cacheContext.collection);

    async getAll(component: any, facilityId?: string, tags?: string[]) {
        return this.httpService.get<Structure[]>(component, StructureService.url(), { tags, facilityId }, StructureService.cacheContext);
    }

    async add(component: any, structure: Structure) {
        return this.httpService.post<Structure>(component, StructureService.url(), structure, StructureService.cacheContext);
    }

    async update(component: any, id: string, structure: Structure) {
        return this.httpService.put<Structure>(component, StructureService.url(id), structure, StructureService.cacheContext);
    }

    async delete(component: any, id: string) {
        return this.httpService.delete<void>(component, StructureService.url(id), null, StructureService.cacheContext);
    }

    async subscribeStructureUpdates(c: any) {
        return this.pubSubService.subscribe<{[key: string]: Structure}>(c, Topic.AccountMapStructures, [this.accountService.getAccountId(), '+']);
    }
}
