import { Component, OnDestroy, OnInit } from '@angular/core';
import { Avatar, buildAvatar } from 'console/app/shared/avatar/avatar.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserProfile } from 'weavix-shared/models/user.model';
import { AccountService } from 'weavix-shared/services/account.service';
import { ProfileService } from 'weavix-shared/services/profile.service';
import { AutoUnsubscribe } from 'weavix-shared/utils/utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-right-header',
    templateUrl: './right-header.component.html',
    styleUrls: ['./right-header.component.scss'],
})
export class RightHeaderComponent implements OnInit, OnDestroy {

    constructor (
        private profileService: ProfileService,
        public accountService: AccountService,
    ) { }

    private destroyed$: Subject<boolean> = new Subject();
    userProfile: UserProfile;
    avatarInput: Avatar;
    settingsNavOpen: boolean = false;
    canaryAvailable = false;

    async ngOnInit() {
        this.getData();
        this.openAvatarSubscription();
        this.canaryAvailable = await this.accountService.checkCanary();
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }

    goToCanary() {
        document.location.href = document.location.href.replace('.weavix', '-canary.weavix');
    }

    async getData(): Promise<void> {
        this.userProfile = await this.profileService.getUserProfile(this);
        const userAvatar = this.userProfile.avatarFile ? this.userProfile.avatarFile : null;
        this.avatarInput = buildAvatar(38, 38, false, userAvatar );
    }

    toggleSettingsMenu(e: Event, open: boolean): void {
        e.stopPropagation();
        this.settingsNavOpen = open;
    }

    openAvatarSubscription(): void {
        this.profileService.profileUpdateSubject
            .pipe(takeUntil(this.destroyed$))
            .subscribe(res => {
                if (res) {
                    this.userProfile = res;
                    this.avatarInput = buildAvatar(30, 30, false, res.avatarFile);
                }
            });
    }
}
