import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { PinReset } from 'weavix-shared/models/auth.model';
import { LoginUser } from 'weavix-shared/models/login.model';

import { AccountService } from 'weavix-shared/services/account.service';
import { HttpService } from 'weavix-shared/services/http.service';
import { PubSubService } from 'weavix-shared/services/pub-sub.service';
import { PushService } from 'weavix-shared/services/push.service';
import { Utils } from 'weavix-shared/utils/utils';
import { AnalyticsService } from './analytics.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    constructor(
        private httpService: HttpService,
        private pushService: PushService,
        private pubSubService: PubSubService,
        private accountService: AccountService,
    ) { }

    async logout(c) {
        HttpService.clearCache();
        await this.pubSubService.disconnect();
        await this.pushService.deregister((await this.accountService.getCurrentUser()).id);
        return Utils.awaitable(this.httpService.get(c, '/account/logout').pipe(
            tap(() => AnalyticsService.stop()),
        ));
    }

    activateNfc(user: PinReset): Promise<LoginUser> {
        return this.httpService.post<LoginUser>(null, '/account/activate-nfc', user);
    }

}
