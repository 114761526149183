import { Injectable } from '@angular/core';
import { CompanyWithWorkHours, CraftWithWorkHours, PersonWithWorkHours, ShiftBuffers, WorkHourStats } from 'console/app/console/facility/people/people-overview/people-overview.model';
import { cloneDeep, omit, throttle } from 'lodash';
import * as moment from 'moment';
import { asyncScheduler, Observable, Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';
import { Company } from 'weavix-shared/models/company.model';
import { Craft } from 'weavix-shared/models/craft.model';
import { Facility } from 'weavix-shared/models/facility.model';
import { GeofenceType, GeofenceTypeCategory } from 'weavix-shared/models/geofence-type.model';
import { Person } from 'weavix-shared/models/person.model';
import { Geofence } from 'weavix-shared/models/weavix-map.model';
import { CompanyService } from 'weavix-shared/services/company.service';
import { CraftService } from 'weavix-shared/services/craft.service';
import { DataSourceService } from 'weavix-shared/services/data-source.service';
import { GeofenceService } from 'weavix-shared/services/geofence.service';
import { PersonService } from 'weavix-shared/services/person.service';
import { DateTimeUtil } from 'weavix-shared/utils/datetime';
import { sleep } from 'weavix-shared/utils/sleep';
import { Utils } from 'weavix-shared/utils/utils';
import { HoursCache, PeopleRowCache, SitePeopleGeofenceLog } from './people-overview-chart.model';

@Injectable({ providedIn: 'root' })
export class PeopleOverviewDataService {
    constructor(
        private dataSourceService: DataSourceService,
        private personService: PersonService,
        private companyService: CompanyService,
        private geofenceService: GeofenceService,
        private craftService: CraftService,
    ) { }

    private peopleRowCache: PeopleRowCache;
    private workHoursByPerson: {[key: string]: WorkHourStats} = {};

    allCompaniesMap = new Map<string, Company>();
    allCraftsMap = new Map<string, Craft>();
    allGeofencesTypesMap: Map<string, GeofenceType> = new Map();
    allGeofencesMap: Map<string, Geofence> = new Map();
    allPeopleMap = new Map<string, Person>();
    peopleNamesMap = new Map<string, { [id: string]: Person }>(); // people sorted by first letter of last name
    tzDiff: number;

    static getQuerySources(start: number, end: number, facilityId: string, personId?: string, active?: boolean): { sql: string, variables: { name: string, value: string | boolean }[] }[] {
        const parts = [];
        while (start < end) {
            const begin = end - 86400000 + 1;
            parts.push({
                sql: this.getGeoFenceDataByFacilitySql(active && parts.length === 0, facilityId, personId),
                variables: [
                    { name: 'start', value: new Date(Math.max(start, begin)).toISOString() },
                    { name: 'end', value: new Date(end).toISOString() },
                ],
            });
            end = begin - 1;
        }
        return parts;
    }

    static getGeoFenceDataByFacilitySql(current: boolean, facilityId: string, personId?: string) {
        const personFilter = personId == null ? 'AND personId IS NOT NULL' : `AND personId = '${personId}'`;
        return `
        SELECT id, entered, exited, personId, WIND(geofenceCategories) AS geofenceCategories, geofenceTypeId, geofenceId
        FROM geofenceLogs
        WHERE facilityId = '${facilityId}'
            ${personFilter}
            AND ${current ? '(exited IS NULL OR exited >= {{start}})' : 'exited >= {{start}} AND exited <= {{end}}'}
            AND entered <= {{end}}`;
    }


    static processPeopleByHour(newRows: SitePeopleGeofenceLog[], startStart: number, now: number, endEnd: number, tzDiff: number, cache?: PeopleRowCache, shiftBuffers?: ShiftBuffers, timezone?: string) {
        // eslint-disable-next-line no-console
        console.log('BAMF ----processPeopleByHour---');
        // eslint-disable-next-line no-console
        console.log('BAMF - startStart', startStart);
        // eslint-disable-next-line no-console
        console.log('BAMF - endEnd', endEnd);
        const first = !cache;
        if (first) cache = {
            people: {},
            geofences: {},
            rows: {},
            exited: {},
            hours: {},
            personnel: [],
            lastTime: startStart + tzDiff,
        };

        // eslint-disable-next-line no-console
        console.log('BAMF - cache', JSON.stringify(cache));

        let { geofences, people } = cache;
        let hours = cache.exited;

        const oneHour = 3600 * 1000;
        const buffer = 300 * 1000;

        const ranges: Array<{ start: number; end: number; }> = [];
        if (shiftBuffers && shiftBuffers.endBuffer !== shiftBuffers.startBuffer) {
            let begin = startStart;
            const endOffset = Math.min(86400000, shiftBuffers.endBuffer + 60000) + (shiftBuffers.endBuffer < shiftBuffers.startBuffer ? 86400000 : 0);
            while (begin < endEnd) {
                const m = moment(begin).tz(timezone);
                const start = m.clone().startOf('day').valueOf();
                ranges.push({ start: start + shiftBuffers.startBuffer, end: start + endOffset });
                begin = m.add(1, 'day').valueOf();
            }
        } else {
            ranges.push({ start: startStart, end: endEnd + 1 });
        }

        newRows.forEach(x => {
            if (x.entered) x.entered = new Date(x.entered).getTime();
            if (x.exited) x.exited = new Date(x.exited).getTime();
        });
        (first ? ranges : ranges.slice(ranges.length - 1)).forEach(({ start, end }) => {
            if (first) {
                people = cache.people = {};
                geofences = cache.geofences = {};
            }

            const rangeRows = newRows.filter(x => x.entered <= end && (!x.exited || x.exited >= start));
            const enters = rangeRows.filter(x => !cache.rows[x.id]).sort((a, b) => a.entered < b.entered ? -1 : 1);
            const exits = rangeRows.filter(x => x.exited && !cache.rows[x.id]?.exited).sort((a, b) => !a.exited ? -1 : !b.exited ? 1 : a.exited < b.exited ? 1 : -1);
            const addPersonnel = (baseTime: number) => {
                const time = baseTime + tzDiff;
                while (cache.lastTime < time) {
                    cache.personnel.push({
                        personIds: Object.keys(people).reduce((a, b) => { a[b] = b; return a; }, {}),
                        geofences: { ...geofences },
                        time: cache.lastTime,
                        count: Object.values(people).length,
                    });
                    cache.lastTime += buffer;
                }
            };

            const addExits = (cap: number) => {
                while (exits.length) {
                    const time = exits[exits.length - 1].exited;
                    if (time > cap) break;
                    const row = exits.pop();

                    const personRow = people[row.personId];
                    if (personRow) {
                        let penultimate: SitePeopleGeofenceLog;
                        let last: SitePeopleGeofenceLog;
                        for (const id in personRow) {
                            penultimate = last;
                            last = personRow[id];
                        }
                        const isCurrent = last?.id === row.id;
                        if (isCurrent) addTime(row, time - end);

                        delete personRow[row.id];
                        geofences[row.geofenceId]--;

                        if (!penultimate) delete people[row.personId];
                        else if (isCurrent) addTime(penultimate, end - time);
                    }
                }
            };

            const addTime = (row: SitePeopleGeofenceLog, time) => {
                time /= oneHour;

                if (!hours[row.personId]) hours[row.personId] = { geofences: {}, categories: {}, types: {}, total: 0 };
                const cached = hours[row.personId];
                cached.total += time;

                cached.geofences[row.geofenceId] = (cached.geofences[row.geofenceId] ?? 0) + time;
                if (row.geofenceTypeId) {
                    cached.types[row.geofenceTypeId] = (cached.types[row.geofenceTypeId] ?? 0) + time;
                }
                row.geofenceCategories?.forEach(category => {
                    cached.categories[category] = (cached.categories[category] ?? 0) + time;
                });
            };

            enters.forEach(row => {
                const time = row.entered;
                addExits(time);
                addPersonnel(time);

                let personRow = people[row.personId];
                if (!personRow) people[row.personId] = personRow = {};
                geofences[row.geofenceId] = (geofences[row.geofenceId] ?? 0) + 1;

                let last: SitePeopleGeofenceLog;
                for (const id in personRow) {
                    last = personRow[id];
                }

                personRow[row.id] = row;

                const started = Math.max(start, time);
                addTime(row, end - started);

                if (last) {
                    addTime(last, started - end);
                }
            });
            addExits(Math.min(end, now));
            addPersonnel(Math.min(end, now));

            if (now < end) {
                hours = cloneDeep(hours);
                Object.values(people).forEach(personRow => {
                    let last: SitePeopleGeofenceLog;
                    for (const id in personRow) {
                        last = personRow[id];
                    }
                    addTime(last, now - end);
                });
            }
        });
        cache.hours = hours;

        newRows.forEach(x => cache.rows[x.id] = x);

        return cache;
    }

    static getTimeStatsFromPeopleHours(hours: HoursCache): {[key: string]: WorkHourStats} {
        return Object.keys(hours).reduce((o, v) => {
            const workingArea = hours[v].categories[GeofenceTypeCategory.WorkingArea] ?? 0;
            o[v] = {
                workingAreaTime: workingArea,
                totalTime: hours[v].total,
            };

            return o;
        }, {});
    }

    /**  Used to trigger data updates and re-renders in child components. Triggered for event data updates and filtering updates */
    private dataUpdateTrigger$: Subject<void> = new Subject();
    triggerDataUpdate() {
        this.dataUpdateTrigger$.next();
    }

    get dataUpdate$() {
        return this.dataUpdateTrigger$.asObservable().pipe(throttleTime(100, asyncScheduler, { leading: false, trailing: true }));
    }

    set dataCache(cache: PeopleRowCache) {
        this.peopleRowCache = cache;
    }

    get dataCache(): PeopleRowCache {
        return this.peopleRowCache;
    }

    get dataCacheHours(): PeopleRowCache['hours'] {
        return this.peopleRowCache?.hours;
    }

    get dataCachePeople(): PeopleRowCache['people'] {
        return this.peopleRowCache?.people;
    }

    get getPeopleWorkHrs(): {[key: string]: WorkHourStats} {
        return this.workHoursByPerson;
    }

    getPersonGeofenceTypeWorkHrs(personId: string, geofenceTypeId: string): number {
        return this.dataCacheHours?.[personId]?.types?.[geofenceTypeId];
    }

    getPersonGeofenceCategoryWorkHrs(personId: string, geofenceCatId: string): number {
        return this.dataCacheHours?.[personId]?.categories?.[geofenceCatId];
    }

    /**
     * Loads initial data for service
     */
    async loadAllPeople(c: any, facilityId: string) {
        const people = (await this.personService.getFacilityPeople(c, facilityId) || []);
        const removeMapProperties = (p: Person) => (omit(p, 'badge', 'checkedIn$', 'closeListener', 'listener', 'marker'));
        people.forEach(p => this.allPeopleMap.set(p.id, removeMapProperties(p)));
    }

    async loadCommonData(c: any, facilityId?: string) {
        const [companies, crafts, geofences, geofenceTypes] = await Promise.all([
            this.companyService.getAll(c),
            this.craftService.getAll(c),
            this.geofenceService.getAll(c, facilityId),
            this.geofenceService.getTypes(c),
        ]);
        this.allCompaniesMap = Utils.toMap(companies);
        this.allCraftsMap = Utils.toMap(crafts);
        this.allGeofencesMap = Utils.toMap(geofences);
        this.allGeofencesTypesMap = Utils.toMap(geofenceTypes);
    }

    async startSubscription(c: any, start: number, end: number, facility: Facility, personId?: string, shiftBuffers?: ShiftBuffers) {
        const throttleTimeMs = 60000;
        if (!facility) return;

        await sleep(1);
        this.stop();

        this.tzDiff = DateTimeUtil.getTimeZoneDiff(facility.timezone);

        const processData = (newRows: any[]) => {
            this.dataCache = PeopleOverviewDataService.processPeopleByHour(newRows, start, new Date().getTime(), end, this.tzDiff, this.dataCache, shiftBuffers, facility.timezone);
        };

        const startTime = new Date().getTime();
        const queryEnd = shiftBuffers && shiftBuffers.endBuffer < shiftBuffers.startBuffer ? end + 86400000 : end; // If we are getting the night shift, need the next day's data also
        const refresh = new Date(queryEnd).getTime() >= new Date().getTime(); // If the current day is in the date range need to dynamically get updates to logs
        const staticSources = PeopleOverviewDataService.getQuerySources(start, queryEnd, facility.id, personId, !refresh);
        const dynamicSource = refresh ? {
            sql: PeopleOverviewDataService.getGeoFenceDataByFacilitySql(true, facility.id, personId),
            variables: [
                { name: 'start', value: new Date().toISOString() },
                { name: 'end', value: new Date(queryEnd).toISOString() },
                { name: 'refresh', value: true },
            ],
        } : null;
        const staticLoad = Promise.all(staticSources.map(async source => {
            return await this.dataSourceService.runQuery(c, source, facility.id, true);
        }));
        const dynamicSource$ = dynamicSource ? await this.dataSourceService.subscribeData(c, dynamicSource) : null;
        const staticData = await staticLoad;
        console.warn(`Pulled static data in ${new Date().getTime() - startTime} ms`);

        return new Observable<PeopleRowCache>(observer => {
            const processInitial = (rows) => {
                const startProcess = new Date().getTime();
                processData(rows);
                observer.next(this.dataCache);
                console.warn(`Processed data in ${new Date().getTime() - startProcess} ms, total time ${new Date().getTime() - startTime} ms`);
            };

            let changes: { [id: string]: any; } = {};
            const processChanges = throttle(() => {
                processData(Object.values(changes));
                observer.next(this.dataCache);
                changes = {};
            }, throttleTimeMs);

            if (dynamicSource$) {
                let init = false;
                const sub = dynamicSource$.subscribe(data => {
                    if (init) {
                        Object.assign(changes, data.changes);
                        processChanges();
                    } else {
                        init = true;
                        const allData = [];
                        staticData.forEach(list => list.forEach(x => {
                            if (!data.rows[x.id]) allData.push(x);
                        }));
                        Object.values(data.rows).forEach(x => allData.push(x));
                        processInitial(allData);
                    }
                });
                return () => {
                    sub.unsubscribe();
                    processChanges.cancel();
                    this.stop();
                };
            } else {
                processInitial([].concat(...staticData));
            }
        });
    }

    stop() {
        this.dataCache = null;
        this.tzDiff = null;
    }

    getEnteredGeofencesFromAllHrsForPerson(personId: string): string[] {
        return Object.keys(this.dataCacheHours?.[personId]?.geofences ?? {});
    }

    getEnteredGeofenceTypesFromAllHrsForPerson(personId: string): string[] {
        return Object.keys(this.dataCacheHours[personId]?.types ?? {});
    }

    setPeopleWorkHrs(stats: {[key: string]: WorkHourStats}): void {
        this.workHoursByPerson = stats;
    }

    getPercentInWorkingArea(entityWorkHrs: PersonWithWorkHours | CompanyWithWorkHours | CraftWithWorkHours): number {
        return (entityWorkHrs.totalTime && entityWorkHrs.workingAreaTime !== undefined) ? entityWorkHrs.workingAreaTime / entityWorkHrs.totalTime : 0;
    }

    getPersonWorkHrs(personId: string): WorkHourStats {
        return this.workHoursByPerson[personId];
    }

    getPersonWithWorkHrs(personId: string): PersonWithWorkHours {
        const person = this.allPeopleMap.get(personId);
        if (!person) return;
        const workHrs = this.getPersonWorkHrs(personId);
        return workHrs ? { ...person, ...workHrs } : { ...person, workingAreaTime: 0, totalTime: 0 };
    }

    getPersonCrafts(craftIds: string[] = []): string {
        if (!craftIds.length) return '-';
        return (craftIds).map(c => this.allCraftsMap.get(c)?.name).filter(x => x).join(', ');
    }
}
