<div class="widget-wrapper">
    <div class="editable-overlay cPointer" *ngIf="editable" (click)="edit($event)">
        <app-icon faIcon="fas fa-pencil-alt"></app-icon>
    </div>
    <div class="widget" *ngIf="widgetData">
        <div class="buttons" *ngIf="!editable">
            <button class=""
                *ngIf="hasDataSource"
                (click)="exportToCsv()"
                matTooltip="{{ 'dashboard.exportCsv' | translate }}"
            >
                <app-icon faIcon="fas fa-file-csv"></app-icon>
            </button>
        </div>

        <div class="widget-content">
            <p class="widget-content-title" *ngIf="widgetData.name">{{widgetData.name | translate}}</p>

            <div class="widget-content-main">
                <ng-content></ng-content>
            </div>
        </div>
    </div>

    <div class="grip" *ngIf="editable">
        <app-icon faIcon="fas fa-grip-horizontal"></app-icon>
    </div>
</div>
