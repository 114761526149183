<div class="map-page-wrapper" #mapPageWrapper>
    <div class="left-panel">
        <div class="map-header" *ngIf="features.tableView?.tableOptions && features?.search && mapLoaded">
            <ng-container *ngIf="features?.search">
                <app-simple-map-search
                    #mapSearch
                    [activeMapTableMode]="activeMapTableMode"
                    [searchConfig]="features?.search"
                    (searchQueryOutput)="handleTableSearch($event)"
                ></app-simple-map-search>
            </ng-container>

            <app-button-list
                class="mLa"
                [buttons]="tableModeButtons"
                (selected)="handleTableModeSelection($event)"
            ></app-button-list>
        </div>

        <div class="map-main">
            <div *ngIf="activeMapTableMode === mapTableMode.List" class="table-contents">
                <app-table
                    class="h100p"
                    [rows]="tableRows"
                    [options]="features.tableView.tableOptions"
                    [externalSearchInput]="tableSearchQuery"
                    (rowClickOutput)="handleTableRowClick($event)"
                ></app-table>
            </div>

            <div [ngClass]="{
                    'p15px': activeMapTableMode === mapTableMode.Map,
                    'invisible': activeMapTableMode === mapTableMode.List
                }"
                class="map-contents"
            >
                
                <ng-container *ngIf="mapLoaded">
                    <ng-content select="[dashboard]"></ng-content>
            
                    <div class="dashboard-tab"
                        *ngIf="features?.hasDashboard && !dashboardVisible"
                        (click)="toggleDashboard()"
                        [matTooltip]="('map.toggle-analysis' | translate)"
                        matTooltipPosition="after"
                    >
                        <app-icon class="cPointer" [faIcon]="'fas fa-chart-bar'"></app-icon>
                    </div>
            
                    <div class="map-controls bottom right">
                        <ng-container *ngFor="let control of actionMapControls" >
                            <app-map-control *ngIf="!control.hide || !control.hide()" [control]="control"></app-map-control>
                        </ng-container>
                    </div>

                    <div class="map-controls bottom left">
                        <ng-content select="[notShowingOnMap]"></ng-content>
                    </div>
            
                    <div class="map-controls top right">
                        <app-map-control *ngIf="features?.mapControls?.recenter" class="control" [control]="features?.mapControls.recenter"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.zoomIn" class="control" [control]="features?.mapControls.zoomIn"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.zoomOut" class="control" [control]="features?.mapControls.zoomOut"></app-map-control>
                        <app-map-control class="control" *ngIf="!mapService.dashboardMode" [control]="features?.mapControls?.dashboard"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.facilityFloorPlan" class="control" [control]="features?.mapControls.facilityFloorPlan"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.geofences" class="control" [control]="features?.mapControls.geofences"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.items" class="control" [control]="features?.mapControls.items"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.mapLayers" class="control" [control]="features?.mapControls.mapLayers"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.dvr" class="control" [control]="features?.mapControls.dvr"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.toggleUnpinned" class="control" [control]="features?.mapControls.toggleUnpinned"></app-map-control>
                    </div>
            
                    <div class="map-controls top alert-actions">
                        <app-map-control *ngIf="features?.mapControls?.info" class="control" [control]="features?.mapControls.info"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.warning" class="control" [control]="features?.mapControls.warning"></app-map-control>
                        <app-map-control *ngIf="features?.mapControls?.danger" class="control" [control]="features?.mapControls.danger"></app-map-control>
                    </div>
                </ng-container>
        
                <div #map class="map"></div>
                
                <ng-container *ngIf="mapLoaded">
                    <app-dvr-controls class="dvr-controls"
                        [ngClass]="{'left-dashboard-buffer': dashboardVisible}"
                        *ngIf="map && features?.dvr?.show"
                        [hidden]="dvrHidden"
                        [initialTime]="features?.dvr?.initialTime"
                        [range]="features?.dvr?.range"
                        [hideSlider]="features?.dvr?.hideSlider"
                        [removeDateSelection]="features?.dvr?.removeDateSelection"
                        [treatAsRangeSelection]="!!features?.dvr?.range"
                        (playbackStateUpdated)="handleDvrPlaybackStateUpdated($event)"
                        (controlsStateUpdated)="handleDvrControlsStateUpdated($event)"
                        (hideDvr)="toggleDvr()"
                    ></app-dvr-controls>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="right-panel flexColumn gap15p hide-empty" *ngIf="!features?.mapFilter?.removeFilterPanel">
        <app-right-header *ngIf="showRightHeader" style="margin-left: -20px"></app-right-header>
        <ng-container *ngIf="activeDetailView">
            <ng-container *ngTemplateOutlet="detailView; context { view: activeDetailView }"></ng-container>
        </ng-container>

        <ng-container *ngIf="!activeDetailView || compactDetailViews.includes(activeDetailView.type)">
            <ng-container *ngTemplateOutlet="mapFilter"></ng-container>
        </ng-container>
    </div>
</div>

<ng-template #mapFilter>
    <ng-content select="[legend]"></ng-content>
    <div *ngIf="features?.mapFilter?.showFilterPanel && features?.mapFilter?.mapFilterResults" class="filter-container flexGrow1">
        <app-weavix-map-filter
            #mapFilters
            [hideDateRange]="true"
            [filterResults]="features?.mapFilter?.mapFilterResults"
            [multiCategorySelect]="features?.mapFilter?.multiSelect"
        ></app-weavix-map-filter>
    </div>
    <div *ngIf="features?.mapFilter?.floorPlanFilter?.show && hasFloorPlanFilterResults" class="filter-container flexGrow1">
        <app-weavix-map-filter
            #floorPlanFilters
            title="floor-plan.floor-plans"
            clearText="generics.reset"
            [hideDateRange]="true"
            [multiCategorySelect]="features?.mapFilter?.floorPlanFilter?.multiSelect ?? true"
            [filterResults]="floorPlanFilterResults"
            [ignoreViewSubscriptions]="true"
        ></app-weavix-map-filter>
    </div>
</ng-template>
<ng-template #detailView let-view="view">
    <ng-container [ngSwitch]="view.type">        
        <ng-container *ngSwitchCase="MapDetailViewType?.NetworkCoverageDetail">
            <app-network-coverage-detail
            [averages]="view.averages"
            [metricsToDisplay]="view.selectedMetrics"
            [surveyCount]="view.count"
            ></app-network-coverage-detail>
        </ng-container>
        <ng-container *ngSwitchCase="MapDetailViewType?.PersonDetail">
            <app-person-detail [person]="view?.entity" (closeOutput)="handleDetailViewClose()"></app-person-detail>
        </ng-container>
        <ng-container *ngSwitchCase="MapDetailViewType?.ItemDetail">
            <app-item-detail [item]="view?.entity" (closeOutput)="handleDetailViewClose()"></app-item-detail>
        </ng-container>
        <ng-container *ngSwitchCase="MapDetailViewType?.WaltDetail">
            <app-walt-detail [walt]="view?.entity" (closeOutput)="handleDetailViewClose()" style="overflow-y:auto;"></app-walt-detail>
        </ng-container>
        <ng-container *ngSwitchCase="MapDetailViewType?.GeofenceDetail">
            <app-geofence-detail
                [geofence]="view.entity"
                [people]="view.people"
                (closeOutput)="handleDetailViewClose()"
            ></app-geofence-detail>
        </ng-container>
    </ng-container>
</ng-template>



