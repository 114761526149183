import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { DropdownItem } from '../../console/app/shared/dropdown/dropdown.model';
import { DashboardSource, DashboardVariable } from '../models/dashboard.model';
import { ColumnType, DataSource, DataSourceCondition, DataSourceMeta, DataSourceQuery, DataSourceTable, DataSourceVariable } from '../models/data-source.model';
import { FieldType } from '../models/item.model';
import { RowItemType, TableColumn, TableOptions } from '../models/table.model';
import { Topic } from '@weavix/models/src/topic/topic';
import { sleep } from '../utils/sleep';
import { getRelativeDate, Utils } from '../utils/utils';
import { AccountService } from './account.service';
import { DataSourceBuilderService } from './data-source-builder.service';
import { FacilityService } from './facility.service';
import { CacheContext, HttpService } from './http.service';
import { ProfileService } from './profile.service';
import { PubSubService } from './pub-sub.service';

export interface DataSourceResults {
    allRows: { [key: string]: any };
    rows: { [key: string]: any };
    changes?: { [key: string]: any };
    meta: DataSourceMeta;
    updated?: boolean;
    alias?: string;
    isLoading?: boolean;
}

const subjects: { [id: string]: { resubscribe: ((obs: Observable<DataSourceResults>) => any)[]; obs: Observable<DataSourceResults>; source: any; variables: any; } } = {};

export interface VariableField {
    name: string;
    type: FieldType;
    editing: boolean;
    control: FormControl;
    options: DropdownItem[];
}

@Injectable({
    providedIn: 'root'
})
export class DataSourceService {

    constructor(
        private httpService: HttpService,
        private pubSubService: PubSubService,
        private accountService: AccountService,
        private profileService: ProfileService,
        private facilityService: FacilityService,
        private dataSourceBuilderService: DataSourceBuilderService
    ) {}

    static baseUrl = '/core/data-sources';
    static cacheCollection = 'dashboards';

    private static readonly cacheContext: CacheContext = { collection: 'DataSource', maxAge: 1800000 };
    private dataSourceResults: { [id: string]: { [key: string]: any } } = {};
    private dataSources: { [id: string]: DataSourceMeta } = {};
    private dataSourceIndex = 0;

    private typesCache = {};
    private queryCache = {};

    private variables: any = {};
    private autoRefresh: boolean = false;
    static url = (id?: string) => id ? `${DataSourceService.baseUrl}/${id}` : DataSourceService.baseUrl;

    static buildTableOptions(schema: DataSourceMeta, rows: any[], editing: boolean = false): TableOptions {
        const columns: TableColumn[] = schema.columns.map(c => {
            return {
                title: c.name,
                translate: false,
                colKey: c.name,
                type: c.type === ColumnType.Datetime ? editing ? RowItemType.text : RowItemType.date :
                    c.type === ColumnType.Number ? RowItemType.number : RowItemType.text,
                value: row => c.type === ColumnType.Datetime && row[c.name] ? editing ? new Date(row[c.name]).toISOString() : new Date(row[c.name]) : row[c.name],
                sort: { sortable: false }
            };
        });
        const select = {
            rowsDblClickable: false,
            rowsClickable: false,
            showCheckboxes: false
        };
        const pagination = {
            show: true,
            initPageSize: 20,
            showMoreSize: 20
        };
        const filters = [];

        return {
            title: null,
            headerOptions: {
                hide: true
            },
            rowCount: true,
            keyCol: '_id',
            rowEdits: [],
            bulk: {
                url: DataSourceService.url,
                cache: DataSourceService.cacheCollection,
            },
            filters,
            columns,
            select,
            pagination,
            canAdd: false,
            csvExport: true
        };
    }

    async validateQuery(component, data: DataSource) {
        return this.httpService.post<DataSourceMeta>(component, `${DataSourceService.url()}/validate`, data);
    }

    async runQuery(component: any, data: any, facilityId?: string, cache = false) {
        data.variables = (data.variables ?? []).concat(await this.getStaticVariables());

        const topic = await this.getSqlTopic(data.sql, data.variables ?? [], []);
        if (cache && this.queryCache[topic]) return this.queryCache[topic];
        const results = await this.httpService.post<any>(component, `${DataSourceService.url()}/run`, data);
        if (cache) this.queryCache[topic] = results;
        return results;
    }

    async getQuery(component: any, data: any, cache = false) {
        const variables = data.variables.map(x => `${encodeURIComponent(x.name)}=${encodeURIComponent(x.value)}`).join('&');
        return this.httpService.get<any>(component, `/core/data-sources/sql/${encodeURIComponent(data.sql)}/variables/${variables}`, {}, cache ? DataSourceService.cacheContext : null);
    }

    async runDrillDownSql(component: any, sql: string, column: string, value: any, variables?: DataSourceVariable[]) {
        const allVariables = await this.getAllVariables(variables);
        return this.httpService.post<{ rows: any[]; meta: DataSourceMeta; }>(component, `${DataSourceService.url()}/drilldown`,
            { sql, column, value, variables: allVariables });
    }

    async getQueryTypes(component: any, dataSource): Promise<any> {
        const key = `${JSON.stringify(dataSource)}-types`;
        if (!this.typesCache[key]) {
            this.typesCache[key] = this.httpService.post<any>(null, `${DataSourceService.url()}/types`, dataSource);
        }
        return Utils.awaitable(Utils.safeSubscribe(component, this.typesCache[key]));
    }

    async subscribeVariables(
        component: any,
        prefix: string,
        dashboardVariablesMap: {[name: string]: DashboardVariable},
        variables: VariableField[],
        sources: DashboardSource[],
        timezone: string
    ) {
        Object.keys(dashboardVariablesMap).map(async name => {
            const v = dashboardVariablesMap[name];
            const existing = variables.find(va => va.name.toUpperCase() === name);
            if (existing) return;

            const storageKey = `${prefix}-variable-${name}`;
            let value = localStorage.getItem(storageKey) || v.value;
            if (v.type === FieldType.Date) {
                value = getRelativeDate(v.value, timezone);
            }
            const variable = {
                name,
                control: new FormControl(value),
                options: v.options,
                editing: false,
                type: v.type
            };
            variables.push(variable);

            const setOptions = () => {
                if (variable.options?.length && !variable.options.some(x => x.key === variable.control.value)) {
                    variable.control.setValue((variable.options.find(x => x.key === v.value) ?? variable.options[0]).key);
                }
            };

            if (v.dataSourceId) {
                const source = sources.find(x => x.id === v.dataSourceId);
                const subs = (await this.subscribeSource(source.source)).subscribe(res => {
                    if (subs) subs.unsubscribe();

                    variable.options = Object.keys(res.rows).map(x => ({ key: res.rows[x][v.dataSourceValues], label: res.rows[x][v.dataSourceLabels] }));
                    setOptions();
                });
            } else {
                setOptions();
            }

            variable.control.valueChanges.subscribe(async (val) => {
                if (val) localStorage.setItem(storageKey, val);

                if (component['$x-updating']) return;
                component['$x-updating'] = true;
                if (v.type === FieldType.Number || v.type === FieldType.Text) await sleep(1000);
                component['$x-updating'] = false;

                const vmap = {};
                variables.forEach(va => vmap[va.name] = va.control.value);
                await this.setVariables(vmap);
            });
        });
    }

    async setVariables(variables: any) {
        this.variables = variables;

        await Promise.all(Object.keys(subjects).map(async id => {
            const props = subjects[id];

            props.resubscribe.forEach(x => x(null));
            const obs = await this.subscribeSource(props.source, null, props.variables);
            props.obs = obs;
            props.resubscribe.forEach(x => x(obs));
        }));
    }

    async setAutoRefresh(autoRefresh: boolean) {
        this.autoRefresh = autoRefresh !== false;
    }

    async subscribeData(component: any, id: DataSourceTable, variables?: DataSourceVariable[]) {
        const source = await this.subscribeSource(id, null, variables);

        const topic = this.dataSourceIndex++;
        const props = {
            source: id,
            variables,
            obs: source,
            resubscribe: []
        };
        subjects[topic] = props;

        return new Observable<DataSourceResults>(observer => {
            let subs: Subscription;
            let first = true;

            const resub = (obs: Observable<DataSourceResults>) => {
                subs?.unsubscribe();
                subs = null;

                if (!obs) {
                    observer.next({ isLoading: true } as any);
                } else {
                    subs = obs.subscribe({
                        next: result => {
                            const isFirst = first;
                            first = false;
                            observer.next({ changes: result.changes, allRows: result.allRows, rows: result.rows, meta: result.meta, updated: result.updated || isFirst, isLoading: result.isLoading });
                        },
                        error: err => observer.error(err),
                    });
                }
            };
            resub(props.obs);
            props.resubscribe.push(resub);
            return () => {
                subs?.unsubscribe();
                props.resubscribe = props.resubscribe.filter(x => x !== resub);
                if (!props.resubscribe.length) delete subjects[topic];
            };
        });
    }

    async subscribeSource(id: DataSourceTable, columns?: string[], variables?: DataSourceVariable[]): Promise<Observable<DataSourceResults>> {
        let obs: Observable<any>;
        if (id.collection) {
            obs = await this.subscribePrivate(this.getCollectionTopic(id.collection, columns || ['*']));
        } else if (id.query) {
            obs = await this.subscribeQuery(id.query, variables);
        } else if (id.union) {
            obs = await this.subscribeUnion(id.union, variables);
        } else if (id.sql) {
            obs = await this.subscribePrivate(await this.getSqlTopic(id.sql, variables, id.variables));
        } else {
            throw new Error();
        }
        return obs;
    }

    async getColumnsFrom(component: any, from: DataSourceTable) {
        if (!from) return [];

        return await this.getColumns(component, { from: [from] });
    }

    async getColumns(component: any, query: DataSourceQuery, selected: boolean = false) {
        const columns = [];
        await Promise.all(query.from.map(async from => {
            if (from.collection) {
                const types = await this.getQueryTypes(component, { sql: `SELECT * FROM ${from.collection}` });
                const addTypes = (obj, prefix) => {
                    Object.keys(obj).forEach(key => {
                        if (Array.isArray(obj[key])) {
                            if (typeof obj[key][0] === 'object') addTypes(obj[key][0], `${prefix}${key}.`);
                            else {
                                columns.push({ name: `${prefix}${key}`, type: obj[key][0] });
                            }
                        } else if (typeof obj[key] === 'object') {
                            addTypes(obj[key], `${prefix}${key}.`);
                        } else {
                            columns.push({ name: `${prefix}${key}`, type: obj[key] });
                        }
                    });
                };
                addTypes(types.tables[from.collection], from.alias ? `${from.alias}.` : '');
            } else if (from.query) {
                const found = await this.getColumns(component, from.query, true);
                found.forEach(c => {
                    const name = from.alias ? `${from.alias}.${c.name}` : c.name;
                    if (!columns.some(x => x.name === name)) columns.push({ name, type: c.type });
                });
            } else if (from.union) {
                await Promise.all(from.union.map(async x => {
                    delete x.alias;
                    const found = await this.getColumnsFrom(component, x);
                    found.forEach(c => {
                        const name = from.alias ? `${from.alias}.${c.name}` : c.name;
                        if (!columns.some(y => y.name === name)) columns.push({ name, type: c.type });
                    });
                }));
            } else if (from.sql) {
                const found = await this.validateQuery(component, { sql: from.sql });
                if (found.columns) found.columns.forEach(c => columns.push({ name: from.alias ? `${from.alias}.${c.name}` : c.name, type: c.type }));
            }
        }));
        if (selected) {
            const types = columns.reduce((obj, v) => (obj[v.name] = v.type, obj), {});
            return (query.select || []).map(s => {
                s.alias = s.alias || (this.dataSourceBuilderService.getValueText(s).match(/[0-9a-z]/ig) || []).join('');
                return {
                    name: s.alias,
                    type: s.aggregate ? ColumnType.Number : s.column ? types[s.column] : typeof s.value
                };
            });
        }
        return columns;
    }

    private async subscribeUnion(union: DataSourceTable[], variables: DataSourceVariable[]): Promise<Observable<DataSourceResults>> {
        return combineLatest(await Promise.all(union.map(x => this.subscribeSource(x, null, variables)))).pipe(
            map(results => {
                const rows = [].concat(...results.map(x => x.rows));
                return {
                    rows,
                    meta: {
                        columns: results.reduce((list, s) => {
                            s.meta?.columns?.forEach(c => {
                                if (!list.some(x => x.name === c.name)) list.push(c);
                            });
                            return list;
                        }, [])
                    }
                } as any;
            }));
    }

    private async subscribeQuery(query: DataSourceQuery, variables: DataSourceVariable[], found: any = {}): Promise<Observable<DataSourceResults>> {
        const columns = await this.getColumns(null, query, true);
        const fromColumns = await this.getColumns(null, query);
        const obs = await Promise.all(query.from.map(async (from, i) => {
            let o: Observable<DataSourceResults>;
            if (from.collection) {
                const foundColumns = this.usedColumns(query, from.alias);
                o = await this.subscribePrivate(this.getCollectionTopic(from.collection, foundColumns));
            } else if (from.query) {
                o = await this.subscribeQuery(from.query, variables, found);
            } else if (from.union) {
                o = await this.subscribeUnion(from.union, variables);
            } else if (from.sql) {
                o = await this.subscribePrivate(await this.getSqlTopic(from.sql, variables, from.variables));
            }
            return o.pipe(map(x => {
                x.alias = from.alias || String(i);
                return x;
            }));
        }));

        if (!obs.length) {
            obs.push(of({ rows: [{}], meta: { columns: [] }, alias: '' } as any));
        }

        return this.dataSourceBuilderService.evalauteResults(await this.getAllVariables(variables), query, columns, fromColumns, obs);
    }

    private usedColumns(query: DataSourceQuery, alias: string) {
        const prefix = `${alias}.`;
        const obj = {};
        const usedCondition = (condition: DataSourceCondition) => {
            (condition.values || []).forEach(v => v.column && v.column.startsWith(prefix) && (obj[v.column.substring(prefix.length)] = true));
            (condition.conditions || []).map(c => usedCondition(c));
        };
        (query.select || []).forEach(v => v.column && v.column.startsWith(prefix) && (obj[v.column.substring(prefix.length)] = true));
        (query.where || []).map(w => usedCondition(w));
        return Object.keys(obj).sort();
    }

    private async getSqlTopic(sql: string, variables: DataSourceVariable[], sqlVariables: DataSourceVariable[]) {
        const autoRefresh = (this.autoRefresh || variables?.some(x => x.name === 'refresh' && x.value === true) || sqlVariables?.some(x => x.name === 'refresh' && x.value === true))
            && !variables?.some(x => x.name === 'static' && x.value === true)
            && !sqlVariables?.some(x => x.name === 'static' && x.value === true);
        const topic: string = autoRefresh ? Topic.AccountDataSourceSql : Topic.AccountStaticSourceSql;
        const encodedSql = encodeURIComponent(sql.trim());
        const encodedVariables = this.getVariablesTopic(await this.getAllVariables(variables, sqlVariables));
        return this.resolveDataSourceTopic(topic, [encodedSql, encodedVariables]);
    }

    private getCollectionTopic(collection: string, columns: string[]) {
        const topic = this.autoRefresh ? Topic.AccountDataSourceCollection : Topic.AccountStaticSourceCollection;
        return this.resolveDataSourceTopic(topic, [collection, columns.join('&')]);
    }

    private resolveDataSourceTopic(topic: string, args: any[]) {
        // e.g., `account/+/data-source/+/collection/#` -> `account/+/data-source/arg[0]/collection/arg[1]`
        let result = topic.replace('+', '{plus}');
        result = result.replace('+', args[0]);
        result = result.replace('#', args[1]);
        return result.replace('{plus}', '+');
    }

    private async getAllVariables(variables: DataSourceVariable[], defaultVariables: DataSourceVariable[] = []) {
        const vs = { ...this.variables };
        defaultVariables.concat(variables || []).concat(await this.getStaticVariables()).forEach(v => {
            if (v.reference !== 'none') {
                vs[v.name.toUpperCase()] = v.reference ? this.variables[v.reference.toUpperCase()] : v.value;
            }
        });
        return vs;
    }

    private async getStaticVariables() {
        const facility = this.facilityService.getCurrentFacility();
        return [
            { name: 'FACILITYID', value: facility?.id },
            { name: 'PERSONID', value: (await this.profileService.getUserProfile(null)).personId },
            { name: 'TIMEZONE', value: facility?.timezone ?? moment.tz.guess() }
        ];
    }

    private getVariablesTopic(variables: any) {
        return Object.keys(variables).sort().map(key => `${encodeURIComponent(key)}=${encodeURIComponent(variables[key] instanceof Date
            ? variables[key].toISOString() : variables[key])}`).join('&');
    }

    private async subscribePrivate(topic: string): Promise<Observable<DataSourceResults>> {
        return (await this.pubSubService.subscribe<any>(null, topic as Topic, [this.accountService.getAccountId()], true)).pipe(map(result => {
            if (result.payload.meta) {
                this.dataSourceResults[topic] = {};
                this.dataSources[topic] = _.merge(this.dataSources[topic] || {}, result.payload.meta);
            }

            Object.keys(result.payload.rows).forEach(key => {
                const row = result.payload.rows[key];
                if (!row) {
                    delete this.dataSourceResults[topic][key];
                } else {
                    row._id = key;
                    this.dataSourceResults[topic][key] = row;
                }
            });
            const rows = this.dataSourceResults[topic];
            return { changes: result.payload.rows, rows, allRows: rows, meta: this.dataSources[topic], updated: !!result.payload.meta };
        }));
    }
}
