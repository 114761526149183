import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { AutoUnsubscribe, RelativeDate } from 'weavix-shared/utils/utils';

@AutoUnsubscribe()
@Component({
    selector: 'app-relative-date-picker',
    templateUrl: './relative-date-picker.component.html',
    styleUrls: ['./relative-date-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RelativeDatePickerComponent),
            multi: true,
        },
    ],
})
export class RelativeDatePickerComponent implements OnInit {
    @Input() label: string;
    @Input() lessSpacing: boolean; // less space between rows;

    formControl: NgControl;

    addTypes = [
        { key: 'add', label: 'generics.dates.add' },
        { key: 'subtract', label: 'generics.dates.subtract' },
    ];

    unitTypes = [
        { key: 'day', label: 'generics.dates.day' },
        { key: 'week', label: 'generics.dates.week' },
        { key: 'month', label: 'generics.dates.month' },
        { key: 'year', label: 'generics.dates.year' },
    ];

    fixTypes = [
        { key: 'start', label: 'generics.dates.start' },
        { key: 'end', label: 'generics.dates.end' },
    ];

    fixUnits = [
        { key: 'week', label: 'generics.dates.week' },
        { key: 'month', label: 'generics.dates.month' },
        { key: 'year', label: 'generics.dates.year' },
    ];

    daysOfWeek = [
        { key: 0, label: 'generics.days.sunday' },
        { key: 1, label: 'generics.days.monday' },
        { key: 2, label: 'generics.days.tuesday' },
        { key: 3, label: 'generics.days.wednesday' },
        { key: 4, label: 'generics.days.thursday' },
        { key: 5, label: 'generics.days.friday' },
        { key: 6, label: 'generics.days.saturday' },
    ];

    value: RelativeDate = {
        type: null,
        unit: 'day',
        amount: 1,
        fixType: null,
        fixUnit: 'week',
        dayOfWeek: 0,
    };

    constructor(
        private injector: Injector,
    ) {
    }

    async ngOnInit() {
        try { this.formControl = this.injector.get(NgControl); } catch (e) { }
    }

    setType(v) {
        this.value.type = v;
        this.updateValue();
    }

    setUnit(v) {
        this.value.unit = v;
        this.updateValue();
    }

    setAmount(v) {
        this.value.amount = v;
        this.updateValue();
    }

    setFixType(v) {
        this.value.fixType = v;
        this.updateValue();
    }

    setFixUnit(v) {
        this.value.fixUnit = v;
        this.updateValue();
    }

    setDayOfWeek(v) {
        this.value.dayOfWeek = v;
        this.updateValue();
    }

    updateValue() {
        if (this.onChange) this.onChange(this.value);
    }

    // Form control interface fns
    onChange = (x: any) => {};

    onTouched = () => {};

    async writeValue(key: any) {
        this.value = {
            type: null,
            unit: 'day',
            amount: 1,
            fixType: null,
            fixUnit: 'week',
            dayOfWeek: 0,
            ...(key || {}),
        };
    }

    registerOnChange(fn: (key: any) => void) { this.onChange = fn; }

    registerOnTouched(fn: () => void) { this.onTouched = fn; }
    // END Form control
}
